import { EagerLoaded, Fetch, Fetched, LazyJsonLd as JsonLd } from '@techniek-team/fetch';
import { FetchObservable } from '@techniek-team/rxjs';
import { Expose } from 'class-transformer';
import { firstValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { LocationModel } from '../location/location.model';

/**
 * The standard version of the Lesson Resource.
 *
 * A Lesson isn't the same a lesson should attend to at school. A lesson
 * is a grouping of shift connected to an amount of pupils that are attending
 * the shift on a specific date and most of the time in a specific room. The lesson
 * also holds possibly information about which subject and on what level the lesson is given.
 * (There are services and therefor lesson which aren't about a specific subject
 * for example huiswerkbegeleiding)
 */
export class Lesson<Lazy = FetchObservable<unknown>> extends JsonLd {

  /**
   * @inheritDoc
   */
  public override readonly className: string = 'Lesson';

  /**
   * The location where the assignment takes place.
   *
   * Even if the is online boolean is set to true the api still gives a location sometimes
   * because with some delivery types the candidate still is expected at a certain location
   * but is the pupil joining online.
   */
  @Fetch(() => LocationModel)
  @Expose() public location!: Fetched<Lazy, LocationModel>;

  @Expose() public isOnline!: boolean;

  public override async fetchAll(): Promise<Lesson<EagerLoaded>> {
    let location: FetchObservable<LocationModel> | LocationModel = this.location;

    if (location instanceof FetchObservable) {
      location = await firstValueFrom(location.pipe(take(1)));
    }

    const lesson: Lesson<EagerLoaded> = this as Lesson<EagerLoaded>;
    lesson.location = location as LocationModel;
    return lesson;
  }

  /**
   * @inheritDoc
   */
  public override toString(): string {
    return `${this.className}: ${this.getId()}`;
  }
}
