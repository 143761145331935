import { LazyJsonLd as JsonLd } from '@techniek-team/fetch';
import { Expose, Type } from 'class-transformer';
import { Slot } from '../slot.model';
import { SlotAvailability } from './slot-availability.model';

/**
 * Standard version of the Slot Statistics Resource.
 */
export class SlotStatistics extends JsonLd {

  /**
   * boolean which is true when the criteria are matching
   */
  @Expose() public criteriaMatch!: boolean;

  /**
   * the availability in minutes
   */
  @Type(() => SlotAvailability)
  @Expose() public availability!: SlotAvailability;

  /**
   * the distance of the slot in meters
   */
  @Expose() public distance!: number;

  /**
   * List of slot that are conflicting with the current assignment.
   */
  @Expose() public conflictingSlots: Slot[] = [];

  /**
   * Conflicts are resolveable.
   */
  @Expose() public conflictsResolvable!: boolean;
}
