import { JsonLd } from '@techniek-team/class-transformer';
import { FetchObservable } from '@techniek-team/rxjs';
import { Exclude } from 'class-transformer';
import { EagerLoaded } from './decorators/fetch.decorator';

export class LazyJsonLd<Lazy = FetchObservable<unknown>> extends JsonLd {

  /**
   * This method can be reimplemented in each models which uses a @Fetch decorator.
   *
   * In the method you fetch the observable as promise of each with @Fetch decoratored
   * properties and override te value of the property with the result. After that I would
   * recommend triggering the fetchAll property of these nested models in return.
   *
   * After fetches alle the lazyloaded property use can return the model returning a model
   * that doesn't included any {@see FetchObservable}'s (aka Eagerloaded)
   *
   * @example
   * ```typescript
   * export class AuthorModel<Lazy = FetchObservable<unknown>> extends JsonLd<Lazy> {
   *
   *   public readonly className: string ='AuthorModel';
   *
   *   @Expose() public fullName!: string;
   *
   *   @Fetch(() => AddressModel)
   *   @Expose() public address!: Fetched<Lazy, AddressModel>;
   *
   *   public async fetchAll(): Promise<AuthorModel<EagerLoaded>> {
   *     let address: FetchObservable<AddressModel> | AddressModel = this.address;
   *
   *     if (address instanceof FetchObservable) {
   *       address = await firstValueFrom(address.pipe(take(1)));
   *     }
   *
   *     const model: AuthorModel<EagerLoaded> = this as AuthorModel<EagerLoaded>;
   *     model.address = await address.fetchAll();
   *     return model;
   *   }
   * }
   * ```
   */
  @Exclude() public fetchAll(): Promise<LazyJsonLd<EagerLoaded>> {
    return Promise.resolve(this);
  }
}
