import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { TtFeatureFlagNotDirective } from './tt-feature-flag-not.directive';
import { TtFeatureFlagDirective } from './tt-feature-flag.directive';
import { TtFeatureFlagsConfig } from './tt-feature-flags.config';
import { TtFeatureFlagsService } from './tt-feature-flags.service';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    TtFeatureFlagDirective,
    TtFeatureFlagNotDirective,
  ],
  exports: [
    TtFeatureFlagDirective,
    TtFeatureFlagNotDirective,
  ],
})
export class TtFeatureFlagsModule {
  public static forRoot(options: TtFeatureFlagsConfig): ModuleWithProviders<TtFeatureFlagsModule> {
    options = { ...new TtFeatureFlagsConfig(), ...options };

    return {
      ngModule: TtFeatureFlagsModule,
      providers: [
        { provide: TtFeatureFlagsConfig, useValue: options },
        { provide: TtFeatureFlagsService },
        {
          provide: APP_INITIALIZER,
          useFactory: () => (): void => { /* init Service*/ },
          deps: [TtFeatureFlagsService],
          multi: true,
        },
      ],
    };
  }
}
