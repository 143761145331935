import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage';
import { SentryEnvironment } from '@techniek-team/sentry-web';
import { environment } from '@scheduler-frontend/environments';
import { ViewPermission } from '../core/permission/view.permission';
import { ReleaseService } from '../shared/services/release/release.service';

@Component({
  selector: 'app-menu-layout',
  templateUrl: './menu-layout.page.html',
  styleUrls: ['./menu-layout.page.scss'],
})
export class MenuLayoutPage implements OnInit {

  /**
   * Template Injection
   */
  public readonly ViewPermission: typeof ViewPermission = ViewPermission;

  public isBeta: boolean = false;

  constructor(
    private storage: Storage,
    private releaseService: ReleaseService,
    @Inject(DOCUMENT) private document: Document,
  ) {
  }

  /**
   * @inheritDoc
   */
  public ngOnInit(): void {
    const isDev: boolean = !!(this.document.location.origin.match(/localhost$/));
    this.isBeta = !!(this.document.location.origin.match(/^http(s)?:\/\/beta/) || isDev);
    this.releaseService.openWhatsNewModalOnNewRelease();
  }


  public launchBeta(): void {
    this.isBeta = !this.isBeta;

    if (this.isBeta) {
      //noinspection JSIgnoredPromiseFromCall
      this.storage.set('enableBeta', 'true');
      if (environment.environment === SentryEnvironment.PRODUCTION) {
        this.document.location.href = 'https://beta.roostertool.mijnskoleo.nl/';
      } else {
        //eslint-disable-next-line no-console
        console.log('In production you would be redirected to beta version');
      }
    } else {
      //noinspection JSIgnoredPromiseFromCall
      this.storage.remove('enableBeta');
      if (environment.environment === SentryEnvironment.PRODUCTION) {
        this.document.location.href = 'https://roostertool.mijnskoleo.nl/?pleaseNoMoreBeta=true';
      } else {
        //eslint-disable-next-line no-console
        console.log('In production you would be redirected to production version');
      }
    }
  }
}
