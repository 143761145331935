import { TsRange } from '@techniek-team/class-transformer';
import { EagerLoaded } from '@techniek-team/fetch';
import { FetchObservable } from '@techniek-team/rxjs';
import { Exclude, Expose, Type } from 'class-transformer';
import { format, isFuture, isSameDay, isSameMonth, isSameYear, max, min } from 'date-fns';
import locale from 'date-fns/locale/nl';
import { firstValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { BusinessService } from '../business/business-service.model';
import { LocationModel } from '../location/location.model';
import { AssignmentDetail } from './assignment-detail.model';
import {
  AssignmentHasSlotDetailedWithSlot as AssignmentHasSlot,
} from './assignment-has-slot/assignment-has-slot-with-slot.model';

/**
 * The Detailed version of the Assignment resource from Scheduler-api
 */
export class AssignmentDetailWithAssignmentHasSlot<Lazy = FetchObservable<unknown>> extends AssignmentDetail<Lazy> {

  /**
   * @inheritDoc
   */
  public override readonly className: string = 'AssignmentDetailWithAssignmentHasSlot';

  /**
   * A list of all the slots or shift within the assignment. Each slot contains
   * a start/end time for when it takes place.
   */
  @Type(() => AssignmentHasSlot)
  @Expose() public assignmentHasSlots: AssignmentHasSlot<Lazy>[] = [];

  @Exclude()
  public get whoPeriodString(): string {
    const start: Date = min(this.getCorrectTimePeriods.map(period => period.start));
    const end: Date = max(this.getCorrectTimePeriods.map(period => period.start));

    if (isSameDay(start, end)) {
      return format(start, 'd MMM \'\'yy', { locale: locale });
    } else if (isSameMonth(start, end)) {
      return `${format(start, 'd')}-${format(end, 'd MMM \'\'yy')}`;
    } else if (isSameYear(start, end)) {
      return `${format(start, 'd MMM')}-${format(end, 'd MMM \'\'yy')}`;
    }
    return `${format(start, 'd MMM \'\'\'yy')}-${format(end, 'd MMM \'\'yy')}`;
  }


  /**
   * Return either the actual time periods which can either be the actual time period
   * or the slot time period if the actual isn't set yet.
   */
  @Exclude()
  public get getCorrectTimePeriods(): TsRange[] {
    return this.assignmentHasSlots.map(assignmentHasSlots => {
      return assignmentHasSlots.realTimePeriod;
    });
  }

  /**
   * Return true if the first start time of any slot lies in the past.
   */
  @Exclude()
  public isPast(): boolean {
    for (let slot of this.assignmentHasSlots) {
      if (isFuture(slot.startDate)) {
        return false;
      }
    }
    return true;
  }

  public override async fetchAll(): Promise<AssignmentDetailWithAssignmentHasSlot<EagerLoaded>> {
    const promises: Promise<unknown>[] = [];
    //eslint-disable-next-line max-len
    const assignment: AssignmentDetailWithAssignmentHasSlot<EagerLoaded> = this as AssignmentDetailWithAssignmentHasSlot<EagerLoaded>;

    if (this.location instanceof FetchObservable) {
      const promise: Promise<unknown> = firstValueFrom(this.location.pipe(take(1))).then(location => {
        assignment.location = location;
      });
      promises.push(promise);
    } else {
      assignment.location = this.location as LocationModel;
    }

    if (this.businessService instanceof FetchObservable) {
      const promise: Promise<unknown> = firstValueFrom(this.businessService.pipe(take(1))).then(businessService => {
        assignment.businessService = businessService;
      });
      promises.push(promise);
    } else {
      assignment.businessService = this.businessService as BusinessService<EagerLoaded>;
    }

    promises.concat(this.assignmentHasSlots.map((hasSlot, index) => hasSlot.fetchAll()
      .then(hasSlotEager => {
        assignment.assignmentHasSlots[index] = hasSlotEager;
      })));

    await Promise.all(promises);
    await assignment.businessService.fetchAll();
    return assignment;
  }
}

