import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { IonicModule } from '@ionic/angular';
import { TtAvatarModule } from '@techniek-team/components/avatar';
import { TtIonSelectSearchModule } from '@techniek-team/components/ion-select-search';
import { HeaderMenuComponent } from './header-menu/header-menu.component';
import { HeaderComponent } from './header.component';
import { LocationComponent } from './location/location.component';

@NgModule({
  declarations: [
    HeaderComponent,
    LocationComponent,
    HeaderMenuComponent,
  ],
  imports: [
    CommonModule,
    ScrollingModule,
    IonicModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    TtAvatarModule,
    TtIonSelectSearchModule,
  ],
  exports: [
    HeaderComponent,
    LocationComponent,
    HeaderMenuComponent,
  ],
})
export class HeaderModule {
}
