import { FormGroup } from '@angular/forms';
import { AssignmentDetailWithAssignmentHasSlot, AssignmentHasSlotDetailedWithSlot } from '@scheduler-frontend/models';
import { EagerLoaded } from '@techniek-team/fetch';
import { PermissionService } from '@techniek-team/permissions';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { AssignmentHasSlotPermission } from '../../../../../core/permission/assignment-has-slot.permission';
import { AssignmentPermission } from '../../../../../core/permission/assignment.permission';
import { AssignmentHasSlotRowForm } from './assignment-has-slot-table.component';

export class AssignmentHasSlotTableRow {

  /**
   * Subject when true the loading indicator of the time fields is shown.
   */
  public timeLoader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * Subject when true the loading indicator of the is billable checkbox is shown.
   */
  public isBillableLoader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * If true the user may remove slots.
   */
  public canRemoveSlot$: Observable<boolean>;

  constructor(
    public assignmentHasSlot: AssignmentHasSlotDetailedWithSlot<EagerLoaded>,
    public form: FormGroup<AssignmentHasSlotRowForm>,
    public assignment: AssignmentDetailWithAssignmentHasSlot<EagerLoaded>,
    private permissionService: PermissionService,
  ) {
    this.permissionUpdateSlot().then(canUpdate => {
      if (!canUpdate) {
        this.form.disable();
      }
    });
    this.canRemoveSlot$ = this.createPermissionRemoveSlotObserver();
  }

  /**
   * returns true if sure the user has permission to update this slot.
   */
  private async permissionUpdateSlot(): Promise<boolean> {
    const canUpdateAssignment: boolean = await this.permissionService.isGranted(
      AssignmentPermission,
      'UPDATE',
      this.assignment,
    );

    const canUpdateAssignmentHasSlot: boolean = await this.permissionService.isGranted(
      AssignmentHasSlotPermission,
      'UPDATE',
      this.assignmentHasSlot,
    );

    return canUpdateAssignment && canUpdateAssignmentHasSlot;
  }

  /**
   * Promise resolving true is the current user may remove this slot from the assignment.
   */
  private createPermissionRemoveSlotObserver(): Observable<boolean> {
    return from(Promise.all([
      this.permissionService.isDenied(
        AssignmentPermission,
        'REMOVE_SLOT_FROM_ASSIGNMENT',
        this.assignment,
      ),
      this.permissionService.isDenied(
        AssignmentHasSlotPermission,
        'REMOVE_SLOT_FROM_ASSIGNMENT',
        this.assignmentHasSlot,
      ),
    ]).then(([isDeniedAssignmentCanRemove, isDeniedAssignmentHasSlotCanRemove]) => {
      return !(isDeniedAssignmentCanRemove && isDeniedAssignmentHasSlotCanRemove);
    }));
  }

}
