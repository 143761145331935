import { TransitionEnum } from '@scheduler-frontend/enums';
import { Expose } from 'class-transformer';

export class TransitionOption {

  @Expose() public name!: TransitionEnum;

  @Expose() public froms!: TransitionEnum[];

  @Expose() public tos!: TransitionEnum[];
}
