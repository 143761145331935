import { Component, Input } from '@angular/core';
import { GetPayoutInformationResponse } from '../../../../../api/payout/payout.response';

@Component({
  selector: 'app-general-information',
  templateUrl: './payout-information.component.html',
  styleUrls: ['./payout-information.component.scss'],
})
export class PayoutInformationComponent {

  /**
   * Payout to display information of.
   */
  @Input() public payout?: GetPayoutInformationResponse | null;

  /**
   * Product type to show.
   */
  @Input() public businessService?: string | null;
}
