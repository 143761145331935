import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ContractTypeEnum, DeclineReasonTypeEnum } from '@scheduler-frontend/enums';
import { Assignment, DeclineReason } from '@scheduler-frontend/models';
import { isDefined } from '@techniek-team/rxjs';
import { SentryErrorHandler } from '@techniek-team/sentry-web';
import { ToastService } from '@techniek-team/services';
import { Observable, ReplaySubject, share } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DeclineReasonApi } from '../../../api/decline-reason/decline-reason.api';

function isEmploymentContractOrUnknown(assignment: Assignment): boolean {
  return (!(assignment?.contractType) || assignment.contractType === ContractTypeEnum.EMPLOYMENT_CONTRACT);
}


@Component({
  selector: 'app-unassign-modal',
  templateUrl: './unassign-modal.component.html',
  styleUrls: ['./unassign-modal.component.scss'],
})
export class UnassignModalComponent implements OnInit {

  @Input() public set assignment(assignment: Assignment) {
    if (!assignment) {
      return;
    }
    this.candidateName = assignment?.candidate?.fullName;
    this._assignment = assignment;
  }

  @Input() public absentForFullAssignment: boolean = false;

  protected declineReasonControl: FormControl<DeclineReason | null> = new FormControl<DeclineReason | null>(
    null,
    Validators.required,
  );

  protected declineReasons$!: Observable<DeclineReason[]>;

  //eslint-disable-next-line @typescript-eslint/naming-convention
  protected _assignment!: Assignment;

  protected candidateName?: string;

  protected subject: string = 'Afmelden';

  protected readonly ContractTypeEnum: typeof ContractTypeEnum = ContractTypeEnum;

  constructor(
    private modalController: ModalController,
    private declineReasonApi: DeclineReasonApi,
    private toastService: ToastService,
    private sentryErrorHandler: SentryErrorHandler,
  ) {
  }

  public ngOnInit(): void {
    this.declineReasons$ = this.createDeclineReasonsObserver();
  }

  protected cancel(): Promise<boolean> {
    return this.modalController.dismiss(undefined, 'cancel');
  }

  protected confirm(): Promise<boolean> {
    if (!this.declineReasonControl.valid) {
      return Promise.resolve(false);
    }

    // we only register that an employee is sick when the assignment.contractType is EMPLOYEE_CONTRACT or NULL.
    // we do not register that an employee is sick for IB47. There we use the 'Prive omstandigheden' declineReason.
    if (this.declineReasonControl.value?.id === 'sick') {
      return this.modalController.dismiss(undefined, 'sick');
    }
    return this.modalController.dismiss(this.declineReasonControl.value, 'confirm');
  }

  //eslint-disable-next-line max-lines-per-function
  private createDeclineReasonsObserver(): Observable<DeclineReason[]> {
    return this.declineReasonApi.getDeclineReasons()
      .pipe(
        map((reasons: DeclineReason[]) => {
          return reasons.filter((reason) => reason.declineReasonType === DeclineReasonTypeEnum.CANCEL);
        }),
        map((reasons: DeclineReason[]) => {
          let reasonSick: DeclineReason = new DeclineReason();
          // we only register that am employee is sick when it's an employee contract
          // if ib47 we use the `Privé omstandigheden` reason.
          if (isEmploymentContractOrUnknown(this._assignment)) {
            // this is a fake id which is being picked up by the { @see UnassignService }
            reasonSick.id = 'sick';
            reasonSick.description = 'Ziekmelding';
          } else {
            reasonSick.id = (reasons.find(item => item.description === 'Privé omstandigheden') as DeclineReason).id;
            reasonSick.description = 'Ziek (= uitroosteren wegens privé omstandigheden)';
          }

          reasons.push(reasonSick);
          return reasons;
        }),
        map((reasons: DeclineReason[]) => {
          return reasons.sort((a, b) => a.description.localeCompare(b.description));
        }),
        catchError(async (error) => {
          await Promise.all([
            this.sentryErrorHandler.captureError(error),
            this.toastService.error(this.sentryErrorHandler.extractMessage(
              error,
              'Het ophalen van de afwijsredenen is mislukt.',
            )),
          ]);
          return [];
        }),
        isDefined(),
        share({
          connector: () => new ReplaySubject(1),
          resetOnError: false,
          resetOnComplete: false,
          resetOnRefCountZero: false,
        }),
      );
  }
}
