import { Component, Input } from '@angular/core';

@Component({
  selector: 'tt-modal-title',
  templateUrl: './tt-modal-title.component.html',
  styleUrls: ['./tt-modal-title.component.scss'],
})
export class TtModalTitleComponent {

  @Input() public color: string = 'primary';

  @Input() public isLoading: boolean = false;

}
