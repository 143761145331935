import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@scheduler-frontend/environments';
import { Region } from '@scheduler-frontend/models';
import { Collection, Resource } from '@techniek-team/api-platform';
import { denormalize, Hydra } from '@techniek-team/class-transformer';
import { CacheService } from '@techniek-team/services';
import { hoursToMilliseconds } from 'date-fns';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GetRegionResponse } from './region.response';

export interface RegionApiInterface {
  getRegion(region: string): Observable<Region>;
  getProvinces(): Observable<Hydra<Region>>;
  getRegions(): Observable<Hydra<Region>>;
}

/**
 * Api to retrieve the {@see Region} resource from the scheduler-api
 */
@Injectable({
  providedIn: 'root',
})
export class RegionApi implements RegionApiInterface {

  constructor(
    protected httpClient: HttpClient,
    private cacheService: CacheService,
  ) {}


  /**
   * Gets a specific {@see Region}
   */
  public getRegion(region: string): Observable<Region> {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/regions/${region}`;

    //todo retrieve this from the cache as well.
    return this.httpClient.get<Resource<GetRegionResponse>>(url)
      .pipe(map(response => denormalize(Region, response)));
  }

  /**
   * Returns all {@see Region} from the scheduler Api.
   */
  public getProvinces(): Observable<Hydra<Region>> {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/regions`;

    const params: HttpParams = new HttpParams().set('isProvince', '1');

    //todo retrieve this from the cache as well.
    return this.httpClient.get<Collection<GetRegionResponse>>(url, { params: params })
      .pipe(map(response => denormalize(Region, response)));
  }

  /**
   * Returns all {@see Region} from the scheduler Api.
   *
   * The response is cashed in the {@see CacheService} and is refreshed every day.
   * The list comes sorted ascending on name.
   */
  public getRegions(): Observable<Hydra<Region>> {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/regions`;

    const call: Observable<Hydra<Region>> = this.httpClient.get<Collection<GetRegionResponse>>(
      url,
    ).pipe(
      map(response => denormalize(Region, response)),
    );

    return this.cacheService.create(
      (new Region()).constructor.name,
      call,
      { refreshInterval: hoursToMilliseconds(3) },
    );
  }

}
