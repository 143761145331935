<tt-modal (cancel)="close()">
  <tt-modal-title color="tertiary">
    Opdrachtstatus aangepast
  </tt-modal-title>

<div>
  <p *ngIf="!transition.oldState && transition.newState">
    De status van de opdracht is veranderd naar
    <span class="ion-color-{{transition.newState}}"
          [matTooltip]="AssignmentStateExplanation[transition.newState]">
      {{ AssignmentStateDisplayValues[transition.newState] | lowercase }}
      <fa-icon [fixedWidth]="true" [icon]="['far', 'exclamation-circle']"></fa-icon>
    </span>.
  </p>

  <p *ngIf="transition.oldState && transition.newState">
    De status van de opdracht is veranderd van
    <span class="ion-color-{{transition.oldState}}"
          [matTooltip]="AssignmentStateExplanation[transition.oldState]">
      {{ AssignmentStateDisplayValues[transition.oldState] | lowercase }}
      <fa-icon [fixedWidth]="true" [icon]="['far', 'exclamation-circle']"></fa-icon>
    </span>
    naar
    <span class="ion-color-{{transition.newState}}"
          [matTooltip]="AssignmentStateExplanation[transition.newState]">
      {{ AssignmentStateDisplayValues[transition.newState] | lowercase }}
      <fa-icon [fixedWidth]="true" [icon]="['far', 'exclamation-circle']"></fa-icon>
    </span>
  </p>

  <ng-container *ngIf="transition">
    <ng-container *ngFor="let message of transition.messages">
      <div class="message {{ message.messageType }}">
        <fa-icon [fixedWidth]="true"
                 *ngIf="getIcon(message.messageType) as icon"
                 [icon]="icon"></fa-icon>
        <div>{{ message.text }}</div>
      </div>
    </ng-container>
  </ng-container>
</div>

  <ion-button (click)="close()"
              slot="action"
              fill="outline"
              color="secondary">Ok
  </ion-button>
</tt-modal>
