import { Injectable } from '@angular/core';
import { SentryErrorHandler } from '@techniek-team/sentry-web';
import { Observable, Subject } from 'rxjs';
import { IMutableContext as UnleashMutableContext, UnleashClient } from 'unleash-proxy-client';
import { TtFeatureFlagsConfig } from './tt-feature-flags.config';

@Injectable()
export class TtFeatureFlagsService {

  private unleashClient: UnleashClient;

  private onInitialized$: Subject<void> = new Subject<void>();

  private onUpdate$: Subject<void> = new Subject<void>();

  constructor(
    private sentryErrorHandler: SentryErrorHandler,
    private config: TtFeatureFlagsConfig,
  ) {
    this.unleashClient = this.createUnleashInstance();

    this.unleashClient.on('initialized', async () => {
      this.onInitialized$.next(undefined);
      try {
        await this.start();
      } catch (error) {
        return this.sentryErrorHandler.captureError(error);
      }
    });
    this.unleashClient.on('updated', () => {
      this.onUpdate$.next(undefined);
    });
    this.unleashClient.on('error', (error: Error) => {
      return this.sentryErrorHandler.captureError(error);
    });

  }

  public get onInitialized(): Observable<unknown> {
    return this.onInitialized$.asObservable();
  }

  public get onUpdate(): Observable<unknown> {
    return this.onUpdate$.asObservable();
  }

  public start(): Promise<void> {
    return this.unleashClient.start();
  }

  public stop(): void {
    this.unleashClient.stop();
  }

  public updateContext(contextObj: UnleashMutableContext): Promise<void> {
    return this.unleashClient.updateContext(contextObj);
  }

  public isEnabled(feature: string): boolean {
    return this.unleashClient.isEnabled(feature);
  }

  private createUnleashInstance(): UnleashClient {
    return new UnleashClient({
      appName: this.config.appName,
      environment: this.config.environment,
      url: this.config.url,
      // the parameter is Required by the Sdk which is needed with the unleash-proxy-server
      // Some project have an open endpoint as replacement which doesn't use this.
      // therefor we give a random uuid as placeholder.
      clientKey: this.config.clientKey ?? '054a622e-e344-4144-be85-c6d43f055840',
      disableRefresh: this.config.disableRefresh,
      refreshInterval: this.config.refreshInterval,
      metricsInterval: this.config.metricsInterval,
      disableMetrics: this.config.disableMetrics,
      storageProvider: this.config.storageProvider,
      context: this.config.context,
      bootstrap: this.config.bootstrap,
      bootstrapOverride: this.config.bootstrapOverride,
    });
  }
}
