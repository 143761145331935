import { Expose } from 'class-transformer';

export class CandidateGrade {

  @Expose() public aspect!: string;

  @Expose() public grade!: number;

  @Expose() public timesGraded!: number;
}
