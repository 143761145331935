<tt-menu [header]="'Roostertool'">
  <tt-menu-item [routerLink]="['/home']"
                [icon]="['fad', 'chart-user']"
                [title]="'Dashboard'"
                *ttIsGranted="ViewPermission; subject: 'VIEW_DASHBOARD';">
  </tt-menu-item>

  <tt-menu-item [routerLink]="['/zoeken']"
                [icon]="['fad', 'magnifying-glass']"
                [title]="'Zoeken'"
                *ttIsGranted="ViewPermission; subject: 'VIEW_HQ_SEARCH';">
  </tt-menu-item>

  <tt-menu-item [routerLink]="['/agenda']"
                [icon]="['fad', 'calendar-days']"
                [title]="'Agenda'"
                *ttIsGranted="ViewPermission; subject: 'VIEW_LOCATION_AGENDA';">
  </tt-menu-item>

  <tt-menu-item [routerLink]="['/begeleiders']"
                [icon]="['fad', 'user-group']"
                [title]="'Begeleiders'"
                *ttIsGranted="ViewPermission; subject: 'VIEW_HQ_CANDIDATES';">
  </tt-menu-item>

  <tt-menu-item [routerLink]="['/moeilijke-gevallen']"
                [icon]="['fad', 'calendar-exclamation']"
                [title]="'Moeilijke Gevallen'"
                *ttIsGranted="ViewPermission; subject: 'VIEW_HQ_DIFFICULT_CASES';">
  </tt-menu-item>

  <tt-menu-item [routerLink]="['/wachtend-op-shift']"
                [icon]="['fad', 'snooze']"
                [title]="'Wachtend op shift'"
                [submenu]="awaitingSlotsSubmenu">
  </tt-menu-item>

  <ng-template #awaitingSlotsSubmenu>
    <tt-sub-menu-item [routerLink]="['/wachtend-op-shift/eerste-shift']">Eerste shift</tt-sub-menu-item>
    <tt-sub-menu-item [routerLink]="['/wachtend-op-shift/vervolg-shift']">Vervolg-shift</tt-sub-menu-item>
  </ng-template>

  <tt-menu-item [routerLink]="['/daadwerkelijk-gewerkte-uren/doorgeven-begeleiders']"
                [icon]="['fad', 'alarm-clock']"
                [title]="'Uren doorgeven app'"
                *ttIsGranted="ViewPermission; subject: 'VIEW_HQ_HOURS_APPROVAL';">
  </tt-menu-item>

  <tt-menu-item [routerLink]="['/daadwerkelijk-gewerkte-uren/doorgeven-vestigingsmanager']"
                [icon]="['fad', 'calendar-clock']"
                [title]="'Daadwerkelijke uren'"
                *ttIsGranted="ViewPermission; subject: 'VIEW_LOCATION_HOURS_APPROVAL';">
  </tt-menu-item>

  <ng-container *ttIsGranted="ViewPermission; subject: 'VIEW_HQ_PAYOUT'; else noAdminPayout">
    <tt-menu-item [routerLink]="['/uitbetaling']"
                  [icon]="['fad', 'file-invoice-dollar']"
                  [submenu]="payoutSubmenu"
                  [title]="'Uitbetaaloverzicht'">
    </tt-menu-item>

    <ng-template #payoutSubmenu>
      <tt-sub-menu-item [routerLink]="['/uitbetaling/hoofdkantoor']">Hoofdkantoor</tt-sub-menu-item>
      <tt-sub-menu-item [routerLink]="['/uitbetaling/locatie']">Locatie</tt-sub-menu-item>
    </ng-template>
  </ng-container>

  <ng-template #noAdminPayout>
    <tt-menu-item [routerLink]="['/uitbetaling/locatie']"
                  [icon]="['fad', 'file-invoice-dollar']"
                  [title]="'Uitbetaaloverzicht'"
                  *ttIsGranted="ViewPermission; subject: 'VIEW_LOCATION_PAYOUT';"></tt-menu-item>
  </ng-template>

  <tt-menu-item (click)="launchBeta()" [title]="'Beta Aanzetten'">
    <fa-icon [slot]="'start'" [icon]="['fad', 'flask']"></fa-icon>
    <ion-badge class="beta-badge" [color]="isBeta ? 'secondary' : 'medium'">new</ion-badge>
    <ion-toggle color="secondary"
                justify="space-between"
                [checked]="isBeta" [slot]="'end'">
      Beta
    </ion-toggle>
  </tt-menu-item>
</tt-menu>
