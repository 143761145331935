<table [dataSource]="logDataSource"
       class="ion-color-primary"
       matSort
       mat-table>
  <ng-container matColumnDef="icon">
    <th *matHeaderCellDef mat-header-cell></th>
    <td *matCellDef="let element;" mat-cell>
        <fa-icon class="ion-color-{{ element.iconColor }}"
                 [icon]="element.icon"
                 [fixedWidth]="true"></fa-icon>
    </td>
  </ng-container>

  <ng-container matColumnDef="initiatedAt">
    <th *matHeaderCellDef mat-sort-header mat-header-cell>Datum</th>
    <td *matCellDef="let element" mat-cell>
      {{ element.initiatedAt | date:'MMM d, y' }}
      <span>{{ element.initiatedAt | date:'HH:mm' }}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="description">
    <th *matHeaderCellDef mat-header-cell></th>
    <td mat-cell
        *matCellDef="let element"
        [ngSwitch]="element.type">
        <app-transition-log-record *ngSwitchCase="RecordType.TRANSITION"
                                   [logRecord]="element"></app-transition-log-record>
        <ng-container *ngSwitchDefault>
          {{ element.toString() }}
        </ng-container>
    </td>
  </ng-container>

  <ng-container matColumnDef="initiatedBy">
    <th *matHeaderCellDef mat-header-cell>Gebruiker</th>
    <td *matCellDef="let element" mat-cell>
        <ng-container *ngIf="element.initiatedBy">
          {{element.initiatedBy.fullName}}
        </ng-container>
    </td>
  </ng-container>

  <ng-container matColumnDef="loading">
    <th *matHeaderCellDef mat-header-cell> Gebruiker</th>
    <td *matCellDef="let element" mat-cell>
      <ion-skeleton-text animated="true"></ion-skeleton-text>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns" ></tr>
<!-- todo still needs to make it working-->
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
