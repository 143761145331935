import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { Release } from '@scheduler-frontend/models';
import { firstValueFrom, ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { ReleaseApi } from '../../../api/release/release.api';
import { WhatsNewModalComponent } from '../../modals/whats-new-modal/whats-new-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ReleaseService {
  private releases: ReplaySubject<Release[]> = new ReplaySubject<Release[]>(1);

  private readonly latestViewedReleaseKey: string = 'latest_viewed_release';

  constructor(private releaseApi: ReleaseApi,
    private modalController: ModalController,
    private storage: Storage) {
    this.releaseApi.getReleases().pipe(take(1)).subscribe(releases => this.releases.next(releases));
  }

  public async openWhatsNewModalOnNewRelease(): Promise<void> {
    const isLatestReleaseViewed: boolean = await this.isLatestReleaseViewed();

    if (!isLatestReleaseViewed) {
      this.openWhatsNewModal();
    }
  }

  public async openWhatsNewModal(): Promise<void> {
    const releases: Release[] = await firstValueFrom(this.releases);

    const modal: HTMLIonModalElement = await this.modalController.create({
      component: WhatsNewModalComponent,
      componentProps: {
        releases: releases,
      },
    });

    modal.onWillDismiss().then(() => this.setLatestViewedRelease());

    await modal.present();
  }

  private setLatestViewedRelease(): void {
    this.storage.set(this.latestViewedReleaseKey, this.getLatestReleaseTag());
  }

  private async isLatestReleaseViewed(): Promise<boolean> {
    const latestViewedRelease: string | undefined = await this.storage.get(this.latestViewedReleaseKey);

    if (latestViewedRelease) {
      return latestViewedRelease === await this.getLatestReleaseTag();
    }

    return false;
  }

  private async getLatestReleaseTag(): Promise<string> {
    const releases: Release[] = await firstValueFrom(this.releases);

    return releases[0].tagName;
  }
}
