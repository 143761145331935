import { AddressTypeEnum } from '@scheduler-frontend/enums';
import { LazyJsonLd as JsonLd } from '@techniek-team/fetch';
import { Expose } from 'class-transformer';

export class Address extends JsonLd {

  /**
   * @inheritDoc
   */
  public override readonly className: string = 'Address';

  /**
   * The property isn't aways exposed because of privacy.
   */
  @Expose() public street?: string;

  /**
   * The property isn't aways exposed because of privacy.
   */
  @Expose() public streetNumber?: number;

  /**
   * The property isn't aways exposed because of privacy.
   */
  @Expose() public numberSupplement?: string;

  /**
   * The property isn't aways exposed because of privacy.
   */
  @Expose() public postalCode?: string;

  @Expose() public town!: string;

  @Expose() public country?: string;

  @Expose() public type!: AddressTypeEnum;

  /**
   * @inheritDoc
   */
  public override toString(): string {
    return `${this.street} ${this.streetNumber}${this.numberSupplement}, ${this.postalCode} ${this.town}`;
  }
}
