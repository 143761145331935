import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import {
  InvalidRoleError,
  OAuthGuard as TtOAuthGuard,
  OAuthService,
  UserInterface,
  UserService,
} from '@techniek-team/oauth';
import { PermissionService } from '@techniek-team/permissions';
import { ViewPermission } from './permission/view.permission';

@Injectable({
  providedIn: 'root',
})
export class OAuthGuard extends TtOAuthGuard {

  constructor(
    oauthService: OAuthService,
    userService: UserService,
    toastController: ToastController,
    storage: Storage,
    protected permissionService: PermissionService,
  ) {
    super(oauthService, userService, toastController, storage);
  }

  /**
   * Check if the user has the proper role to check this dashboard.
   * @inheritDoc
   */
  protected override async checkUserRoles(currentUser: UserInterface, route: ActivatedRouteSnapshot): Promise<void> {
    if (!currentUser) {
      throw new InvalidRoleError();
    }

    const isGranted: boolean = await this.permissionService.isGranted(ViewPermission, 'VIEW_PAGE', route);

    if (!isGranted) {
      throw new InvalidRoleError();
    }
  }
}

