import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { AssignmentStateEnum, isBeforeState, TransitionEnum } from '@scheduler-frontend/enums';
import {
  Assignment,
  AssignmentDetailWithAssignmentHasSlot,
  AssignmentHasSlotDetailed,
  AssignmentHasSlotDetailedWithSlot,
  CandidateMinimal,
  DeclineReason,
  Slot,
  SlotDetailedWithAssignmentHasSlot as SlotDetailed,
} from '@scheduler-frontend/models';
import { TtSimpleModalComponent } from '@techniek-team/components/modal';
import { firstEmitFrom } from '@techniek-team/rxjs';
import { SentryErrorHandler } from '@techniek-team/sentry-web';
import { ToastService } from '@techniek-team/services';
import { firstValueFrom } from 'rxjs';
import { AssignmentApi } from '../../../api/assignment/assignment.api';
import { CandidateApi } from '../../../api/candidate/candidate.api';
import { MarkAsAbsentApi, MaskAsAbsentRequest } from '../../../api/messenger/mark-as-absent.api';
import { UnassignModalComponent } from '../../modals/absent-modal/unassign-modal.component';

@Injectable({
  providedIn: 'root',
})
export class UnassignService {
  constructor(
    private modalController: ModalController,
    private markAsAbsentApi: MarkAsAbsentApi,
    private toastService: ToastService,
    private candidateApi: CandidateApi,
    private assignmentApi: AssignmentApi,
    private errorHandler: SentryErrorHandler,
  ) {
  }

  /**
   * Mark the current candidate assigned to the slot as absent.
   */
  public async unassign(
    target: SlotDetailed | AssignmentDetailWithAssignmentHasSlot,
    targetAssignmentHasSlots?: AssignmentHasSlotDetailedWithSlot[],
    fullAssignment: boolean = false,
  ): Promise<boolean> {
    if (isBeforeState(this.getAssignmentFromTarget(target).state, AssignmentStateEnum.WAITING_FOR_CONFIRMATION)) {
      return this.removeSlot(target);
    }

    const { data: declineReason, role } = await this.showDeclineReason(target, fullAssignment);

    if (role === 'sick') {
      return this.markAsAbsent(target);
    }
    if (role === 'confirm' && declineReason) {
      return this.markCandidateRejection(target, declineReason, fullAssignment, targetAssignmentHasSlots);
    }
    return false;
  }

  private async showDeclineReason(
    target: SlotDetailed | AssignmentDetailWithAssignmentHasSlot,
    absentForFullAssignment: boolean = false,
  ): Promise<OverlayEventDetail<DeclineReason | undefined>> {
    const confirmModal: HTMLIonModalElement = await this.modalController.create({
      component: UnassignModalComponent,
      componentProps: {
        assignment: this.getAssignmentFromTarget(target),
        absentForFullAssignment: absentForFullAssignment,
      },
      cssClass: ['stack-modal'],
    });
    void confirmModal.present();

    return confirmModal.onWillDismiss<DeclineReason | undefined>();
  }

  private async markAsAbsent(
    target: SlotDetailed | AssignmentDetailWithAssignmentHasSlot,
  ): Promise<boolean> {
    const candidate: CandidateMinimal = this.getCandidateFromTarget(target);
    try {
      const promises: Promise<void>[] = [];
      for (let hasSlot of this.getAssignmentHasSlotsFromTarget(target)) {
        promises.push(firstEmitFrom(this.markAsAbsentApi.execute(new MaskAsAbsentRequest(hasSlot))));
      }
      await Promise.all(promises);
      return this.toastService.create(`${candidate.fullName} is ziekgemeld.`).then(_ => true);
    } catch (error) {
      return Promise.all([
        this.errorHandler.captureError(error),
        this.toastService.error(this.errorHandler.extractMessage(
          error,
          `Let op! Het ziekmelden van ${candidate.fullName} is mislukt!`,
        )),
      ]).then(() => false);
    }
  }

  //eslint-disable-next-line max-lines-per-function
  private async markCandidateRejection(
    target: SlotDetailed | AssignmentDetailWithAssignmentHasSlot,
    declineReason: DeclineReason,
    declineForFullAssignment: boolean,
    targetAssignmentHasSlots?: AssignmentHasSlotDetailedWithSlot[],
  ): Promise<boolean> {
    const candidate: CandidateMinimal = this.getCandidateFromTarget(target);
    const slots: Slot[] = this.getSlotsFromTarget(target, declineForFullAssignment, targetAssignmentHasSlots);
    try {
      await firstEmitFrom(this.candidateApi.postCandidateRejection({
        candidate: candidate,
        declineReason: declineReason.id,
        slots: slots,
      }));

      if (declineForFullAssignment) {
        await firstValueFrom(this.assignmentApi.applyTransition({
          assignment: this.getAssignmentFromTarget(target),
          transition: TransitionEnum.UNASSIGN,
        }));
        return this.toastService.create(`${candidate.fullName} is uitgeroosterd van de gehele opdracht.`)
          .then(_ => true);
      }

      await firstEmitFrom(this.assignmentApi.removeSlot({ slots: slots }));
      return this.toastService.create(`${candidate.fullName} is uitgeroosterd van de shift.`).then(_ => true);
    } catch (error) {
      return Promise.all([
        this.errorHandler.captureError(error),
        this.toastService.error(this.errorHandler.extractMessage(
          error,
          `Let op! Het uitroosteren van ${candidate.fullName} is mislukt!`,
        )),
      ]).then(() => false);
    }
  }

  private async removeSlot(target: SlotDetailed | AssignmentDetailWithAssignmentHasSlot): Promise<boolean> {
    const confirmModal: HTMLIonModalElement = await this.modalController.create({
      component: TtSimpleModalComponent,
      componentProps: {
        title: 'Shift openstellen',
        message: `Begeleider ${this.getCandidateFromTarget(target)?.fullName} zal worden ontkoppeld van deze shift`,
      },
    });
    void confirmModal.present();
    const { role } = await confirmModal.onWillDismiss();

    if (role === 'confirm') {
      try {
        await firstEmitFrom(this.assignmentApi.removeSlot({ slots: this.getSlotsFromTarget(target) }));
        return this.toastService.create('Shift is losgekoppeld.').then(_ => true);
      } catch (error) {
        return Promise.all([
          this.errorHandler.captureError(error),
          this.toastService.error(this.errorHandler.extractMessage(
            error,
            'Er is iets misgegaan bij het openstellen van deze shift.',
          )),
        ]).then(() => false);
      }
    }
    return Promise.resolve(false);
  }

  private getSlotsFromTarget(
    target: SlotDetailed | AssignmentDetailWithAssignmentHasSlot,
    declineForFullAssignment: boolean = false,
    targetAssignmentHasSlots?: AssignmentHasSlotDetailedWithSlot[],
  ): Slot[] {
    if (target instanceof SlotDetailed) {
      return [target];
    }

    if (declineForFullAssignment) {
      return target.assignmentHasSlots.map(item => item.slot);
    }
    return (targetAssignmentHasSlots as AssignmentHasSlotDetailedWithSlot[]).map(item => item.slot);
  }

  private getAssignmentFromTarget(target: SlotDetailed | AssignmentDetailWithAssignmentHasSlot): Assignment {
    if (target instanceof SlotDetailed) {
      return target.assignment as Assignment;
    }
    return target;
  }

  private getCandidateFromTarget(target: SlotDetailed | AssignmentDetailWithAssignmentHasSlot): CandidateMinimal {
    return this.getAssignmentFromTarget(target).candidate as CandidateMinimal;
  }

  private getAssignmentHasSlotsFromTarget(
    target: SlotDetailed | AssignmentDetailWithAssignmentHasSlot,
  ): AssignmentHasSlotDetailed[] {
    if (target instanceof SlotDetailed) {
      return [target.assignmentHasSlot as AssignmentHasSlotDetailed];
    }
    return target.assignmentHasSlots;
  }
}
