import { FetchObservable } from '@techniek-team/rxjs';
import { Expose, Type } from 'class-transformer';
import { Assignment } from '../assignment.model';
import { AssignmentHasSlotDetailed } from './assignment-has-slot-detailed.model';

//eslint-disable-next-line max-len
export class AssignmentHasSlotDetailedWithAssignment<Lazy = FetchObservable<unknown>> extends AssignmentHasSlotDetailed<Lazy> {

  /**
   * @inheritDoc
   */
  public override readonly className: string = 'AssignmentHasSlotDetailedWithAssignment';

  /**
   * Assignment where the slot belongs to
   *
   * Note: this property is only available when this resource is retrieved
   * through the {@see SlotApi} or {@see SearchApi}. In other cases look at
   * the parent of this resource
   */
  @Type(() => Assignment)
  @Expose() public assignment!: Assignment;
}
