<table mat-table
       class="ion-color-primary"
       [dataSource]="rows">
  <ng-container matColumnDef="marking">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell
        data-test="has-slot-marking"
        *matCellDef="let element">
      <ng-container *ttFeatureFlag="'absenceregistration'">
        <fa-icon *ngIf="(element|asType:TableRow).assignmentHasSlot.purpose === AssignmentHasSlotPurposeEnum.ABSENCE"
                 [icon]="['fad', 'virus']">
        </fa-icon>
      </ng-container>
    </td>
  </ng-container>

  <ng-container matColumnDef="location">
    <th mat-header-cell *matHeaderCellDef>Locatie</th>
    <td mat-cell
        data-test="has-slot-location"
        *matCellDef="let element">
      {{(assignment.location | ttFetch)?.getName || 'Online'}}
    </td>
  </ng-container>

  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef>Datum</th>
    <td mat-cell
        data-test="has-slot-date"
        *matCellDef="let element">
      {{(element|asType:TableRow).assignmentHasSlot.slot.timePeriod.start |  date: 'dd-MM-yyyy' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="time">
    <th mat-header-cell
        matTooltip="Geplande tijden"
        matTooltipPosition="above"
        *matHeaderCellDef>
      Tijd
    </th>
    <td mat-cell
        data-test="has-slot-time"
        *matCellDef="let element">
      {{ (element|asType:TableRow).assignmentHasSlot.slot.timePeriod.format('HH:mm', ' - ')}}
    </td>
  </ng-container>

  <ng-container matColumnDef="breakTime">
    <th mat-header-cell
        *matHeaderCellDef
        matTooltip="De genomen pauze uitgedrukt in minuten. Deze kun je pas wijzigen als de opdracht bevestigd is."
        matTooltipPosition="above">
      Pauze
    </th>
    <td mat-cell
        *matCellDef="let element"
        [formGroup]="(element|asType:TableRow).form"
        matTooltip="Pauze in minuten"
        matTooltipPosition="above">
      <ng-container *ngIf="(element|asType:TableRow).form.get('breakTime') as breakTime">
        <ion-input formControlName="breakTime"
                   data-test="has-slot-break-input"
                   type="text"
                   aria-label="pauze"
                   fill="outline"
                   inputmode="numeric"
                   [errorText]="breakTime.hasError('required') ? 'Voer een geldig waarde in.' : ((element|asType:TableRow).form.hasError('breakIsLongerThenWorkingTimes')) ? 'De pauze is langer dan de lengte van de shift.' : ''"
                   pattern="\d*"
                   mode="md"></ion-input>
      </ng-container>
    </td>

  </ng-container>
  <ng-container matColumnDef="actualTime">
    <th mat-header-cell *matHeaderCellDef> (Werkelijk)</th>
    <td mat-cell
        data-test="has-slot-actualTime"
        matTooltip="Daadwerkelijk gewerkte uren van de begeleider. Deze kun je alleen wijzigen als de opdracht de status 'bevestigd' of 'wachtend op goedkeuring' heeft."
        matTooltipPosition="above"
        *matCellDef="let element; let index = index"
        [formGroup]="(element|asType:TableRow).form">
      <ng-container *ngIf="!(element|asType:TableRow).assignmentHasSlot.slot.isCombined"
                    formGroupName="actualTimePeriod">
        <ng-container *ngIf="(element|asType:TableRow).form.get('actualTimePeriod') as actualTime">
          <tt-time-control formControlName="start"
                           ariaLabel="start time"
                           [fill]="'outline'"
                           data-test="has-slot-actualTime-start"></tt-time-control>
          -
          <tt-time-control formControlName="end"
                           ariaLabel="end time"
                           [fill]="'outline'"
                           data-test="has-slot-actualTime-end"></tt-time-control>
          <ion-button size="small"
                      fill="clear"
                      [disabled]="element.form.disabled"
                      (click)="confirmTime(element)">
            <ion-icon name="checkmark-outline"
                      slot="icon-only"
                      *ngIf="((element|asType:TableRow).timeLoader | async) === false; else loading"></ion-icon>
            <ng-template #loading>
              <ion-spinner slot="icon-only"></ion-spinner>
            </ng-template>

          </ion-button>

          <div>
            <ng-container *ngIf="!actualTime.disabled && actualTime.dirty && actualTime.hasError('tsRangeNotInRange')">
              Je kan niet beginnen met een shift nadat je gestopt bent.
            </ng-container>
            <ng-container *ngIf="actualTime.get('start') as startTime">
              <ng-container *ngIf="!startTime.disabled && startTime.dirty && (startTime.hasError('pattern') || startTime.hasError('required'))">
                Geeft een geldige start tijd op.
              </ng-container>
            </ng-container>
            <ng-container *ngIf="actualTime.get('end') as endTime">
              <ng-container *ngIf="!endTime.disabled && endTime.dirty && (endTime.hasError('pattern') || endTime.hasError('required'))">
                Geef een geldige eindtijd op.
              </ng-container>
            </ng-container>

          </div>
        </ng-container>

      </ng-container>

    </td>
  </ng-container>
  <ng-container matColumnDef="role">
    <th mat-header-cell *matHeaderCellDef> Rol</th>
    <td mat-cell
        data-test="has-slot-role"
        *matCellDef="let element"> {{((element|asType:TableRow).assignmentHasSlot.slot.role | ttFetch)?.name}} </td>
  </ng-container>
  <ng-container matColumnDef="saldo">
    <th mat-header-cell
        matTooltip="Schrijf het saldo af op de strippenkaart van de leerling"
        matTooltipPosition="above"
        *matHeaderCellDef>
      Saldo
    </th>
    <td mat-cell
        *matCellDef="let element">
      <ng-container *ngIf="(element|asType:TableRow) as row">
        <ion-toggle data-test="has-slot-saldo-input"
                    color="secondary"
                    aria-label="heeft saldo"
                    [disabled]="(changeIsBillablePermission | async) || (element|asType:TableRow).assignmentHasSlot.purpose !== AssignmentHasSlotPurposeEnum.SCHEDULING"
                    [checked]="(element|asType:TableRow).assignmentHasSlot.slot.lesson.isBillable"
                    (ionChange)="onIsBillableClick(row, $event)">
        </ion-toggle>
      </ng-container>
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell
        data-test="has-slot-actions"
        *matCellDef="let element">
      <ng-container *ngIf="(element|asType:TableRow) as row">
        <ion-button [id]="'popover-button-'+ row.assignmentHasSlot.slot.getId()"
                    fill="clear"
                    size="small"
                    color="medium">
          <ion-icon icon="ellipsis-vertical-outline"></ion-icon>
        </ion-button>
        <ion-popover [trigger]="'popover-button-'+ row.assignmentHasSlot.slot.getId()"
                     [showBackdrop]="false"
                     [dismissOnSelect]="true">
          <ng-template>
            <ion-content>
              <ion-list>
                <ion-item [matTooltip]="(row.canRemoveSlot$ | async) ? 'Verwijder shift uit deze opdracht' : 'Deze shift kan niet worden verwijderd uit deze opdracht'"
                          (click)="removeSlot(row)"
                          *ngIf="assignment.state === AssignmentStateEnum.UNASSIGNED"
                          [disabled]="(row.canRemoveSlot$ | async) === false"
                          [button]="true"
                          [detail]="false"
                          lines="none">
                  <fa-icon [icon]="['far', 'trash-can']" slot="start"></fa-icon>
                  Shift ontkoppelen
                </ion-item>
                <ion-item *ngIf="row.assignmentHasSlot.purpose === AssignmentHasSlotPurposeEnum.SCHEDULING"
                          (click)="unassignSlot(row)"
                          [disabled]="row.assignmentHasSlot.slot.isParentSlot()"
                          [button]="true"
                          [detail]="false"
                          lines="none">
                  <fa-icon [icon]="['fad', 'arrow-right-from-bracket']" slot="start"></fa-icon>
                  Uitroosteren
                </ion-item>
                <ion-item *ngIf="row.assignmentHasSlot.purpose === AssignmentHasSlotPurposeEnum.ABSENCE"
                          [disabled]="row.assignmentHasSlot.slot.isParentSlot()"
                          (click)="markAsPresent(row)"
                          [button]="true"
                          [detail]="false"
                          lines="none">
                  <fa-icon [icon]="['fad', 'virus']" slot="start"></fa-icon>
                  Ziekmelden ongedaan maken
                </ion-item>
              </ion-list>
            </ion-content>
          </ng-template>
        </ion-popover>
      </ng-container>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row
      *matRowDef="let row; let i = index; columns: displayedColumns;"
      [class.absence]="row.assignmentHasSlot.purpose === AssignmentHasSlotPurposeEnum.ABSENCE"></tr>
</table>
