import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@scheduler-frontend/environments';
import { AssignmentHasSlotDetailed } from '@scheduler-frontend/models';
import { TsRange } from '@techniek-team/class-transformer';
import { formatISO } from 'date-fns';
import { Observable } from 'rxjs';

export class MaskAsAbsentRequest {
  constructor(
    public assignmentHasSlot: AssignmentHasSlotDetailed,
  ) {
  }
}

@Injectable({
  providedIn: 'root',
})
export class MarkAsAbsentApi {

  constructor(protected httpClient: HttpClient) {}

  public execute(message: MaskAsAbsentRequest): Observable<void> {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/mark-as-absent`;

    return this.httpClient.post<void>(url, {
      assignmentHasSlotId: message.assignmentHasSlot.getId(),
      absencePeriod: {
        start: formatISO((message.assignmentHasSlot.actualTimePeriod as TsRange).start),
        end: formatISO((message.assignmentHasSlot.actualTimePeriod as TsRange).end),
        inclusiveStart: true,
        inclusiveEnd: false,
      },
    });
  }
}
