<tt-modal (cancel)="dismiss()">
  <tt-modal-title>Uitbetalingsregel toevoegen</tt-modal-title>
  <form *ngIf="allowedArticleCodes && allowedArticleCodes.size > 0"
        class="extraCompensationLine"
        [formGroup]="compensationLineForm">
    <ion-select formControlName="article"
                label="Artikelcode"
                labelPlacement="stacked"
                interface="popover"
                fill="outline"
                placeholder="Selecteer Artikelcode">
      <ion-select-option *ngFor="let code of allowedArticleCodes | keyvalue"
                         [value]="code.key">
        {{ code.value }}
      </ion-select-option>
    </ion-select>
    <ion-input fill="outline"
               label="Omschrijving"
               labelPlacement="stacked"
               formControlName="description"
               placeholder="Omschrijving"></ion-input>
    <ion-select formControlName="assignmentHasSlot"
                label="Shift-datum & tijd"
                labelPlacement="stacked"
                fill="outline"
                interface="popover"
                placeholder="Selecteer Shift-datum & tijd">
      <ng-container *ngIf="(selectableAssignmentHasSlots$ | async) as assignmentHasSlots">
        <ng-container *ngIf="assignmentHasSlots.length > 0; else noEntriesFound">
          <ion-select-option *ngFor="let assignmentHasSlot of assignmentHasSlots"
                             [value]="assignmentHasSlot">
            {{assignmentHasSlot.slot.timePeriod.start | date:'dd-MM-yyyy HH:mm'}}
            - {{assignmentHasSlot.slot.timePeriod.end | date:'HH:mm'}}
          </ion-select-option>
        </ng-container>

        <ng-template #noEntriesFound>
          <ion-select-option [disabled]="true">
            Geen toegestane shifts gevonden.
          </ion-select-option>
        </ng-template>
      </ng-container>
    </ion-select>

    <div class="number-inputs">
      <!-- TODO Fix the setValue() of the tt-number-input. Otherwise it doesn't work. -->
<!--      <tt-number-input formControlName="quantity"-->
<!--                       label="Aantal"-->
<!--                       labelPlacement="stacked"-->
<!--                       fill="outline"-->
<!--                       [min]="0"></tt-number-input>-->
      <ion-input formControlName="quantity"
                 label="Aantal"
                 labelPlacement="stacked"
                 fill="outline"
                 [min]="0">
      </ion-input>

      <tt-number-input formControlName="amount"
                       label="Bedrag"
                       labelPlacement="stacked"
                       fill="outline"
                       [allowDecimals]="true"
                       [min]="0"></tt-number-input>
    </div>

  </form>
  <ion-button slot="action"
              [disabled]="(createCompensationLineLoader$ | async) || compensationLineForm.invalid"
              (click)="addLine()">
    Toevoegen
    <ion-spinner *ngIf="createCompensationLineLoader$ | async"></ion-spinner>
  </ion-button>
</tt-modal>
