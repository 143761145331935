<ng-container *ngIf="urgentForm"
              [formGroup]="urgentForm">
  <ion-list>
    <ion-list-header>
      Urgentie
      <ion-spinner *ngIf="loader$ | async"></ion-spinner>
    </ion-list-header>
    <ion-item lines="none">
      <ion-toggle labelPlacement="end"
                  color="secondary"
                  formControlName="isUrgent">
        Spoed
      </ion-toggle>
    </ion-item>
  </ion-list>

</ng-container>
