<tt-modal (cancel)="cancel()">
  <tt-modal-title color="tertiary">Uitroosteren bevestigen</tt-modal-title>
  <p slot="center"
     *ngIf="candidateName">
    Weet je zeker dat je {{ candidateName }} {{ absentForFullAssignment ? 'voor de gehele opdracht' : ''}} wilt uitroosteren?
  </p>


  <h3 slot="center">Vul hieronder de reden van uitroosteren in.</h3>
  <ion-select slot="center"
              *ngIf="(declineReasons$ | async) as declineReasons"
              [interfaceOptions]="{ size: 'cover', alignment: 'start' }"
              [formControl]="declineReasonControl"
              interface="popover"
              fill="outline"
              aria-label="afwijsredenen"
              placeholder="Selecteer een reden"
              data-test="decline-reason-select">
    <ion-select-option *ngFor="let reason of declineReasons"
                       [value]="reason">
      {{ reason.description }}
    </ion-select-option>
  </ion-select>
  <div slot="action">
    <ion-button (click)="cancel()"
                data-test="absent-modal-cancel"
                slot="action"
                fill="clear"
                size="small">Sluiten
    </ion-button>
    <ion-button color="secondary"
                data-test="absent-modal-confirm"
                (click)="confirm()"
                [disabled]="!declineReasonControl.valid">
      Bevestigen
    </ion-button>
  </div>
</tt-modal>
