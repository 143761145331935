import { LazyJsonLd as JsonLd } from '@techniek-team/fetch';
import { FetchObservable } from '@techniek-team/rxjs';
import { Expose } from 'class-transformer';

/**
 * The standard version of the Action Resource.
 */
export class Action<Lazy = FetchObservable<unknown>> extends JsonLd<Lazy> {

  /**
   * @inheritDoc
   */
  public override readonly className: string = 'Action';

  /**
   * Description of this action
   */
  @Expose() public description!: string;

  /**
   * @inheritDoc
   */
  public override toString(): string {
    return this.description;
  }
}
