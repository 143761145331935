import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { TtFeatureFlagsService } from './tt-feature-flags.service';

/**
 * To check if a toggle is enabled from within a Component template, you can use
 * the ttFeatureFlag and ttFeatureFlagNot directives:
 *
 * @example
 * ```angular-html
 *   <some-component *ttFeatureFlag="'test.toggle'"></some-component>
 *   <some-other-component *ttFeatureFlagNot="'test.toggle'"></some-other-component>
 * ```
 */
@Directive({
  // todo move to shared frontend library
  //eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[ttFeatureFlag]',
})
export class TtFeatureFlagDirective implements OnInit {

  /**
   * boolean stating if the content within this directive is available.
   */
  private isVisible: boolean = false;

  /**
   * The name of the feature toggle. This is used to check if the feature should
   * still be shown or not on a update from the backend.
   */
  private toggleName: string = '';

  @Input() public set ttFeatureFlag(toggleName: string) {
    if (this.ttFeatureFlagsService.isEnabled(toggleName)) {
      if (!this.isVisible) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isVisible = true;
      }
    } else {
      this.viewContainer.clear();
      this.isVisible = false;
    }
    this.toggleName = toggleName;
  }

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private ttFeatureFlagsService: TtFeatureFlagsService,
  ) {}

  public ngOnInit(): void {
    this.ttFeatureFlagsService.onUpdate.subscribe(() => {
      this.ttFeatureFlag = this.toggleName;
    });
  }
}
