import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionUserInterface } from '../contracts/permission-user.interface';
import { PermissionService } from '../service/permission.service';
import { BasePermission } from '../base-permission/base.permission';

interface ClassConstructor<T> {
  //eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/prefer-function-type
  new (...args: any[]): T;
}

//noinspection AngularMissingOrInvalidDeclarationInModule
/**
 * This directive gives the ability to use the {@see PermissionService.isGranted}
 * method from your template.
 *
 * @example
 * ```html
 *  <div *ttIsGranted="AssignmentPermission; subject: 'CREATE_ASSIGNMENT_COMPENSATION_LINE'; extraArgs:
 *   [this.assignment]"
 *    <!-- html where you needed permission for -->
 *  </div>
 * ```
 */
@Directive({
  selector: '[ttIsGranted]',
})
export class IsGrantedDirective<U extends PermissionUserInterface = PermissionUserInterface> implements OnInit {
  @Input() public set ttIsGranted(permissionCls: ClassConstructor<BasePermission<U>>) {
    this.permissionCls = permissionCls;
  }

  @Input() public set ttIsGrantedSubject(subject: string) {
    this.subject = subject;
  }

  @Input() public set ttIsGrantedExtraArgs(extraArgs: unknown[]) {
    this.extraArgs = extraArgs ?? [];
  }

  @Input() public ttIsGrantedElse?: TemplateRef<unknown>;

  private hasView: boolean = false;

  private permissionCls!: ClassConstructor<BasePermission<U>>;

  private subject!: string;

  private extraArgs: unknown[] = [];

  constructor(
    private permissionService: PermissionService<U>,
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
  ) { }

  /**
   * @inheritDoc
   */
  public ngOnInit(): void {
    this.permissionService.isGranted(this.permissionCls, this.subject, ...(this.extraArgs ?? [])).then(isGranted => {
      this.viewContainer.clear();

      if (isGranted) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.hasView = true;
      } else if (this.ttIsGrantedElse) {
        this.viewContainer.createEmbeddedView(this.ttIsGrantedElse);
        this.hasView = false;
      } else {
        this.hasView = false;
      }
    });
  }
}
