<ng-container *ngIf="logRecord.from; else creationRecord">
  De opdracht is van status&nbsp;
  <span class="ion-color-{{logRecord.from}}">
    {{ AssignmentStateDisplayValues[logRecord.from] }}
    <fa-icon [fixedWidth]="true" [icon]="['far', 'exclamation-circle']"></fa-icon>
  </span>
  &nbsp;naar de status&nbsp;
  <span class="ion-color-{{logRecord.to}}">
    {{ AssignmentStateDisplayValues[logRecord.to] }}
    <fa-icon [fixedWidth]="true" [icon]="['far', 'exclamation-circle']"></fa-icon>
  </span>.
</ng-container>
<ng-template #creationRecord>
  De opdracht is aangemaakt met de status status&nbsp;
  <span class="ion-color-{{logRecord.to}}">
  {{ AssignmentStateDisplayValues[logRecord.to] }}
    <fa-icon [fixedWidth]="true" [icon]="['far', 'exclamation-circle']"></fa-icon>
</span>.
</ng-template>
