<ion-item lines="none"
          button
          (mouseenter)="presentPopover($event)"
          (mouseleave)="leavePopover($event)"
          [@.disabled]="true"
          [routerLink]="routerLink"
          (click)="leavePopover($event)"
          [routerLinkActiveOptions]="routerLinkActiveOptions"
          [class.expanded]="isExpanded | async"
          routerLinkActive="active"
          #routerLinkActive="routerLinkActive">
  <ng-container *ngIf="!(ref.innerHTML.trim())">
    <fa-icon *ngIf="isFontAwesome(iconUrl) as fontAwesomeIcon; else ionIcon"
             [slot]="(isExpanded | async) ? 'start' : ''"
             size="lg"
             [icon]="fontAwesomeIcon">
    </fa-icon>
  </ng-container>
  <ng-template #ionIcon>
    <ion-icon *ngIf="iconUrl"
              [slot]="(isExpanded | async) ? 'start' : ''"
              [icon]="iconUrl">
    </ion-icon>
  </ng-template>
  <ion-label *ngIf="!(ref.innerHTML.trim()) && title && (isExpanded | async)">
    {{ title }}
  </ion-label>
  <div class="custom-content" #ref>
    <ng-content></ng-content>
  </div>
</ion-item>
<ion-popover #popover
             [dismissOnSelect]="true"
             [keepContentsMounted]="true"
             [isOpen]="popoverOpen"
             [showBackdrop]="false"
             class="menu-item-popover"
             side="right">
  <ng-template>
    <ion-content class="menu-item-popover" (mouseleave)="leavePopover($event)">
      <ion-list class="ion-no-padding">
        <ion-list-header>{{ title }}</ion-list-header>

        <ng-container *ngIf="submenu">
          <ng-container *ngTemplateOutlet="submenu"></ng-container>
        </ng-container>
      </ion-list>
    </ion-content>
  </ng-template>
</ion-popover>
