import { Expose, Type } from 'class-transformer';
import { Product } from '../product/product.model';
import { ScheduleMinimal } from './schedule-minimal.model';

/**
 * Standard version of the Schedule Resource.
 */
export class Schedule extends ScheduleMinimal {

  /**
   * @inheritDoc
   */
  public override readonly className: string = 'Schedule';

  /**
   * Description of the schedule.
   */
  @Expose() public description?: string;

  /**
   * Product of the scheduler
   */
  @Type(() => Product)
  @Expose() public product!: Product;

}
