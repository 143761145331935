<tt-modal (cancel)="cancel()">
  <tt-modal-title color="secondary">{{ title }}</tt-modal-title>
  <p>{{ message }}</p>
  <ion-button *ngFor="let button of buttons"
              slot="action"
              (click)="buttonClick(button)"
              [ngClass]="button?.cssClass ?? ''"
              [fill]="button?.fill"
              [color]="button?.color">
    {{button.text}}
  </ion-button>
</tt-modal>
