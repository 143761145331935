<h4>Algemene informatie</h4>

<div>
  <div class="label">Skoleo ID</div>
  <div>
    <ng-container *ngIf="payout; else loadingSkoleoId">{{ payout.general.candidateSkoleoId }}</ng-container>
    <ng-template #loadingSkoleoId>
      <ion-skeleton-text animated="true" style="width: 80px;"></ion-skeleton-text>
    </ng-template>
  </div>
</div>

<div>
  <div class="label">Project ID</div>
  <div>
    <ng-container *ngIf="payout; else loadingProjectCode">{{ payout.general.projectCode }}</ng-container>
    <ng-template #loadingProjectCode>
      <ion-skeleton-text animated="true" style="width: 100px;"></ion-skeleton-text>
    </ng-template>
  </div>
</div>

<div>
  <div class="label">Producttype</div>
  <div>
    <ng-container *ngIf="businessService; else loadingBusinessService">{{ businessService }}</ng-container>
    <ng-template #loadingBusinessService>
      <ion-skeleton-text animated="true" style="width: 80px;"></ion-skeleton-text>
    </ng-template>
  </div>
</div>

<div>
  <div class="label">Divisie</div>
  <div>
    <ng-container *ngIf="payout; else loadingDivision">{{ payout.general.division }}</ng-container>
    <ng-template #loadingDivision>
      <ion-skeleton-text animated="true" style="width: 40px;"></ion-skeleton-text>
    </ng-template>
  </div>
</div>

