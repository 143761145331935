<ion-input [formControl]="timeControl"
           [fill]="fill"
           [label]="label"
           [labelPlacement]="labelPlacement"
           [maskito]="maskItoConfig"
           [maskitoElement]="maskPredicate"
           (ionFocus)="onTouch()"
           data-test="time-input"
           type="text"
           [clearInput]="clearInput"
           [clearOnEdit]="clearOnEdit">
</ion-input>
