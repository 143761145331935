import { LazyJsonLd as JsonLd } from '@techniek-team/fetch';
import { Expose } from 'class-transformer';

/**
 * The Bare minimal version of the Schedule Resource.
 */
export class ReleaseImage extends JsonLd {

  /**
   * @inheritDoc
   */
  public override readonly className: string = 'ReleaseImage';

  @Expose() public name!: string;

  @Expose() public url!: string;

  /**
   * @inheritDoc
   */
  public override toString(): string {
    return this.name;
  }
}
