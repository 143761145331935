import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TtModalModule } from '@techniek-team/components/modal';
import { WhatsNewModalComponent } from './whats-new-modal.component';

@NgModule({
  declarations: [
    WhatsNewModalComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    TtModalModule,
    NgOptimizedImage,
  ],
  exports: [
    WhatsNewModalComponent,
  ],
})
export class WhatsNewModalModule { }
