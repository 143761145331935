export enum AllTransitionEnum {
  DRAFT = 'draft', // This isn't a actual transition because it's the first state of the assignment process.
  ASSIGN = 'assign',
  REQUEST_CONFIRMATION = 'request_confirmation',
  REJECT = 'reject',
  CONFIRM = 'confirm',
  CONFIRM_DEFINITIVE = 'confirm_definitive',
  COMPLETE = 'complete',
  APPROVE = 'approve',
  FINALIZE = 'finalize',
  CANCEL = 'cancel',
  WITHDRAW = 'withdraw',
  REVERT_APPROVAL = 'revert_approval',
  REVERT_FINALIZE = 'revert_finalize',
  UNDO_CONFIRM_DEFINITIVE = 'undo_confirm_definitive',
  UNASSIGN = 'unassign',
}

export enum TransitionEnum {
  ASSIGN = 'assign',
  REQUEST_CONFIRMATION = 'request_confirmation',
  REJECT = 'reject',
  CONFIRM = 'confirm',
  CONFIRM_DEFINITIVE = 'confirm_definitive',
  COMPLETE = 'complete',
  APPROVE = 'approve',
  FINALIZE = 'finalize',
  CANCEL = 'cancel',
  WITHDRAW = 'withdraw',
  REVERT_APPROVAL = 'revert_approval',
  REVERT_FINALIZE = 'revert_finalize',
  UNDO_CONFIRM_DEFINITIVE = 'undo_confirm_definitive',
  UNASSIGN = 'unassign',
}

/* eslint-disable @typescript-eslint/naming-convention */
export enum TransitionDisplayValues {
  reject = 'openstaand',
  confirm = 'bevestigd',
  finalize = 'afgerond',
  cancel = 'openstaand',
  withdraw = 'openstaand',
  revert_finalize = 'bevestigd',
}

export enum TransitionToMessage {
  assign = 'voorlopig inplannen',
  request_confirmation = 'vraag bevestiging',
  reject = 'afwijzen',
  confirm_provisionally = 'voorlopig bevestigen',
  confirm = 'bevestigen',
  confirm_definitive = 'definitief bevestigen',
  complete = 'markeren als \'wachtend op goedkeuring\'',
  approve = 'goedkeuren',
  finalize = 'afronden',
  unassign = 'uitroosteren',
  withdraw = 'intrekken',
  revert_approval = 'goedkeuring terugdraaien',
  revert_finalize = 'afronden terugdraaien',
  undo_confirm_definitive = 'terugzetten naar \'voorlopig bevestigd\'',
  cancel = 'annuleren',
}

/* eslint-enable @typescript-eslint/naming-convention */
export const transitionMessages: Omit<Record<TransitionEnum, string>, 'draft'> = {
  [TransitionEnum.ASSIGN]: 'Weet je zeker dat je deze opdracht als concept wilt inplannen?',
  [TransitionEnum.REQUEST_CONFIRMATION]: 'Weet je zeker dat je voor deze opdracht wilt vragen om bevestiging?',
  [TransitionEnum.REJECT]: 'Weet je zeker dat je deze opdracht wilt afwijzen?',
  [TransitionEnum.CONFIRM]: 'Weet je zeker dat je deze opdracht wilt bevestigen?',
  [TransitionEnum.CONFIRM_DEFINITIVE]: 'Weet je zeker dat je deze opdracht definitief wilt bevestigen?',
  [TransitionEnum.COMPLETE]: 'Weet je zeker dat je deze opdracht wilt markeren als \'wachtend op goedkeuring\'?',
  [TransitionEnum.APPROVE]: 'Weet je zeker dat je deze opdracht wilt goedkeuren?',
  [TransitionEnum.FINALIZE]: 'Weet je zeker dat je deze opdracht wilt afronden?',
  [TransitionEnum.UNASSIGN]: 'Weet je zeker dat je deze opdracht wilt uitroosteren?',
  [TransitionEnum.WITHDRAW]: 'Weet je zeker dat je deze opdracht wilt intrekken?',
  [TransitionEnum.REVERT_APPROVAL]: 'Weet je zeker dat je van deze opdracht de goedkeuring wilt terugdraaien?',
  [TransitionEnum.REVERT_FINALIZE]: 'Weet je zeker dat je van deze opdracht de afronding wilt terugdraaien?',
  //eslint-disable-next-line max-len
  [TransitionEnum.UNDO_CONFIRM_DEFINITIVE]: 'Weet je zeker dat je deze opdracht wilt terugzetten naar \'voorlopig bevestigd\'?',
  [TransitionEnum.CANCEL]: 'Weet je zeker dat je deze opdracht wilt annuleren?',
};
