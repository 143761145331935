import { Component, ElementRef, Input, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],

})
export class LoadingComponent {
  @Input() public set present(present: boolean) {
    if (present) {
      this.show();
      return;
    }

    this.hide();
  }

  public isActive: boolean = false;

  public _loadingCounter: number = 0;

  public identifiers: Map<string, number> = new Map<string, number>();

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) { }

  public show(token?: string): void {
    this._loadingCounter++;

    if (token) {
      if (this.identifiers.has(token)) {
        this.identifiers.set(token, (this.identifiers.get(token) as number) + 1);
      } else {
        this.identifiers.set(token, 1);
      }
    }

    if (!this.isActive) {
      this.renderer.setStyle(this.el.nativeElement, 'display', 'block');
      this.isActive = true;
    }
  }

  public hide(token?: string): void {
    if (token) {
      if (this.identifiers.has(token)) {
        this._loadingCounter = this._loadingCounter - (this.identifiers.get(token) as number);
        this.identifiers.delete(token);
      }
    } else {
      this._loadingCounter--;
    }

    if (this.isActive && this._loadingCounter <= 0) {
      this.renderer.setStyle(this.el.nativeElement, 'display', 'none');
      this.isActive = false;
    }
  }
}

