import { Gender } from '@scheduler-frontend/enums';
import { Expose } from 'class-transformer';
import { CandidateMinimal } from './candidate-minimal.model';

export class Candidate extends CandidateMinimal {

  /**
   * @inheritDoc
   */
  public override readonly className: string = 'Candidate';

  @Expose() public gender!: Gender;

  @Expose() public phoneNumber!: string;

  @Expose() public emailAddress!: string;

  @Expose() public grade!: number;

  @Expose() public isMain!: number;
}
