import { Location as LocationUrl } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { ActivationEnd, Event, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AssignmentModalComponent } from './assignment-modal.component';

export enum AssignmentModalSegmentsUrlValue {
  //eslint-disable-next-line @typescript-eslint/naming-convention
  algemeen = 'OVERVIEW',
  //eslint-disable-next-line @typescript-eslint/naming-convention
  uitbetaling = 'PAYOUT',
  //eslint-disable-next-line @typescript-eslint/naming-convention
  historie = 'HISTORY',
}

@Injectable({
  providedIn: 'root',
})
export class AssignmentModalRoutingService implements OnDestroy {


  /**
   * Subject with which we can destroy all others hot observers
   */
  private onDestroy$: Subject<void> = new Subject();

  constructor(
    private router: Router,
    private modalController: ModalController,
    private locationUrl: LocationUrl,
  ) {
  }

  public initRouting(): void {
    this.createRouteParamObserver();
  }

  /**
   * @inheritDoc
   */
  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public setRoute(assignmentId: string): void {
    if (!this.locationUrl.path().match(`/assignment/${assignmentId}`)) {
      this.locationUrl.replaceState(LocationUrl.joinWithSlash(
        this.locationUrl.path(),
        `assignment/${assignmentId}`,
      ));
    }
  }

  public removeRoute(): void {
    this.locationUrl.replaceState(this.locationUrl.path().slice(0, this.locationUrl.path().indexOf('assignment')));
  }

  /**
   * Create an observer for the route parameters.
   */
  private createRouteParamObserver(): void {
    this.router.events
      .pipe(
        takeUntil(this.onDestroy$),
        filter<Event, ActivationEnd>((event): event is ActivationEnd => {
          return (event instanceof ActivationEnd);
        }),
        filter(event => {
          return (event.snapshot.url[0]?.path === 'assignment');
        }),
      )
      .subscribe(event => {
        const segments: Record<string, AssignmentModalSegmentsUrlValue> = Object
          .assign(AssignmentModalSegmentsUrlValue);
        this.modalController.create({
          component: AssignmentModalComponent,
          componentProps: {
            assignmentId: event.snapshot.params['id'],

            segment: segments[event.snapshot.params['segment'] as string],
          },
        }).then(modal => {
          modal.present();
        });
      });
  }
}
