import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import {
  ArticleCodeEnumDisplayValues,
  AssignmentStateEnum,
  isState,
  PayoutArticleCodeEnum,
} from '@scheduler-frontend/enums';
import { Assignment } from '@scheduler-frontend/models';
import { PermissionService } from '@techniek-team/permissions';
import { firstEmitFrom, isDefined } from '@techniek-team/rxjs';
import { SentryErrorHandler } from '@techniek-team/sentry-web';
import { ToastService } from '@techniek-team/services';
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  firstValueFrom,
  from,
  Observable,
  ReplaySubject,
  share,
  Subject,
} from 'rxjs';
import { catchError, filter, map, mergeMap, startWith, switchMap, take, takeUntil } from 'rxjs/operators';
import { PayoutApi } from '../../../../api/payout/payout.api';
import { GetPayoutInformationResponse } from '../../../../api/payout/payout.response';
import { AssignmentPermission } from '../../../../core/permission/assignment.permission';
import { AssignmentModalService } from '../assignment-modal.service';
import { AddCompensationLineModalComponent } from './add-compensation-line-modal/add-compensation-line-modal.component';

@Component({
  selector: 'app-payout-details',
  templateUrl: './payout.component.html',
  styleUrls: ['./payout.component.scss'],
})
export class PayoutComponent implements OnInit, OnDestroy {

  protected readonly ArticleCodeEnumDisplayValues: typeof ArticleCodeEnumDisplayValues = ArticleCodeEnumDisplayValues;

  protected readonly PayoutArticleCodeEnum: typeof PayoutArticleCodeEnum = PayoutArticleCodeEnum;

  protected readonly displayedColumns: string[] = [
    'articleCode',
    'description',
    'date',
    'quantity',
    'amount',
    'total',
    'actions',
  ];

  /**
   * A FormControl that represents the checkbox if a payout should be performed.
   */
  protected performPayoutControl: FormControl<boolean> = new FormControl<boolean>(
    { value: false, disabled: true }, { nonNullable: true },
  );

  /**
   * A FormControl that represents the checkbox if automatic travel compensation
   * should be performed.
   */
  public automaticTravelCompensationControl: FormControl<boolean> = new FormControl<boolean>({
    value: false,
    disabled: true,
  }, { nonNullable: true });

  /**
   * An observable containing the payout.
   */
  protected payout$!: Observable<GetPayoutInformationResponse>;

  /**
   * An observable containing all the compensation lines that are added
   * by default and are NOT added as extra.
   */
  protected compensationLines$!: Observable<GetPayoutInformationResponse['compensationLines'][0][]>;

  /**
   * An observable containing all the allowed article codes for new
   * compensation lines.
   */
  protected allowedArticleCodes$!: Observable<Map<string, string>>;

  /**
   * Observable which check if the current user may create compensation lines.
   */
  protected canCreateCompensationLine$!: Observable<boolean>;

  /**
   * Observable which check if the current user may remove compensation lines.
   */
  protected canRemoveCompensationLine$!: Observable<boolean>;

  /**
   * Subject when true the loading indicator of the compensationLine delete is shown.
   */
  protected deleteCompensationLineLoader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * reload payout data
   */
  private reloadPayout$: Subject<void> = new Subject<void>();

  /**
   * A subject used to destroy hot observers.
   */
  private onDestroy$: Subject<void> = new Subject();

  constructor(
    private payoutApi: PayoutApi,
    private toastService: ToastService,
    private modalController: ModalController,
    private sentryErrorHandler: SentryErrorHandler,
    public permissionService: PermissionService,
    public assignmentModalService: AssignmentModalService,
  ) {
  }

  /**
   * @inheritDoc
   */
  public ngOnInit(): void {
    this.payout$ = this.createPayoutsObserver();
    this.compensationLines$ = this.createSortedCompensationLines();
    this.allowedArticleCodes$ = this.createAllowedArticleCodesObserver();
    this.canCreateCompensationLine$ = this.createCanCreateCompensationLineObservable();
    this.canRemoveCompensationLine$ = this.createCanRemoveCompensationLineObservable();
    this.setPerformPayoutControl();

    this.performPayoutControl.valueChanges.subscribe(() => this.onPerformPayoutChange());
    this.automaticTravelCompensationControl.valueChanges.subscribe(() => this.onPerformPayoutChange());
  }

  /**
   * @inheritDoc
   */
  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public getArticleCodeEnumDisplayValues(item: PayoutArticleCodeEnum): string {
    return ArticleCodeEnumDisplayValues[item];
  }

  public async addLine(): Promise<void> {
    const confirmModal: HTMLIonModalElement = await this.modalController.create({
      component: AddCompensationLineModalComponent,
      showBackdrop: true,
      cssClass: ['stack-modal'],
      componentProps: {
        payout: await firstValueFrom(this.payout$),
        assignment: await firstValueFrom(this.assignmentModalService.assignment$),
      },
    });
    await confirmModal.present();
    const { role } = await confirmModal.onWillDismiss();

    if (role === 'confirm') {
      this.assignmentModalService.reloadAssignment();
      this.reloadPayout$.next();
    }
  }

  /**
   * Delete an existing compensation line by calling the API.
   */
  public async deleteLine(compensationLine: string): Promise<void> {
    try {
      this.deleteCompensationLineLoader$.next(true);
      await firstEmitFrom(this.payoutApi.deleteAssignmentCompensationLine(compensationLine));
      this.assignmentModalService.reloadAssignment();
      this.reloadPayout$.next();
    } catch (error) {
      await Promise.all([
        this.toastService.error(this.sentryErrorHandler.extractMessage(
          error,
          'Er is iets fout gegaan bij het verwijderen van deze vergoedingsregel.',
        )),
        this.sentryErrorHandler.captureError(error),
      ]);
    } finally {
      this.deleteCompensationLineLoader$.next(false);
    }
  }

  /**
   * This method gets triggered when the performPayout checkbox is changed
   * by the user and this will update everything accordingly.
   */
  public async onPerformPayoutChange(): Promise<void> {
    const performCompensation: boolean = this.performPayoutControl.value;
    let automaticTravelCompensation: boolean = this.automaticTravelCompensationControl.value;
    if (!performCompensation) {
      this.automaticTravelCompensationControl.setValue(false, { emitEvent: false });
    }
    const assigment: Assignment = await firstEmitFrom(
      this.assignmentModalService.assignment$.pipe(take(1)),
    ) as Assignment;

    try {
      this.performPayoutControl.disable({ emitEvent: false });

      await firstEmitFrom(this.payoutApi.postMustPerformCompensation(
        assigment,
        performCompensation,
        (performCompensation) ? automaticTravelCompensation : false,
      ));

      this.assignmentModalService.reloadAssignment();
    } catch (error) {
      if ((error instanceof HttpErrorResponse && error.status !== 400) || !(error instanceof HttpErrorResponse)) {
        await this.sentryErrorHandler.captureError(error);
      }
      await this.toastService.error(this.sentryErrorHandler.extractMessage(
        error,
        'Er is iets misgegaan bij het aan-/uitzetten van de uitbetaling.',
      ));
    } finally {
      this.performPayoutControl.enable({ emitEvent: false });
    }
  }


  /**
   * Set the value of the performPayoutControl every time the payout changes
   * so the checkbox represents the value of the server.
   */
  private setPerformPayoutControl(): void {
    combineLatest([
      this.payout$.pipe(startWith(null)),
      this.assignmentModalService.assignment$.pipe(take(1)),
    ]).pipe(
      takeUntil(this.onDestroy$),
      switchMap(([payout, assignment]) => Promise.all([
        Promise.resolve(payout),
        Promise.resolve(assignment),
        this.permissionService.isGranted(AssignmentPermission, 'EDIT_PAYOUT_CHECKBOX', assignment),
        this.permissionService.isGranted(AssignmentPermission, 'EDIT_MANUAL_TRAVELING_COMPENSATION', assignment),
      ])),
    ).subscribe(([payout, assignment, allowPayout, allowTravelingCompensation]) => {
      this.performPayoutControl.setValue(payout?.general.needsCompensation ?? false, { emitEvent: false });
      this.automaticTravelCompensationControl.setValue(
        payout?.general.automaticTravelCompensation ?? false,
        { emitEvent: false },
      );

      this.performPayoutControl.disable({ emitEvent: false });
      this.automaticTravelCompensationControl.disable({ emitEvent: false });

      if (isState(assignment.state, AssignmentStateEnum.APPROVED)) {
        return;
      }

      if (allowPayout) {
        this.performPayoutControl.enable({ emitEvent: false });
      }
      if (allowTravelingCompensation) {
        this.automaticTravelCompensationControl.enable({ emitEvent: false });
      }
    });
  }

  /**
   * Create an observer which retrieves and returns the payout information
   * from the API.
   */
  private createPayoutsObserver(): Observable<GetPayoutInformationResponse> {
    return combineLatest([
      this.assignmentModalService.assignment$,
      this.reloadPayout$.pipe(startWith(undefined as unknown)),
    ]).pipe(
      takeUntil(this.onDestroy$),
      isDefined([0]),
      filter(([assignment]) => !assignment.restrictAssignmentCompensationAccess),
      filter(([assignment]) => assignment.performCompensation),
      mergeMap(([assignment]) => {
        return this.payoutApi
          .getPayoutInformation(assignment).pipe(
            catchError(error => {
              return from(
                Promise.all([
                  this.sentryErrorHandler.captureError(error),
                  this.toastService.error(this.sentryErrorHandler.extractMessage(error)),
                ]).then(),
              ).pipe(switchMap(() => EMPTY));
            }),
          );
      }),
      share({
        connector: () => new ReplaySubject(1),
        resetOnError: false,
        resetOnComplete: false,
        resetOnRefCountZero: false,
      }),
    );
  }

  /**
   * Create an observable containing all the default compensation lines.
   */
  private createSortedCompensationLines(): Observable<GetPayoutInformationResponse['compensationLines'][0][]> {
    return this.payout$.pipe(
      filter((payout) => !!(payout?.compensationLines)),
      map((payout: GetPayoutInformationResponse) => {
        return payout.compensationLines
          .sort((lineA, lineB) => {
            if (lineA.isExtra && lineB.isExtra) {
              return 0;
            }
            return lineA.isExtra ? 1 : -1;
          })
          .sort((lineA, lineB) => {
            const dateA: Date = new Date(lineA.date);
            const dateB: Date = new Date(lineB.date);

            if (dateA < dateB) {
              return -1;
            }
            if (dateA > dateB) {
              return 1;
            }
            return 0;
          });
      }),
    );
  }

  /**
   * Create an observable containing all the valid article codes the user can
   * choose from when adding a new (extra) compensation line.
   */
  private createAllowedArticleCodesObserver(): Observable<Map<PayoutArticleCodeEnum, string>> {
    return this.payout$.pipe(
      switchMap(payout => Promise.all([
        Promise.resolve(payout),
        this.permissionService.isGranted(AssignmentPermission, 'ADD_PREMIUM_ALLOWED', payout),
      ])),
      map(([payout, addPremiumAllowedGranted]) => {
        const mapAllowedArticleCode: Map<PayoutArticleCodeEnum, string> = new Map<PayoutArticleCodeEnum, string>();
        if (payout.compensationLines.find(compensationLine => compensationLine.compensation?.allowExtraExpenses)) {
          mapAllowedArticleCode.set(PayoutArticleCodeEnum.REIMBURSEMENT, 'Reiskosten');
        }
        if (payout.compensationLines.find(compensationLine => compensationLine.compensation?.allowExtraHours)) {
          mapAllowedArticleCode.set(PayoutArticleCodeEnum.REMUNERATION, 'Extra uren');
        }
        if (addPremiumAllowedGranted) {
          mapAllowedArticleCode.set(PayoutArticleCodeEnum.SLOT_PREMIUM, 'Schaarstetoeslag');
        }
        return mapAllowedArticleCode;
      }),
    );
  }

  /**
   * Return an observable which check if the current user may create compensation lines.
   */
  private createCanCreateCompensationLineObservable(): Observable<boolean> {
    return this.assignmentModalService.assignment$.pipe(isDefined(), switchMap((assignment) => {
      return from(this.permissionService.isGranted(
        AssignmentPermission,
        'CREATE_ASSIGNMENT_COMPENSATION_LINE',
        assignment,
      ));
    }));
  }

  /**
   * Return an observable which check if the current user may remove compensation lines.
   */
  private createCanRemoveCompensationLineObservable(): Observable<boolean> {
    return this.assignmentModalService.assignment$.pipe(isDefined(), switchMap((assignment) => {
      return from(this.permissionService.isGranted(
        AssignmentPermission,
        'DELETE_ASSIGNMENT_COMPENSATION_LINE',
        assignment,
      ));
    }));
  }
}
