import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TtPermissionModule } from '@techniek-team/permissions';
import { AsTypeModule } from '../shared/as-type/as-type.module';
import { LoadingModule } from '../shared/loading/loading.module';
import {
  AssignmentTransitionMessagesModalModule,
} from '../shared/modals/assignment-transition-messages-modal/assignment-transition-messages-modal.module';
import { TtFeatureFlagsModule } from '../shared/tt-feature-flags/tt-feature-flags.module';
import { HeaderModule } from './header/header.module';
import { IconsModule } from './icons.module';

@NgModule({
  imports: [
    // Angular core
    CommonModule,
    IonicModule,
    IconsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    // Repo Modules
    LoadingModule,
    TtFeatureFlagsModule,
    TtPermissionModule,
    HeaderModule,
    AsTypeModule,
  ],
  exports: [
    // Angular core
    CommonModule,
    IonicModule,
    IconsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    // Repo Modules
    LoadingModule,
    AsTypeModule,
    TtFeatureFlagsModule,
    AssignmentTransitionMessagesModalModule,
    HeaderModule,
  ],
})
export class CoreModule {
}
