import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocationTypeEnum } from '@scheduler-frontend/enums';
import { environment } from '@scheduler-frontend/environments';
import { LocationModel } from '@scheduler-frontend/models';
import { Collection, Resource } from '@techniek-team/api-platform';
import { denormalize } from '@techniek-team/class-transformer';
import { CacheService } from '@techniek-team/services';
import { hoursToMilliseconds } from 'date-fns';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocationRequest } from './location.request';
import { GetLocationsResponse } from './location.response';

export interface LocationApiInterface {
  getLocations(request: LocationRequest): Observable<Map<string, LocationModel>>;
}

/**
 * @deprecated
 */
export function regionBackwardCompatibleFix(resource: Resource<GetLocationsResponse>): Resource<GetLocationsResponse>;
//eslint-disable-next-line max-len
/**
 * @deprecated
 */
//eslint-disable-next-line max-len
export function regionBackwardCompatibleFix(resource: Collection<GetLocationsResponse>): Collection<GetLocationsResponse>;

/**
 * We are going to remove the Region object from the location endpoint and only return the iri. This is a backward
 * compatible fix which can be removed later @HJ 2023-05-11
 * @deprecated
 */
//eslint-disable-next-line max-len
export function regionBackwardCompatibleFix(resource: Resource<GetLocationsResponse> | Collection<GetLocationsResponse>): Resource<GetLocationsResponse> | Collection<GetLocationsResponse> {
  if ('hydra:member' in resource) {
    return {
      ...resource,
      'hydra:member': resource['hydra:member'].map(item => ({
        ...item,
        province: item.province,
      })),
    } as Collection<GetLocationsResponse>;
  }
  resource = resource as Resource<GetLocationsResponse>;
  return {
    ...resource,
    province: (typeof resource?.province === 'string') ? resource?.province : resource?.province['@id'],
  } as Resource<GetLocationsResponse>;
}

/**
 * Api to retrieve the {@see Location} resource from the scheduler-api
 */
@Injectable({
  providedIn: 'root',
})
export class LocationApi implements LocationApiInterface {

  constructor(
    protected httpClient: HttpClient,
    private cacheService: CacheService,
  ) {}

  /**
   * Returns all location from the scheduler Api.
   *
   * The response is cashed in the {@see CacheService} and is refreshed every day.
   * The list comes sorted ascending on name.
   */
  //eslint-disable-next-line max-lines-per-function
  public getLocations(request?: LocationRequest): Observable<Map<string, LocationModel>> {
    const mergedRequest: Required<LocationRequest> = {
      cache: false,
      includeArchived: false,
      locationTypes: [LocationTypeEnum.HUB, LocationTypeEnum.SCHOOL, LocationTypeEnum.EXTERN],
      ...(request || {}),
    } as Required<LocationRequest>;
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/locations`;

    let params: HttpParams = new HttpParams({
      fromObject: {
        //eslint-disable-next-line @typescript-eslint/naming-convention
        'include_archived': mergedRequest.includeArchived ?? false,
        //eslint-disable-next-line @typescript-eslint/naming-convention
        'location_types[]': mergedRequest.locationTypes,
      },
    });

    if (mergedRequest.pupil) {
      params = params.set('pupil', mergedRequest.pupil);
    }

    const call: Observable<Map<string, LocationModel>> = this.httpClient
      .get<Collection<GetLocationsResponse>>(url, { params: params })
      .pipe(
        map(response => regionBackwardCompatibleFix(response)),
        map(response => denormalize(LocationModel, response)),
        map(response => response.toMap()),
      );

    if (mergedRequest.cache) {
      return this.cacheService.create('LocationModel', call, {
        refreshInterval: hoursToMilliseconds(3),
      });
    }
    return call;
  }

}
