import { AssignmentHasSlotPurposeEnum } from '@scheduler-frontend/enums';
import { AssignmentHasSlotDetailed, AssignmentHasSlotDetailedWithSlot, SlotDetailed } from '@scheduler-frontend/models';
import { BasePermission, InsufficientPermissionsError } from '@techniek-team/permissions';

enum AssignmentHasSlotPermissionSubjects {
  UPDATE = 'UPDATE',
  REMOVE_SLOT_FROM_ASSIGNMENT = 'REMOVE_SLOT_FROM_ASSIGNMENT',
}

/**
 * This Permission can be used with the {@see TtPermissionModule} and is used to
 * check permission about assignmentHasSlots.
 */
export class AssignmentHasSlotPermission extends BasePermission {
  /**
   * @inheritDoc
   */
  public override readonly subjects: typeof AssignmentHasSlotPermissionSubjects = AssignmentHasSlotPermissionSubjects;

  /**
   * @inheritDoc
   */
  public validate<K extends string>(subject: K, data: AssignmentHasSlotDetailed): boolean {
    switch (subject) {
      case AssignmentHasSlotPermissionSubjects.UPDATE:
        return this.update(data as AssignmentHasSlotDetailed);
      case AssignmentHasSlotPermissionSubjects.REMOVE_SLOT_FROM_ASSIGNMENT:
        return this.removeSlotFromAssignment(data as AssignmentHasSlotDetailedWithSlot);
      default:
        throw new InsufficientPermissionsError();
    }
  }

  //noinspection JSMethodCanBeStatic
  /**
   * Checks if the user may update the assignmentHasSlot.
   */
  private update(assignmentHasSlot: AssignmentHasSlotDetailed): boolean {
    if (!assignmentHasSlot) {
      return false;
    }

    return assignmentHasSlot.purpose === AssignmentHasSlotPurposeEnum.SCHEDULING;
  }

  //noinspection JSMethodCanBeStatic
  /**
   * Checks if the user may update the assignmentHasSlot.
   */
  private removeSlotFromAssignment(assignmentHasSlot: AssignmentHasSlotDetailedWithSlot): boolean {
    if (!assignmentHasSlot) {
      return false;
    }
    const slot: SlotDetailed = assignmentHasSlot.slot;

    if (slot.isCombined === false && slot.displayAsCombined === true) {
      return false;
    }

    return assignmentHasSlot.purpose === AssignmentHasSlotPurposeEnum.SCHEDULING;
  }
}
