import { LazyJsonLd as JsonLd } from '@techniek-team/fetch';
import { Expose, Type } from 'class-transformer';

export class CompensationLine extends JsonLd {

  /**
   * Iri
   */
  @Expose() public assignmentCompensation!: string;

  /**
   * Iri
   */
  @Expose() public assignmentHasSlot!: string;

  @Expose() public description!: string;

  @Expose() public quantity!: string;

  @Expose() public amount!: string;

  @Expose() public subtotal!: string;

  @Expose() public articleCode!: string;

  @Expose() public isExtra!: boolean;

  @Type(() => Date)
  @Expose() public date!: Date;
}
