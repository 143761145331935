import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { AssignmentStateEnum, TransitionEnum } from '@scheduler-frontend/enums';
import { Expose, Type } from 'class-transformer';
import { LogRecord, RecordType } from '../log/log-record.model';
import { Person } from '../person/person.model';

/**
 * Standard version of the Executed Assignment transition resource from Scheduler-api.
 */
export class AssignmentTransition extends LogRecord {

  public override readonly icon: IconProp = ['far', 'arrow-from-left'];

  public override readonly iconColor: string = 'secondary';

  public override readonly type: RecordType = RecordType.TRANSITION;

  /**
   * @inheritDoc
   */
  public override readonly className: string = 'AssignmentTransition';

  /**
   * The name of the transition.
   */
  @Expose() public transitionName!: TransitionEnum;

  @Expose() public enteredState!: AssignmentStateEnum;

  @Expose() public previousState?: AssignmentStateEnum;

  @Type(() => Date)
  @Expose() public override initiatedAt!: Date;

  @Type(() => Person)
  @Expose() public override initiatedBy!: Person;

  @Expose() public sortOrder?: number;

  /**
   * @inheritDoc
   */
  public override toString(): string {
    return `${this.transitionName}`;
  }
}
