import { Fetch, Fetched } from '@techniek-team/fetch';
import { FetchObservable } from '@techniek-team/rxjs';
import { Exclude, Expose, Type } from 'class-transformer';
import { Region } from '../region/region.model';
import { Statistic } from '../statistics.model';
import { LocationMinimal } from './location-minimal.model';

/**
 * Standard version of the Location resource from Scheduler-api
 *
 * It's named LocationModel since Location is already a globally defined
 * javascript Object.
 */
export class LocationModel<Lazy = FetchObservable<unknown>> extends LocationMinimal {

  /**
   * @inheritDoc
   */
  public override readonly className: string = 'LocationModel';

  /**
   * States that this location is the main location for the current logged in
   * user.
   */
  @Expose() public isMain: boolean = false;

  /**
   * The region or province where this location resides.
   */
  @Fetch(() => Region)
  @Expose() public province?: Fetched<Lazy, Region>;

  @Type(() => Date)
  @Expose() public archivedAt?: Date;

  @Exclude() public statistics!: Statistic;

  /**
   * Returns the location name if it exists. Otherwise, it tries to return
   * the address. But that property is only available in the Detailed version
   * of the location resource.
   */
  public get getName(): string {
    if (this.name && this.name.replace(/\s/g, '')) {
      return this.name;
    }
    return this.getId();
  }

  /**
   * @inheritDoc
   */
  public override toString(): string {
    return this.getName;
  }
}
