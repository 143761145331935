import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AssignmentStateEnum, isState } from '@scheduler-frontend/enums';
import { isDefined } from '@techniek-team/rxjs';
import { SentryErrorHandler } from '@techniek-team/sentry-web';
import { ToastService } from '@techniek-team/services';
import { BehaviorSubject, combineLatest, from, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, switchMap, takeUntil } from 'rxjs/operators';
import { AssignmentApi } from '../../../../api/assignment/assignment.api';
import { AssignmentModalService } from '../assignment-modal.service';

@Component({
  selector: 'app-edit-assignment-description',
  templateUrl: './edit-assignment-description.component.html',
  styleUrls: ['./edit-assignment-description.component.scss'],
})
export class EditAssignmentDescriptionComponent implements OnInit {

  /**
   * Assignment description form control
   */
  protected descriptionController: FormControl<string | null> = new FormControl<string | null>(null);

  /**
   * Subject when true the loading indicator of the description field is shown.
   */
  protected descriptionLoader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private assignmentApi: AssignmentApi,
    private toastService: ToastService,
    private assignmentModalService: AssignmentModalService,
    private sentryErrorHandler: SentryErrorHandler,
  ) {
  }

  /**
   * @inheritDoc
   */
  public ngOnInit(): void {
    this.setDescriptionFieldValue();
    this.createOnDescriptionChangeObserver();
  }

  /**
   * Set description field's value to the assignments description
   *
   * If the assignment has been finalized we can't update the description
   * anymore
   */
  private setDescriptionFieldValue(): void {
    this.assignmentModalService.assignment$.pipe(
      takeUntil(this.onDestroy$),
      isDefined(),
    ).subscribe(response => {
      if (response && isState(response.state, AssignmentStateEnum.APPROVED)) {
        this.descriptionController.disable({ emitEvent: false });
      }
      this.descriptionController.setValue(response.description ?? null, { emitEvent: false });
    });
  }

  /**
   * Auto save the description after 1 second
   */
  private createOnDescriptionChangeObserver(): void {
    combineLatest([
      this.descriptionController.valueChanges.pipe(distinctUntilChanged()),
      this.assignmentModalService.assignment$,
    ]).pipe(
      takeUntil(this.onDestroy$),
      filter(() => this.descriptionController.valid),
      debounceTime(1000),
      switchMap(([description, assignment]) => {
        this.descriptionLoader$.next(true);
        return this.assignmentApi.editDescription({
          assignment: assignment,
          description: description,
        }).pipe(
          catchError((error) => {
            this.descriptionLoader$.next(false);
            return from(Promise.all([
              this.sentryErrorHandler.captureError(error),
              this.toastService.error(this.sentryErrorHandler.extractMessage(
                error,
                'Opslaan van omschrijving is mislukt!',
              )),
            ]));
          }),
        );
      }),
    ).subscribe(_ => {
      this.descriptionLoader$.next(false);
      return from(this.toastService.create('Omschrijving is bijgewerkt!'));
    });
  }
}
