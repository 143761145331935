import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import 'hammerjs';
import 'reflect-metadata';
import { AppModule } from './app/app.module';
import { environment } from '@scheduler-frontend/environments';

if (environment.production) {
  enableProdMode();
}

//noinspection JSIgnoredPromiseFromCall
platformBrowserDynamic().bootstrapModule(AppModule)
  //eslint-disable-next-line no-console
  .catch(err => console.log(err));
