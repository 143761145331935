import { EagerLoaded, Fetch, Fetched, LazyJsonLd as JsonLd } from '@techniek-team/fetch';
import { FetchObservable } from '@techniek-team/rxjs';
import { Expose, Type } from 'class-transformer';
import { firstValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { ProductType } from '../product-type/product-type.model';
import { BusinessEntity } from './business-entity.model';

/**
 * Standard version of the BusinessService resource from Scheduler-api.
 */
export class BusinessService<Lazy = FetchObservable<unknown>> extends JsonLd<Lazy> {

  /**
   * @inheritDoc
   */
  public override readonly className: string = 'BusinessService';

  /**
   * The name of the service.
   *
   * This is a product(service) our company(s) supplies to customers.
   */
  @Expose() public name!: string;

  @Expose() public hasAssignmentReviews: boolean = false;

  /**
   * The company under which we supply this service.
   */
  @Type(() => BusinessEntity)
  @Expose() public businessEntity!: BusinessEntity;


  @Fetch(() => ProductType)
  @Expose() public productType?: Fetched<Lazy, ProductType>;

  //eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    super();
  }

  public override async fetchAll(): Promise<BusinessService<EagerLoaded>> {
    let productType: FetchObservable<ProductType> | ProductType | undefined = this.productType;

    if (productType instanceof FetchObservable) {
      productType = await firstValueFrom(productType.pipe(take(1)));
    }

    const businessService: BusinessService<EagerLoaded> = this as BusinessService<EagerLoaded>;
    businessService.productType = productType as ProductType;
    return businessService;
  }

  /**
   * @inheritDoc
   */
  public override toString(): string {
    return `${this.name} (${this.businessEntity.toString()})`;
  }
}
