import type { Duration } from 'date-fns';

//eslint-disable-next-line max-len
const ISO_DURATION_REGEX: RegExp = /P(?:([\d]+\.?[\d]*|\.[\d]+)Y)?(?:([\d]+\.?[\d]*|\.[\d]+)M)?(?:([\d]+\.?[\d]*|\.[\d]+)W)?(?:([\d]+\.?[\d]*|\.[\d]+)D)?(?:T(?:([\d]+\.?[\d]*|\.[\d]+)H)?(?:([\d]+\.?[\d]*|\.[\d]+)M)?(?:([\d]+\.?[\d]*|\.[\d]+)S)?)?$/;

/**
 * @name parseISODuration
 * @category Common Helpers
 * @summary Parse ISO Duration string
 *
 * @description
 * Parse the given string in ISO 8601 format and return Duration object.
 *
 * Function accepts duration format of ISO 8601.
 * ISO 8601: http://en.wikipedia.org/wiki/ISO_8601
 * Specifically: https://en.wikipedia.org/wiki/ISO_8601#Durations
 *
 * If the argument is not a valid ISO Duration it will throw Invalid format error.
 *
 * @param argument - ISO Duration string
 * @returns duration object
 *
 * @throws {RangeError} Duration in ISO format cannot be `Invalid format`
 *
 * @example
 * // Convert string 'P3Y6M4DT12H30M5S' to duration:
 * const result = parseISODuration('P3Y6M4DT12H30M5S')
 * //=>
 * {
 *   years: 3,
 *   months: 6,
 *   weeks: 0,
 *   days: 4,
 *   hours: 12,
 *   minutes: 30,
 *   seconds: 5,
 * }
 */

export function parseISODuration(argument: string): Duration {
  const parsedArgument: string = argument.replace(/,/g, '.');
  // Decimal fraction may be specified with either a comma or a full stop

  const match: RegExpMatchArray | null = parsedArgument.match(ISO_DURATION_REGEX);

  if (!match) {
    throw new RangeError('Invalid format');
  }

  const [
    ,
    years = 0,
    months = 0,
    weeks = 0,
    days = 0,
    hours = 0,
    minutes = 0,
    seconds = 0,
  ] = match;

  return Object.entries({
    years: years,
    months: months,
    weeks: weeks,
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds,
  }).reduce((prev, [key, value]) => {
    return { ...prev, [key]: Number(value) };
  }, {});
}
