import { Component, OnDestroy, OnInit } from '@angular/core';
import { AssignmentHasSlotDetailedWithSlot as AssignmentHasSlotDetailed } from '@scheduler-frontend/models';
import { EagerLoaded } from '@techniek-team/fetch';
import { PermissionService } from '@techniek-team/permissions';
import { isDefined } from '@techniek-team/rxjs';
import { compareAsc } from 'date-fns';
import { from, Observable, ReplaySubject, share, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { AssignmentApi } from '../../../../api/assignment/assignment.api';
import { AssignmentPermission } from '../../../../core/permission/assignment.permission';
import { AssignmentModalService } from '../assignment-modal.service';

@Component({
  selector: 'app-assignment-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss'],
})
export class GeneralComponent implements OnInit, OnDestroy {

  public readonly displayedColumns: string[] = [
    'location',
    'date',
    'time',
    'break',
    'actualTime',
    'role',
  ];

  /**
   * Sorted list of assignmentHasSlots
   */
  protected assignmentHasSlots$!: Observable<AssignmentHasSlotDetailed<EagerLoaded>[]>;

  /**
   * Check if there is at least one slot with allowBreakTime = true
   */
  protected showAllowBreakTimeColumn$!: Observable<boolean>;

  /**
   * Check if there is at least one slot of which the saldoCanBeDisabled = true
   */
  protected canDisableWritingSaldo$!: Observable<boolean>;

  protected showSelfAssign$!: Observable<boolean>;

  private onDestroy$: Subject<void> = new Subject();

  constructor(
    private assignmentApi: AssignmentApi,
    private permissionService: PermissionService,
    public assignmentModalService: AssignmentModalService,
  ) {
  }

  /**
   * @inheritDoc
   */
  public ngOnInit(): void {
    this.assignmentHasSlots$ = this.createAssignmentHasSlotsObserver();
    this.showAllowBreakTimeColumn$ = this.createShowAllowBreakTimeColumnObserver();
    this.canDisableWritingSaldo$ = this.createCanDisableWritingSaldoObserver();
    this.showSelfAssign$ = this.createShowSelfAssignFormObserver();
  }

  /**
   * @inheritDoc
   */
  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  /**
   * Sort the assignment has slots by their start time
   */
  private createAssignmentHasSlotsObserver(): Observable<AssignmentHasSlotDetailed<EagerLoaded>[]> {
    return this.assignmentModalService.assignment$.pipe(
      takeUntil(this.onDestroy$),
      map(assignment => {
        return assignment.assignmentHasSlots.sort((a, b) => compareAsc(
          a.slot.timePeriod.start,
          b.slot.timePeriod.start,
        ));
      }),
    );
  }

  /**
   * Check if there is at least one slot of which the saldoCanBeDisabled = true
   */
  public createCanDisableWritingSaldoObserver(): Observable<boolean> {
    return this.assignmentHasSlots$.pipe(
      map(slots => {
        return slots.reduce(
          (atLeastOneCanBeDisabled: boolean, hasSlot: AssignmentHasSlotDetailed<EagerLoaded>) => {
            const slotSaldoCanBeDisabled: boolean = (
              !!(hasSlot.slot.role.businessService?.productType?.hasBalance)
            );

            return atLeastOneCanBeDisabled || slotSaldoCanBeDisabled;
          },
          false, // initial value of atLeastOneCanBeDisabled
        );
      }),
      share({
        connector: () => new ReplaySubject(1),
        resetOnError: false,
        resetOnComplete: false,
        resetOnRefCountZero: false,
      }),
    );
  }

  /**
   * Check if there is at least one slot with allowBreakTime = true
   */
  public createShowAllowBreakTimeColumnObserver(): Observable<boolean> {
    return this.assignmentHasSlots$.pipe(
      map(slots => slots.reduce(
        (atLeaseOneHasBreakTime: boolean, hasSlot: AssignmentHasSlotDetailed<EagerLoaded>) =>
          atLeaseOneHasBreakTime || hasSlot.slot.role.allowBreakTime,
        false, // initial value of atLeaseOneHasBreakTime
      )),
      share({
        connector: () => new ReplaySubject(1),
        resetOnError: false,
        resetOnComplete: false,
        resetOnRefCountZero: false,
      }),
    );
  }

  /**
   * Determines whether to show the selfAssignForm based on
   * assignment.allowUpdatingSelfAssignFields and USER_ROLE.
   */
  public createShowSelfAssignFormObserver(): Observable<boolean> {
    return this.assignmentModalService.assignment$.pipe(isDefined(), switchMap(assignment => {
      return from(this.permissionService.isGranted(
        AssignmentPermission,
        'READ_SELF_ASSIGNABLE',
        assignment,
      ));
    }));
  }
}
