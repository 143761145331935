import { EagerLoaded } from '@techniek-team/fetch';
import { Role } from '../../role/role.model';
import { SubjectModel } from '../../subject/subject.model';
import { CandidateSkill } from '../skill/candidate-skilll.model';
import { SkillsPerLevelSet } from './skill-per-subject.model';

/**
 * The model contains a list of candidate skill which all shared the same
 * {@see Role}
 */
export class SkillsPerRole {
  public skills: CandidateSkill<EagerLoaded>[] = [];

  public skillsPerSubject: Map<string, SkillsPerLevelSet> = new Map<string, SkillsPerLevelSet>();

  public skillsPerSubjectAndLevel: SkillsPerLevelSet[] = [];

  constructor(
    public role: Role,
    skills: CandidateSkill<EagerLoaded>[],
  ) {
    for (let skill of skills) {
      this.addSkill(skill);
    }
  }

  public addSkill(skill: CandidateSkill): void {
    this.skills.push(skill);
    this.addSkillToSubjectGroup(skill);
  }

  private addSkillToSubjectGroup(skill: CandidateSkill<EagerLoaded>): void {
    const subjectMap: Map<string, SkillsPerLevelSet> = this.skillsPerSubject;

    const subject: SubjectModel | undefined = skill.skill.subject;
    const identifier: string = (subject) ? subject.getIri() ?? 'noSubject' : 'noSubject';
    if (!subjectMap.has(identifier)) {
      subjectMap.set(identifier, new SkillsPerLevelSet([skill]));
    } else {
      subjectMap.get(identifier)?.addSkill(skill);
    }
    this.createListGroupBySubjectAndLevel();
  }

  private createListGroupBySubjectAndLevel(): void {
    const subjectMap: Map<string, SkillsPerLevelSet> = this.cloneSkillBySubject();

    for (let [key, item] of subjectMap.entries()) {
      for (let [innerKey, innerItem] of subjectMap.entries()) {
        if (key === innerKey) {
          continue;
        }
        if (item.levelSetEquals(innerItem.levels)) {
          item.addSkill(innerItem.skills);
          subjectMap.delete(innerKey);
        }
      }
    }
    this.skillsPerSubjectAndLevel = this.sortBySizeOfSubjectAndLevel([...subjectMap.values()]);
  }

  private cloneSkillBySubject(): Map<string, SkillsPerLevelSet> {
    return new Map<string, SkillsPerLevelSet>(
      [...this.skillsPerSubject.entries()]
        .map(([key, value]) => {
          return [key, new SkillsPerLevelSet(value.skills)];
        }),
    );
  }

  private sortBySizeOfSubjectAndLevel(distinctByLevel: SkillsPerLevelSet[]): SkillsPerLevelSet[] {
    distinctByLevel.sort((a, b) => {
      const compare: number = b.subjects.size - a.subjects.size;
      if (compare !== 0) {
        return compare;
      }
      return a.levels.size - b.levels.size;
    });
    return distinctByLevel;
  }
}
