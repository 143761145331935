import { Person } from '../person/person.model';

/**
 * The Minimal version of the Candidate resource from Scheduler-api
 */
export class CandidateMinimal extends Person {

  /**
   * @inheritDoc
   */
  public override readonly className: string = 'CandidateMinimal';

  /**
   * @inheritDoc
   */
  public override toString(): string {
    return this.fullName;
  }
}
