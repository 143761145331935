import { AssignmentTransitionErrorEnum, AssignmentTransitionSuccessEnum } from '@scheduler-frontend/enums';
import { ApplyTransitionResponse } from '../../../api/assignment/assignment.response';

export function containsWarningOrError(transition: ApplyTransitionResponse): boolean {
  return !!(transition.messages.find(message => {
    return (Object.values(AssignmentTransitionErrorEnum) as string[]).includes(message.messageType);
  }));
}

export function containsInfoOrSuccess(transition: ApplyTransitionResponse): boolean {
  return !!(transition.messages.find(message => {
    return (Object.values(AssignmentTransitionSuccessEnum) as string[]).includes(message.messageType);
  }));
}
