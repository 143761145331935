import { EagerLoaded } from '@techniek-team/fetch';
import { isFuture, isPast } from 'date-fns';
import { BusinessService } from '../../business/business-service.model';
import { Role } from '../../role/role.model';
import { CandidateSkill } from '../skill/candidate-skilll.model';
import { SkillsPerRole } from './skills-per-role.model';

/**
 * The model contains a list of candidate skill which all shared the same
 * {@see BusinessService}
 */
export class SkillsPerService {

  public past: Map<string, SkillsPerRole> = new Map<string, SkillsPerRole>();

  public present: Map<string, SkillsPerRole> = new Map<string, SkillsPerRole>();

  public future: Map<string, SkillsPerRole> = new Map<string, SkillsPerRole>();

  public skills: CandidateSkill<EagerLoaded>[] = [];

  constructor(
    public businessService: BusinessService,
    skills: CandidateSkill<EagerLoaded>[],
  ) {
    for (let skill of skills) {
      this.addSkill(skill);
    }
  }

  public addSkill(skill: CandidateSkill): void {
    this.skills.push(skill);
    if (isPast(skill.validityRange.end)) {
      this.addSkillToRoleGroup(skill, this.past);
    }
    if (!isPast(skill.validityRange.end) && !isFuture(skill.validityRange.start)) {
      this.addSkillToRoleGroup(skill, this.present);
    }
    if (isFuture(skill.validityRange.start)) {
      this.addSkillToRoleGroup(skill, this.present);
    }
  }

  //noinspection JSMethodCanBeStatic
  private addSkillToRoleGroup(skill: CandidateSkill<EagerLoaded>, roleMap: Map<string, SkillsPerRole>): void {
    const role: Role = skill.skill.role;
    if (!roleMap.has(role.getIri() as string)) {
      roleMap.set(role.getIri() as string, new SkillsPerRole(
        role as Role,
        [skill],
      ));
    } else {
      roleMap.get(role.getIri() as string)?.addSkill(skill);
    }
  }
}
