import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors } from '@angular/forms';

export function getNestedErrors(control: AbstractControl): ValidationErrors | null {
  if (control instanceof FormControl) {
    return control.errors ?? null;
  }

  if (control instanceof FormGroup || control instanceof FormArray) {
    const groupErrors: ValidationErrors | null = control.errors;
    // Form group can contain errors itself, in that case add'em
    const formErrors: ValidationErrors | null = groupErrors ? groupErrors : {};
    Object.keys(control.controls).forEach(key => {
      // Recursive call of the FormGroup fields
      const error: ValidationErrors | null = getNestedErrors(control.get(key) as AbstractControl);
      if (error !== null) {
        // Only add error if not null
        formErrors[key] = error;
      }
    });
    // Return FormGroup errors or null
    return Object.keys(formErrors).length > 0 ? formErrors : null;
  }

  return null;
}
