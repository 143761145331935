import { AssignmentStateDisplayValues, AssignmentStateEnum, TransitionEnum } from '@scheduler-frontend/enums';
import { AssignmentTransition } from '../assignment/assignment-transition.model';

export class TransitionLogRecord extends AssignmentTransition {

  public get from(): AssignmentStateEnum {
    return this.previousState as AssignmentStateEnum;
  }

  public get to(): AssignmentStateEnum {
    return this.enteredState;
  }

  public get name(): TransitionEnum {
    return this.transitionName;
  }

  public override toString(): string {
    return `Status is veranderd van ${AssignmentStateDisplayValues[this.from]} `
      + `maar ${AssignmentStateDisplayValues[this.to]}`;
  }
}
