import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Release } from '@scheduler-frontend/models';
import { denormalize } from '@techniek-team/class-transformer';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { GET_RELEASES_MOCK } from './release.response.mock';

export interface ReleaseApiInterface {
  getReleases(): Observable<Release[]>;
}

@Injectable({
  providedIn: 'root',
})
export class ReleaseApi implements ReleaseApiInterface {

  constructor(protected httpClient: HttpClient) {
  }

  public getReleases(): Observable<Release[]> {
    return of(GET_RELEASES_MOCK).pipe(
      map(response => denormalize(Release, response)),
    );
    return this.httpClient.get<Release[]>('releases').pipe(
      map(response => denormalize(Release, response)),
    );
  }
}
