<ng-container *ngIf="(assignmentModalService.assignment$ | async)?.canEnableCompensation; else cannotEnableCompensation">
  <app-general-information [payout]="(payout$ | async)"
                           [businessService]="(assignmentModalService.assignment$ | async)?.businessService?.name">
  </app-general-information>
  <div class="payment-checkbox">
      <ion-toggle labelPlacement="start"
                  color="secondary"
                  [formControl]="performPayoutControl">
        Uitbetalen
      </ion-toggle>

      <ion-label></ion-label>
      <ion-toggle labelPlacement="start"
                  color="secondary"
                  [formControl]="automaticTravelCompensationControl">
        Automatische reiskostenvergoeding
      </ion-toggle>
  </div>
  <div *ngIf="performPayoutControl && performPayoutControl.value" class="compensations">
    <table mat-table
           class="ion-color-primary"
           *ngIf="(compensationLines$ | async) as compensationLines"
           [dataSource]="compensationLines">
      <ng-container matColumnDef="articleCode">
        <th mat-header-cell *matHeaderCellDef>Artikelcode</th>
        <td mat-cell *matCellDef="let element">
          <span>{{ (element.isExtra) ? "Extra: " : "" }}</span>
          {{ getArticleCodeEnumDisplayValues(element.articleCode) }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>Omschrijving</th>
        <td mat-cell *matCellDef="let element">
          {{element.description}}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <ng-container matColumnDef="date">
        <th mat-header-cell
            *matHeaderCellDef
            class="fit-content">Datum
        </th>
        <td mat-cell
            class="fit-content"
            *matCellDef="let element">
          {{ element.date | date:'dd-MM-yyyy' }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <ng-container matColumnDef="quantity">
        <th mat-header-cell
            class="fit-content"
            *matHeaderCellDef>Aantal
        </th>
        <td mat-cell
            class="fit-content"
            *matCellDef="let element">
          {{ element.quantity | number:'1.0-2' }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef>Bedrag</th>
        <td mat-cell *matCellDef="let element">
          {{ element.amount | currency: 'EUR':'symbol':'1.2-4' }}
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <ng-container matColumnDef="total">
        <th mat-header-cell *matHeaderCellDef>Subtotaal</th>
        <td mat-cell *matCellDef="let element">
          {{ element.subtotal | currency: 'EUR':'symbol':'1.2-4' }}
        </td>
        <td mat-footer-cell *matFooterCellDef>
          {{ (payout$ | async)?.general?.total | currency: 'EUR':'symbol':'1.2-4' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <ion-button *ngIf="element.isExtra || element.articleCode === PayoutArticleCodeEnum.SLOT_PREMIUM"
                  [disabled]="(canRemoveCompensationLine$ | async) === false"
                  (click)="deleteLine(element.id)">
            <ion-icon slot="icon-only"
                      *ngIf="(deleteCompensationLineLoader$ | async) === false"
                      name="trash-outline"></ion-icon>
            <ion-spinner *ngIf="(deleteCompensationLineLoader$ | async)"></ion-spinner>
          </ion-button>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row
          *matRowDef="let row; let i = index; columns: displayedColumns;"
          [class.extra]="row.isExtra"></tr>
      <tr mat-footer-row
          *matFooterRowDef="displayedColumns"
          [class.ion-hide]="(payout$ | async) === undefined"></tr>
    </table>

    <div *ngIf="(assignmentModalService.assignment$ | async)?.restrictAssignmentCompensationAccess"
         class="access-denied">
      <h2>
        Toegang geweigerd
        <fa-icon [icon]="['far', 'eye-slash']">
          visibility_off
        </fa-icon>
      </h2>

      <p>
        De uitbetalingsdetails van deze opdracht mogen niet door jou worden ingezien.<br>
        Deze opdracht heeft namelijk een product waarbij deze restrictie is ingesteld.
      </p>
    </div>
    <ion-button (click)="addLine()"
                *ngIf="assignmentModalService.assignment$ as assignment"
                [disabled]="((allowedArticleCodes$ | async) !== null && !((allowedArticleCodes$ | async)?.size ?? 0 > 0)) || (canCreateCompensationLine$ | async) === false"
                fill="clear"
                class="ion-float-right"
                size="small">
      Toevoegen
    </ion-button>
  </div>
</ng-container>

<ng-template #cannotEnableCompensation>
  <div style="width: 80%">
    <p>
      De vergoeding voor deze opdracht is nog niet bekend.<br>
      Het is nog niet duidelijk of deze begeleider wel of geen vergoeding ontvangt voor deze opdracht,<br>
      en zoja wat de vergoeding dan moet zijn.
    </p>
    <p *ngIf="(assignmentModalService.assignment$ | async) as assignment">
      Neem contact op met <a target="_blank" href="https://helpdesk.mijnskoleo.nl/">applicatiebeheer</a>
      en informeer of in Perza vergoedingsregels zijn aangemaakt <br> voor {{ assignment.candidate?.fullName }}
      voor de rol {{ assignment.assignmentHasSlots[0].slot.role.toString() }} in dienst {{ assignment.assignmentHasSlots[0].slot.role.businessService?.name }}.
    </p>
  </div>
</ng-template>
