import { Component, Input, OnInit } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ModalController } from '@ionic/angular';
import {
  AssignmentStateDisplayValues,
  AssignmentStateExplanation,
  AssignmentTransitionMessageEnum,
} from '@scheduler-frontend/enums';
import { ApplyTransitionResponse } from '../../../api/assignment/assignment.response';

type Transition = ApplyTransitionResponse;
type TransitionMessage = Transition['messages'][0];

@Component({
  selector: 'app-assignment-transition-messages-modal',
  templateUrl: './assignment-transition-messages-modal.component.html',
  styleUrls: ['./assignment-transition-messages-modal.component.scss'],
})
export class AssignmentTransitionMessagesModalComponent implements OnInit {

  /**
   * Template Injection
   */
  public readonly AssignmentStateDisplayValues: typeof AssignmentStateDisplayValues = AssignmentStateDisplayValues;

  public readonly AssignmentStateExplanation: typeof AssignmentStateExplanation = AssignmentStateExplanation;

  @Input() public transition!: Transition;

  constructor(private modalController: ModalController) { }

  public ngOnInit(): void {
    const order: AssignmentTransitionMessageEnum[] = [
      AssignmentTransitionMessageEnum.ERROR,
      AssignmentTransitionMessageEnum.WARNING,
      AssignmentTransitionMessageEnum.INFO,
      AssignmentTransitionMessageEnum.SUCCESS,
    ];

    this.transition.messages.sort((a: TransitionMessage, b: TransitionMessage) => {
      return order.indexOf(a.messageType) - order.indexOf(b.messageType);
    });
  }

  public close(): void {
    this.modalController.dismiss();
  }
  //
  //public keyAssignmentStateExplanation(item: AssignmentStateEnum): AssignmentStateExplanation {
  //  if (item in AssignmentStateExplanation) {
  //    return AssignmentStateExplanation[item];
  //  }
  //}

  /**
   * Get the correct icon name by assignment transition message type.
   */
  public getIcon(type: AssignmentTransitionMessageEnum | null | undefined): IconProp | undefined {
    switch (type) {
      case AssignmentTransitionMessageEnum.ERROR:
        return ['far', 'circle-exclamation'];
      case AssignmentTransitionMessageEnum.WARNING:
        return ['far', 'triangle-exclamation'];
      case AssignmentTransitionMessageEnum.INFO:
        return ['far', 'circle-info'];
      case AssignmentTransitionMessageEnum.SUCCESS:
        return ['far', 'circle-check'];
    }
    return undefined;
  }
}
