import { AssignmentStateEnum, ContractTypeEnum } from '@scheduler-frontend/enums';
import { LazyJsonLd as JsonLd } from '@techniek-team/fetch';
import { Expose, Type } from 'class-transformer';
import { CandidateMinimal } from '../../candidate/candidate-minimal.model';
import { Schedule } from '../../schedule/schedule.model';
import { CompensationLine } from './compensation-lines.model';
import { Compensation } from './compensation.model';
import {
  PayoutWaitingForApprovalAssignmentHasSlot as AssignmentHasSlot,
} from './payout-waiting-for-approval-assignment-has-slot.model';

export class PayoutWaitingForApproval extends JsonLd {

  protected readonly AssignmentHasSlot: typeof AssignmentHasSlot = AssignmentHasSlot;

  @Type(() => CandidateMinimal)
  @Expose() public candidate!: CandidateMinimal;

  @Expose() public name?: string;

  @Expose() public description?: string;

  @Expose() public contractType?: ContractTypeEnum;

  @Expose() public state!: AssignmentStateEnum;

  @Type(() => AssignmentHasSlot)
  @Expose() public assignmentHasSlots!: AssignmentHasSlot[];

  @Type(() => Schedule)
  @Expose() public schedule!: Schedule;

  @Type(() => Compensation)
  @Expose() public compensation?: Compensation;

  public getSlotCompensationLines(id: string): CompensationLine[] {
    if (!this.compensation || !this.compensation.assignmentCompensationLines) {
      return [];
    }

    return this.compensation.assignmentCompensationLines.filter(line => {
      return line.assignmentHasSlot === id;
    });
  }

  public getExtraCompensationLines(): CompensationLine[] {
    if (!this.compensation || !this.compensation.assignmentCompensationLines) {
      return [];
    }

    return this.compensation.assignmentCompensationLines?.filter(line => !line.assignmentHasSlot) ?? [];
  }

}
