import { AfterViewInit, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { IsActiveMatchOptions, RouterLinkActive } from '@angular/router';
import { FaConfig, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { IconDefinition, IconProp } from '@fortawesome/fontawesome-svg-core';
import { IonPopover } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'tt-menu-item',
  templateUrl: './tt-menu-item.component.html',
  styleUrls: ['./tt-menu-item.component.scss'],
})
export class TtMenuItemComponent implements AfterViewInit {

  @ViewChild('routerLinkActive') public routerLinkActive: RouterLinkActive | undefined;

  @ViewChild('popover') public popover: IonPopover | undefined;

  /**
   * Commands to pass to {@link Router#createUrlTree Router#createUrlTree}.
   *   - **array**: commands to pass to {@link Router#createUrlTree Router#createUrlTree}.
   *   - **string**: shorthand for array of commands with just the string, i.e. `['/route']`
   *   - **null|undefined**: effectively disables the `routerLink`
   * @see {@link Router#createUrlTree Router#createUrlTree}
   * @see RouterLink.routerLink
   */
  @Input() public routerLink: unknown[] | string | null | undefined;

  /**
   * Options to configure how to determine if the router link is active.
   *
   * These options are passed to the `Router.isActive()` function.
   *
   * @see Router.isActive
   * @see RouterLinkActive.routerLinkActiveOptions
   */
  @Input() public routerLinkActiveOptions: { exact: boolean } | IsActiveMatchOptions = { exact: false };

  /**
   * TemplateRef of the subMenu if any
   *
   * @example
   * ```typescript
   * <tt-menu [header]="'Lyceo Styling'">
   *     <tt-menu-item [routerLink]="['/people']"
   *                   [icon]="'people'"
   *                   [submenu]="people"
   *                   [title]="'People'"></tt-menu-item>
   *     <ng-template #people>
   *         <tt-sub-menu-item>Rare mensen</tt-sub-menu-item>
   *         <tt-sub-menu-item>Leuke Mensen</tt-sub-menu-item>
   *     </ng-template>
   * </tt-menu>
   * ```
   */
  @Input() public submenu: TemplateRef<unknown> | undefined;

  /**
   * The tile of the menu item.
   *
   * The title is shown in both the menu(if expanded) and the submenu(if available)
   * Note that it's only shown in the menu when the <tt-menu-item> element has no inner
   * content, otherwise it shows that instead.
   */
  @Input() public title: string | undefined;

  /**
   * If true the popover is completely disable for the menu item.
   */
  @Input() public disablePopover: boolean = false;

  /**
   * Emit when the menu item is active.
   */
  @Output() public readonly isActiveChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private isActive: boolean = false;

  /**
   * Input to set the icon used in the menu item. This can either be a font-awesome
   * {@see IconProp} or an ion-icon icon name (string) or an url to svg (string)
   *
   * Note: This property is only used when the <tt-menu-item> element has no inner
   * content, otherwise it shows that instead.
   */
  @Input()
  public set icon(icon: string | IconProp | undefined) {
    if (!icon) {
      return;
    }

    this.isFontAwesomeIcon = this.lookupIsFontAwesomeIcon(icon as IconProp);
    this.iconUrl = icon;
  }

  @Input()
  public set expanded(expanded: boolean) {
    this.isExpanded.next(expanded);
  }

  /**
   * If true the menu is enlarged, and it shows the text value of the navigation links.
   */
  protected isExpanded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  protected isFontAwesomeIcon: boolean = false;

  /**
   * Input to set the icon used in the menu item. This can either be a font-awesome
   * {@see IconProp} or an ion-icon icon name (string) or an url to svg (string)
   *
   * Note: This property is only used when the <tt-menu-item> element has no inner
   * content, otherwise it shows that instead.
   */
  protected iconUrl: string | IconProp | undefined;

  /**
   * If true the popover is open.
   */
  protected popoverOpen: boolean = false;

  constructor(
    private iconLibrary: FaIconLibrary,
    private fontAwesomeConfig: FaConfig,
  ) {
  }

  /**
   * @inheritDoc
   */
  public ngAfterViewInit(): void {
    if (this.routerLinkActive) {
      this.isActive = this.routerLinkActive.isActive;
      this.routerLinkActive.isActiveChange.subscribe(isActive => {
        this.isActiveChange.emit(isActive);
        this.isActive = isActive;
      });
    }
  }

  /**
   * Trigger on MouseEnter and opens the popover.
   *
   * The Popover is only opened when the menu isn't expanded and there isn't a submenu
   */
  protected presentPopover(event: Event): void {
    if (this.isExpanded.getValue() || this.disablePopover || this.isActive || !this.popover) {
      return;
    }
    this.popover.event = event;
    this.popoverOpen = true;
  }

  /**
   * Trigger on MouseLeave and closes the popover.
   *
   * The MouseLeave is set on multiple elements and therefor needs to check if
   * it actually needs to close the popover.
   */
  protected leavePopover(event: Event): void {
    if (event instanceof MouseEvent) {
      if ((event.relatedTarget as Element)?.closest('ion-content')?.classList?.contains('menu-item-popover')) {
        return;
      }
    }
    this.popoverOpen = false;
  }

  public isFontAwesome(icon: IconProp | string | undefined): IconProp | undefined {
    return (this.isFontAwesomeIcon) ? icon as IconProp : undefined;
  }

  /**
   * Method determines if the given icon is a Font Awesome icon.
   */
  private lookupIsFontAwesomeIcon(iconSpec: IconDefinition | IconProp): boolean {
    let lookup: IconProp;
    if (Array.isArray(iconSpec) && (iconSpec as string[]).length === 2) {
      lookup = { prefix: iconSpec[0], iconName: iconSpec[1] };
    } else if (typeof iconSpec === 'string') {
      lookup = { prefix: this.fontAwesomeConfig.defaultPrefix, iconName: iconSpec };
    } else {
      return false;
    }

    return !!(this.iconLibrary.getIconDefinition(lookup.prefix, lookup.iconName));
  }
}
