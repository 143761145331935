import { LevelEnum } from '@scheduler-frontend/enums';
import { EagerLoaded, Fetch, Fetched, LazyJsonLd as JsonLd } from '@techniek-team/fetch';
import { FetchObservable } from '@techniek-team/rxjs';
import { Expose } from 'class-transformer';
import { firstValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { Role } from '../role/role.model';
import { SubjectModel } from '../subject/subject.model';

export class Skill<Lazy = FetchObservable<unknown>> extends JsonLd {

  /**
   * @inheritDoc
   */
  public override readonly className: string = 'Skill';

  @Expose() public level!: LevelEnum;

  @Fetch(() => Role)
  @Expose() public role!: Fetched<Lazy, Role>;

  @Fetch(() => SubjectModel)
  @Expose() public subject?: Fetched<Lazy, SubjectModel>;

  public override async fetchAll(): Promise<Skill<EagerLoaded>> {
    let role: FetchObservable<Role> | Role = this.role;

    if (role instanceof FetchObservable) {
      role = await firstValueFrom(role.pipe(take(1)));
    }

    let subject: FetchObservable<SubjectModel> | SubjectModel | undefined = this.subject;

    if (subject instanceof FetchObservable) {
      subject = await firstValueFrom(subject.pipe(take(1)));
    }

    const skill: Skill<EagerLoaded> = this as Skill<EagerLoaded>;
    skill.role = await (role as Role).fetchAll();
    skill.subject = subject as SubjectModel;
    return skill;
  }
}
