import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@scheduler-frontend/environments';
import { Role, RoleDetailed } from '@scheduler-frontend/models';
import { Collection, Resource } from '@techniek-team/api-platform';
import { denormalize, Hydra } from '@techniek-team/class-transformer';
import { CacheService } from '@techniek-team/services';
import { hoursToMilliseconds } from 'date-fns';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GetRolesResponse } from './role.response';

export interface RoleApiInterface {
  getRole(role: string | Role): Observable<RoleDetailed>;
  getRoles(): Observable<Hydra<Role>>;
}

@Injectable({
  providedIn: 'root',
})
export class RoleApi implements RoleApiInterface {

  constructor(
    private httpClient: HttpClient,
    private cacheService: CacheService,
  ) { }

  public getRole(role: string | Role): Observable<RoleDetailed> {
    if (role instanceof Role) {
      role = role.getId();
    }
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/roles/${role}`;

    //todo retrieve this from the cache as well.
    return this.httpClient.get<Resource<GetRolesResponse>>(url).pipe(
      map(response => denormalize(RoleDetailed, response)),
    );
  }

  public getRoles(): Observable<Hydra<Role>> {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/roles`;
    const call: Observable<Hydra<Role>> = this.httpClient.get<Collection<GetRolesResponse>>(url).pipe(
      map(response => denormalize(Role, response)),
    );

    return this.cacheService.create<Hydra<Role>>(
      'Role',
      call,
      { refreshInterval: hoursToMilliseconds(24) },
    );
  }
}
