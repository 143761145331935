import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AssignmentDetail } from '@scheduler-frontend/models';
import { EagerLoaded } from '@techniek-team/fetch';
import { PermissionService } from '@techniek-team/permissions';
import { firstEmitFrom } from '@techniek-team/rxjs';
import { SentryErrorHandler } from '@techniek-team/sentry-web';
import { ToastService } from '@techniek-team/services';
import { BehaviorSubject, from } from 'rxjs';
import { debounceTime, filter, switchMap } from 'rxjs/operators';
import { AssignmentApi } from '../../../../../api/assignment/assignment.api';
import { AssignmentPermission } from '../../../../../core/permission/assignment.permission';

interface SelfAssignForm {
  selfAssignable: FormControl<boolean>;
  allowSelfAssignWhenNew: FormControl<boolean>;
  minimalGradeSelfAssign: FormControl<number | null>;
  maxTravelDistanceSelfAssign: FormControl<number | null>;
}

@Component({
  selector: 'app-self-assign-form',
  templateUrl: './self-assign-form.component.html',
  styleUrls: ['./self-assign-form.component.scss'],
})
export class SelfAssignFormComponent implements OnInit {

  /**
   * The provided assignment.
   */
  @Input() public assignment!: AssignmentDetail<EagerLoaded>;

  /**
   * The form.
   */
  protected selfAssignForm!: FormGroup<SelfAssignForm>;

  /**
   * Subject when true the loading indicator of the selfAssign field is shown.
   */
  protected selfAssignLoader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private assignmentApi: AssignmentApi,
    private errorHandler: SentryErrorHandler,
    private permissionService: PermissionService,
    private toastService: ToastService,
  ) {
  }

  /**
   * @inheritDoc
   */
  public ngOnInit(): void {
    this.selfAssignForm = this.createForm();
    this.createSelfAssignableSubscriber();
    this.createSaveSelfAssignSubscriber();
  }

  /**
   * Creates the selfAssign form with validators.
   */
  private createForm(): FormGroup<SelfAssignForm> {
    const selfAssignFormGroup: FormGroup<SelfAssignForm> = new FormGroup<SelfAssignForm>({
      selfAssignable: new FormControl<boolean>(this.assignment.selfAssignable, { nonNullable: true }),
      allowSelfAssignWhenNew: new FormControl<boolean>(
        { value: this.assignment.allowSelfAssignWhenNew, disabled: !this.assignment.selfAssignable },
        { nonNullable: true },
      ),
      minimalGradeSelfAssign: new FormControl<number | null>(
        {
          value: this.assignment.minimalGradeSelfAssign ?? 7.5,
          disabled: !this.assignment.selfAssignable || !this.assignment.businessService.hasAssignmentReviews,
        },
        [Validators.min(0), Validators.max(10)],
      ),
      maxTravelDistanceSelfAssign: new FormControl<number | null>(
        {
          value: this.assignment.maxTravelDistanceSelfAssign ?? null,
          disabled: !this.assignment.selfAssignable,
        },
        [Validators.min(0), Validators.max(250)],
      ),
    });

    this.permissionService.isDenied(AssignmentPermission, 'EDIT_SELF_ASSIGNABLE', this.assignment).then(isDenied => {
      if (isDenied) {
        selfAssignFormGroup.disable({ emitEvent: false });
      }
    });

    return selfAssignFormGroup;
  }

  /**
   * Create a subscriber which check if the subsequence form control must be disabled or not.
   */
  private createSelfAssignableSubscriber(): void {
    this.selfAssignForm.get('selfAssignable')?.valueChanges.subscribe(selfAssignable => {
      if (selfAssignable === true) {
        this.selfAssignForm.get('allowSelfAssignWhenNew')?.enable({ onlySelf: true, emitEvent: false });
        this.selfAssignForm.get('minimalGradeSelfAssign')?.enable({ onlySelf: true, emitEvent: false });
        this.selfAssignForm.get('maxTravelDistanceSelfAssign')?.enable({ onlySelf: true, emitEvent: false });
      } else {
        this.selfAssignForm.get('allowSelfAssignWhenNew')?.disable({ onlySelf: true, emitEvent: false });
        this.selfAssignForm.get('minimalGradeSelfAssign')?.disable({ onlySelf: true, emitEvent: false });
        this.selfAssignForm.get('maxTravelDistanceSelfAssign')?.disable({ onlySelf: true, emitEvent: false });
      }
    });
  }

  /**
   * trigger save on form change.
   */
  private createSaveSelfAssignSubscriber(): void {
    this.selfAssignForm.valueChanges.pipe(
      filter(() => this.selfAssignForm.valid && this.selfAssignForm.dirty),
      debounceTime(2000),
      switchMap(() => from(this.editSelfAssign())),
    ).subscribe();
  }

  /**
   * Saves the updated assignment selfAssign values to the backend.
   */
  private async editSelfAssign(): Promise<void> {
    // inferring type
    //eslint-disable-next-line @typescript-eslint/typedef
    const formValue = this.selfAssignForm.getRawValue();

    try {
      this.selfAssignLoader$.next(true);

      await firstEmitFrom(this.assignmentApi.updateSelfAssignSettings({
        assignment: this.assignment,
        selfAssign: {
          selfAssignable: formValue.selfAssignable,
          allowSelfAssignWhenNew: formValue.allowSelfAssignWhenNew,
          minimalGradeSelfAssign: formValue.minimalGradeSelfAssign ?? undefined,
          maxTravelDistanceSelfAssign: formValue.maxTravelDistanceSelfAssign ?? undefined,
        },
      }));

      await this.toastService.create('Zelf inplan data opgeslagen.');
    } catch (error) {
      let message: string = 'Er is iets misgegaan bij het bijwerken van de zelf inplan data.';

      if ((error instanceof HttpErrorResponse && error.status !== 400) || !(error instanceof HttpErrorResponse)) {
        await this.errorHandler.captureError(error);
      }

      await this.toastService.error(this.errorHandler.extractMessage(error, message));
    } finally {
      this.selfAssignLoader$.next(false);
    }
  }

}
