import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { PayoutArticleCodeEnum } from '@scheduler-frontend/enums';
import { AssignmentDetailWithAssignmentHasSlot, AssignmentHasSlotDetailedWithSlot } from '@scheduler-frontend/models';
import { EagerLoaded } from '@techniek-team/fetch';
import { PermissionService } from '@techniek-team/permissions';
import { firstEmitFrom } from '@techniek-team/rxjs';
import { SentryErrorHandler } from '@techniek-team/sentry-web';
import { ToastService } from '@techniek-team/services';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { PayoutApi } from '../../../../../api/payout/payout.api';
import { GetPayoutInformationResponse } from '../../../../../api/payout/payout.response';
import { AssignmentPermission } from '../../../../../core/permission/assignment.permission';
import { TtFeatureFlagsService } from '../../../../tt-feature-flags/tt-feature-flags.service';

type PayoutFormValue = NonNullable<FormGroup<PayoutForm>['value']>;

interface PayoutForm {
  article: FormControl<PayoutArticleCodeEnum | null>;

  description: FormControl<string | null>;

  assignmentHasSlot: FormControl<AssignmentHasSlotDetailedWithSlot | null>;

  quantity: FormControl<string | null>;

  amount: FormControl<string | null>;
}

@Component({
  selector: 'app-add-compensation-line-modal',
  templateUrl: './add-compensation-line-modal.component.html',
  styleUrls: ['./add-compensation-line-modal.component.scss'],
})
export class AddCompensationLineModalComponent implements OnInit {

  @Input() public payout!: GetPayoutInformationResponse;

  @Input() public set assignment(assignment: AssignmentDetailWithAssignmentHasSlot<EagerLoaded> | null | undefined) {
    if (!assignment) {
      return;
    }
    this.currentAssignment = assignment;
  }

  private currentAssignment!: AssignmentDetailWithAssignmentHasSlot<EagerLoaded>;

  /**
   * Form to add compensation lines.
   */
  protected compensationLineForm!: FormGroup<PayoutForm>;

  protected allowedArticleCodes!: Map<string, string>;

  /**
   * An observable containing all the assignment has slots that can be
   * selected when adding a new compensation line. These depend on the
   * selected article code.
   */
  protected selectableAssignmentHasSlots$!: Observable<AssignmentHasSlotDetailedWithSlot[]>;

  /**
   * Subject when true the loading indicator of the compensationLine field is shown.
   */
  protected createCompensationLineLoader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * An observable containing all the allowed article codes for new
   * compensation lines.
   */
  protected get compensationLines(): GetPayoutInformationResponse['compensationLines'][0][] {
    return (this.payout.compensationLines ?? []).sort((lineA, lineB) => {
      if (lineA.isExtra && lineB.isExtra) {
        return 0;
      }
      return lineA.isExtra ? 1 : -1;
    });
  }

  private amountMaxValidator: ValidatorFn = Validators.max(100);

  /**
   * A subject used to destroy hot observers.
   */
  private onDestroy$: Subject<void> = new Subject();

  constructor(
    private payoutApi: PayoutApi,
    private formBuilder: FormBuilder,
    private permissionService: PermissionService,
    private toastService: ToastService,
    private modalController: ModalController,
    private sentryErrorHandler: SentryErrorHandler,
    private featureFlagService: TtFeatureFlagsService,
  ) {
  }

  public ngOnInit(): void {
    this.compensationLineForm = this.createPayoutForm();
    this.selectableAssignmentHasSlots$ = this.createSelectableAssignmentHasSlotsObserver();
    this.compensationLineForm.valueChanges.subscribe(() => this.compensationLineForm);
    this.toggleAssignmentHasSlotControlSubscriber();
    this.setAllowedArticleCodes();
  }

  public dismiss(): Promise<boolean> {
    return this.modalController.dismiss(null, 'cancel');
  }

  //eslint-disable-next-line max-lines-per-function
  public async addLine(): Promise<boolean> {
    if (!this.compensationLineForm.valid) {
      return Promise.resolve(false);
    }
    const assignmentCompensationLine: string = this.payout.general.assignmentCompensationId;
    const formData: PayoutFormValue = this.compensationLineForm.getRawValue();

    try {
      const date: Date | null = (formData.assignmentHasSlot as AssignmentHasSlotDetailedWithSlot).slot.timePeriod.start;
      this.createCompensationLineLoader$.next(true);
      await firstEmitFrom(this.payoutApi.postCompensationLine({
        assignmentCompensationLine: assignmentCompensationLine,
        articleCode: formData.article as string,
        description: formData.description as string,
        quantity: formData.quantity as string,
        amount: formData.amount as string,
        assignmentHasSlot: formData.assignmentHasSlot as AssignmentHasSlotDetailedWithSlot,
        subtotal: parseFloat(formData.quantity as string) * parseFloat(formData.amount as string),
        date: date ?? this.currentAssignment.assignmentHasSlots[0].slot.timePeriod.start,
      }));

      return this.modalController.dismiss(null, 'confirm');
    } catch (error) {
      let toastError: string = 'Er is iets fout gegaan bij het toevoegen van deze vergoedingsregel.';

      await Promise.all([
        this.toastService.error(this.sentryErrorHandler.extractMessage(error, toastError)),
        this.sentryErrorHandler.captureError(error),
      ]);
      return this.modalController.dismiss(null, 'error');
    } finally {
      this.createCompensationLineLoader$.next(false);
    }
  }

  private async setAllowedArticleCodes(): Promise<void> {
    const mapAllowedArticleCode: Map<PayoutArticleCodeEnum, string> = new Map<PayoutArticleCodeEnum, string>();
    if (this.payout.compensationLines.find(compensationLine => compensationLine.compensation?.allowExtraExpenses)) {
      mapAllowedArticleCode.set(PayoutArticleCodeEnum.REIMBURSEMENT, 'Reiskosten');
    }
    if (this.payout.compensationLines.find(compensationLine => compensationLine.compensation?.allowExtraHours)) {
      mapAllowedArticleCode.set(PayoutArticleCodeEnum.REMUNERATION, 'Extra uren');
    }

    const addPremiumAllowed: boolean = await this.permissionService.isGranted(
      AssignmentPermission,
      'ADD_PREMIUM_ALLOWED',
      this.payout,
    );
    if (addPremiumAllowed && this.featureFlagService.isEnabled('slot_premium')) {
      mapAllowedArticleCode.set(PayoutArticleCodeEnum.SLOT_PREMIUM, 'Schaarstetoeslag');
    }
    this.allowedArticleCodes = mapAllowedArticleCode;
  }

  private createPayoutForm(): FormGroup<PayoutForm> {
    return this.formBuilder.group<PayoutForm>({
      article: this.formBuilder.control<PayoutArticleCodeEnum | null>(null, [Validators.required]),
      description: this.formBuilder.control<string | null>(null, [Validators.required]),
      assignmentHasSlot: this.formBuilder.control<AssignmentHasSlotDetailedWithSlot | null>(
        null,
        [Validators.required],
      ),
      quantity: this.formBuilder.control<string | null>(
        null,
        [Validators.required, Validators.pattern('^\\s*(?=.*[1-9])\\d+(\\.\\d{1,2})?\\s*$')],
      ),
      amount: this.formBuilder.control<string | null>(
        null,
        [Validators.required, Validators.pattern('^\\s*(?=.*[1-9])\\d+(\\.\\d{2})?\\s*$')],
      ),
    }) as FormGroup<PayoutForm>;
  }

  /**
   * Create an observable containing all the assignment has slots that can be
   * chosen when an article code has been selected.
   */
  private createSelectableAssignmentHasSlotsObserver(): Observable<AssignmentHasSlotDetailedWithSlot[]> {
    return (this.compensationLineForm.get('article') as AbstractControl).valueChanges.pipe(
      map(selectedArticle => {
        // Find the assignment has slot ids that are linked to assignment
        // compensations which are valid options based on the selected article
        // code.
        const validAssignmentHasSlotIds: string[] = this.compensationLines
          .filter(compensationLine => {
            if (selectedArticle === PayoutArticleCodeEnum.REIMBURSEMENT) {
              return compensationLine.compensation?.allowExtraExpenses;
            }

            if (selectedArticle === PayoutArticleCodeEnum.REMUNERATION) {
              return compensationLine.compensation?.allowExtraHours;
            }

            return selectedArticle === PayoutArticleCodeEnum.SLOT_PREMIUM;
          })
          .map(compensationLine => compensationLine.assignmentHasSlotId);

        // Use the found assignment has slot ids to match the assignment has
        // slots linked to the assignment.
        return this.currentAssignment.assignmentHasSlots
          .filter(assignmentHasSlot => {
            return validAssignmentHasSlotIds.includes(assignmentHasSlot.getId());
          });
      }),
    );
  }

  /**
   * Listen to the value changes of the article FormControl to
   * make changes to the form controls.
   */
  private toggleAssignmentHasSlotControlSubscriber(): void {
    this.compensationLineForm.get('article')?.valueChanges.pipe(
      takeUntil(this.onDestroy$),
      startWith(null),
    ).subscribe((articleCode: PayoutArticleCodeEnum | null) => {
      this.handleAssignmentHasSlotControl(articleCode);
      this.handleSlotPremiumArticle(articleCode);
    });
  }

  /**
   * Set and remove validators for the slot premium
   */
  private handleSlotPremiumArticle(articleCode: PayoutArticleCodeEnum | null): void {
    const quantityControl: FormControl<string> = this.compensationLineForm.get('quantity') as FormControl<string>;
    const amountControl: FormControl<string> = this.compensationLineForm.get('amount') as FormControl<string>;

    if (articleCode === PayoutArticleCodeEnum.SLOT_PREMIUM) {
      quantityControl.setValue('1');
      quantityControl.disable();

      amountControl.addValidators(this.amountMaxValidator);
    } else {
      quantityControl.enable();

      amountControl.removeValidators(this.amountMaxValidator);
    }

    amountControl.updateValueAndValidity();
  }

  /**
   * Enable/disable the assignment has slot FormControl.
   */
  private handleAssignmentHasSlotControl(articleCode: PayoutArticleCodeEnum | null): void {
    const control: FormControl<AssignmentHasSlotDetailedWithSlot | null> = this.compensationLineForm
      .get('assignmentHasSlot') as FormControl<AssignmentHasSlotDetailedWithSlot | null>;
    control.setValue(null);

    if (articleCode) {
      control.enable();
    } else {
      control.disable();
    }
  }
}
