import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@scheduler-frontend/environments';
import { AssignmentHasSlotDetailed } from '@scheduler-frontend/models';
import { Observable } from 'rxjs';

export class MaskAsPresentRequest {
  constructor(
    public assignmentHasSlot: AssignmentHasSlotDetailed,
  ) {
  }
}

@Injectable({
  providedIn: 'root',
})
export class MarkAsPresentApi {

  constructor(protected httpClient: HttpClient) {}

  public execute(message: MaskAsPresentRequest): Observable<void> {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/mark-as-present`;

    return this.httpClient.post<void>(url, {
      assignmentHasSlotId: message.assignmentHasSlot.getId(),
    });
  }
}
