<ng-container *ngIf="selfAssignForm"
              [formGroup]="selfAssignForm">
  <ion-list>
    <ion-list-header>
      Zelf inplannen
      <ion-spinner *ngIf="selfAssignLoader$ | async"></ion-spinner>
    </ion-list-header>
    <ion-item lines="none">
      <ion-toggle labelPlacement="start"
                  color="secondary"
                  formControlName="selfAssignable">
        Zelf inplanbaar
      </ion-toggle>
    </ion-item>
    <ion-item lines="none">
      <ion-label></ion-label>
      <ion-toggle labelPlacement="start"
                  color="secondary"
                  formControlName="allowSelfAssignWhenNew">
        Zelf inplanbaar indien nieuw
      </ion-toggle>
    </ion-item>
    <ion-item lines="none">
      <tt-number-input
        label="Minimaal cijfer"
        labelPlacement="stacked"
        fill="outline"
        [max]="10"
        [min]="1"
        formControlName="minimalGradeSelfAssign"></tt-number-input>
      <tt-number-input
        label="Maximaal aantal km"
        labelPlacement="stacked"
        fill="outline"
        [allowDecimals]="false"
        [max]="350"
        [disabled]="true"
        [min]="0"
        [placeholder]="25"
        formControlName="maxTravelDistanceSelfAssign"></tt-number-input>
    </ion-item>
  </ion-list>

</ng-container>
