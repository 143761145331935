import { LazyJsonLd as JsonLd } from '@techniek-team/fetch';
import { Expose } from 'class-transformer';

/**
 * Detailed version of the Region resource from Scheduler-api
 */
export class Region extends JsonLd {

  /**
   * @inheritDoc
   */
  public override readonly className: string = 'Region';

  /**
   * The abbreviation or sort version of the {@see name}
   */
  @Expose() public abbreviation!: string;

  /**
   * Name of this Resource
   */
  @Expose() public name!: string;

  /**
   * Returns true if this region is a province of the netherlands.
   */
  @Expose() public isProvince!: boolean;

  /**
   * @inheritDoc
   */
  public override toString(): string {
    return this.name;
  }
}
