import { JsonLd, TsRange } from '@techniek-team/class-transformer';
import { EagerLoaded } from '@techniek-team/fetch';
import { FetchObservable } from '@techniek-team/rxjs';
import { Expose, Type } from 'class-transformer';
import { Skill } from '../../skill/skill.model';

export class CandidateSkill<Lazy = FetchObservable<unknown>> extends JsonLd {

  /**
   * @inheritDoc
   */
  public override readonly className: string = 'CandidateSkill';

  @Type(() => Skill)
  @Expose() public skill!: Skill<Lazy>;

  @Type(() => TsRange)
  @Expose() public validityRange!: TsRange;


  public async fetchAll(): Promise<CandidateSkill<EagerLoaded>> {
    const candidateSkill: CandidateSkill<EagerLoaded> = this as CandidateSkill<EagerLoaded>;
    Object.assign(candidateSkill.skill, await this.skill.fetchAll());
    return candidateSkill;
  }

  /**
   * @inheritDoc
   */
  public override toString(): string {
    return `${this.className}: ${this.getId()}`;
  }
}
