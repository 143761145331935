import { Injectable, OnDestroy } from '@angular/core';
import { AssignmentDetailWithAssignmentHasSlot, CandidateDetails } from '@scheduler-frontend/models';
import { denormalize } from '@techniek-team/class-transformer';
import { eagerLoad, EagerLoaded } from '@techniek-team/fetch';
import { isDefined } from '@techniek-team/rxjs';
import { SentryErrorHandler } from '@techniek-team/sentry-web';
import { ToastService } from '@techniek-team/services';
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject, share, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { AssignmentApi } from '../../../api/assignment/assignment.api';
import { CandidateApi } from '../../../api/candidate/candidate.api';

/**
 * This service is specific design to share the Assignment model used in
 * {@see AssignmentModalComponent} between the various subcomponents of that
 * component.
 */
@Injectable()
export class AssignmentModalService implements OnDestroy {

  /**
   * Observable emitting a full assignment model.
   */
  public readonly assignment$: Observable<AssignmentDetailWithAssignmentHasSlot<EagerLoaded>>;

  /**
   * Observable emitting the candidate that is assigned to this
   * Assignment.
   */
  public readonly candidate$: Observable<CandidateDetails | undefined>;

  public assignmentHasChanged: boolean = false;

  /**
   * {@see assignmentIdSubject} getter
   */
  public get assignmentId(): string {
    return this.assignmentIdSubject.getValue() as string;
  }

  /**
   * {@see assignmentIdSubject} setter.
   */
  public set assignmentId(assignmentId: string) {
    if (assignmentId === this.assignmentIdSubject.getValue()) {
      return;
    }
    this.assignmentIdSubject.next(assignmentId);
  }

  /**
   * Reload subject for {@see assignment$}
   */
  private reloadAssignment$: Subject<void> = new Subject<void>();

  /**
   * Subject containing the assignmentId.
   *
   * Used in {@see createAssignmentObserver} to retrieve the current assignment
   * to show in the modal.
   */
  private assignmentIdSubject: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);

  /**
   * Subject with which we can destroy all others hot observers
   */
  private onDestroy$: Subject<void> = new Subject();

  constructor(
    private assignmentApi: AssignmentApi,
    private candidateApi: CandidateApi,
    private toastService: ToastService,
    private sentryErrorHandler: SentryErrorHandler,
  ) {
    this.assignment$ = this.createAssignmentObserver();
    this.candidate$ = this.createCandidateObserver();
  }

  /**
   * @inheritDoc
   */
  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  /**
   * marks {@see assignmentHasChanged} as true and reloads the assignment.
   */
  public reloadAssignment(): void {
    this.assignmentHasChanged = true;
    this.reloadAssignment$.next();
  }

  /**
   * Create an observable emitting the current detailed assignment.
   */
  private createAssignmentObserver(): Observable<AssignmentDetailWithAssignmentHasSlot<EagerLoaded>> {
    return combineLatest([
      this.assignmentIdSubject,
      this.reloadAssignment$.pipe(startWith(undefined as void)),
    ]).pipe(
      takeUntil(this.onDestroy$),
      isDefined([0]),
      switchMap(([assignmentId]) => {
        return this.assignmentApi.getAssignment(assignmentId as string).pipe(
          catchError((error) => Promise.all([
            this.sentryErrorHandler.captureError(error),
            this.toastService.error(this.sentryErrorHandler.extractMessage(
              error,
              'Ophalen van de opdrachtgegevens is mislukt.',
            )),
          ]).then(() => undefined)),
          isDefined(),
        );
      }),
      eagerLoad<AssignmentDetailWithAssignmentHasSlot<EagerLoaded>>(),
      share({
        connector: () => new ReplaySubject(1),
        resetOnError: false,
        resetOnComplete: false,
        resetOnRefCountZero: false,
      }),
    );
  }

  /**
   * Create an observable containing the candidate assigned to this assignment.
   */
  private createCandidateObserver(): Observable<CandidateDetails | undefined> {
    return this.assignment$.pipe(
      distinctUntilChanged((assignmentA, assignmentB) => {
        return assignmentA.candidate?.getIri() === assignmentB.candidate?.getIri();
      }),
      switchMap(assignment => {
        if (!assignment.candidate) {
          return of(undefined);
        }
        return this.candidateApi.getCandidate(assignment.candidate).pipe(
          isDefined(),
          map((response) => denormalize(CandidateDetails, response)),
          catchError((error) => Promise.all([
            this.sentryErrorHandler.captureError(error),
            this.toastService.error(this.sentryErrorHandler.extractMessage(
              error,
              'Ophalen van de opdrachtgegevens is mislukt.',
            )),
          ]).then(() => undefined)),
        );
      }),
      share({
        connector: () => new ReplaySubject(1),
        resetOnError: false,
        resetOnComplete: false,
        resetOnRefCountZero: false,
      }),
    );
  }
}
