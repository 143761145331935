import { AddressTypeEnum } from '@scheduler-frontend/enums';
import { environment } from '@scheduler-frontend/environments';
import { EagerLoaded, Fetched } from '@techniek-team/fetch';
import { FetchObservable } from '@techniek-team/rxjs';
import { Exclude, Expose, Type } from 'class-transformer';
import { format } from 'date-fns';
import { BehaviorSubject, Observable } from 'rxjs';
import { Address } from '../address/address.model';
import { BusinessService } from '../business/business-service.model';
import { LocationModel } from '../location/location.model';
import { Role } from '../role/role.model';
import { CandidateAvailabilities } from './availabilities/candidate-availabilities.model';
import { Candidate } from './candidate.model';
import { CandidateGrade } from './grades/candidate-grades.model';
import { CandidateLocation } from './location/candidate-location.model';
import { Remarks } from './remarks/candidate-remarks.model';
import { SkillsPerService } from './skill-per-service/skill-per-service.model';
import { CandidateSkill } from './skill/candidate-skilll.model';

export class CandidateDetails<Lazy = FetchObservable<unknown>> extends Candidate {

  /**
   * @inheritDoc
   */
  public override readonly className: string = 'CandidateDetails';

  /**
   * first middle and surname of the candidate
   */
  @Expose() public firstName!: string;

  @Expose() public middleName?: string;

  @Expose() public lastNamePrefix!: string;

  @Expose() public hasCar!: boolean;

  @Expose() public hasDriversLicense!: boolean;

  @Expose() public hasVog!: boolean;

  @Type(() => Date)
  @Expose() public dateVog?: Date;

  /**
   * an array of addresses where the candidate lives
   */
  @Type(() => Address)
  @Expose() public addresses!: Address[];

  @Type(() => CandidateLocation)
  @Expose() public locations!: CandidateLocation<Lazy>[];

  /**
   * the rating the candidate is give by category
   */
  @Type(() => Remarks)
  @Expose() public remarks!: Remarks;

  /**
   * a detailed overview of rating the candidate is given for it's previous work
   */
  @Type(() => CandidateGrade)
  @Expose() public detailedGrades!: CandidateGrade[];

  /**
   * availabilities specified by a planner
   * todo retrieve by endpoint
   */
  @Type(() => CandidateAvailabilities)
  @Expose() public availabilitiesByPlanner: CandidateAvailabilities[] = [];

  /**
   * a full list of skill a has candidate to which we check the slot criteria
   */
  @Type(() => CandidateSkill)
  @Expose() public candidateHasSkills!: CandidateSkill[];

  private skillList!: CandidateSkill<Lazy>[];

  private skillPerSubjects$: BehaviorSubject<SkillsPerService[]> = new BehaviorSubject<SkillsPerService[]>([]);

  public get skillsPerSubject(): Observable<SkillsPerService[]> {
    return this.skillPerSubjects$.asObservable();
  }

  public get skills(): CandidateSkill<Lazy>[] {
    return this.skillList;
  }

  public get longTermAvailabilites(): CandidateAvailabilities[] {
    return this.availabilitiesByPlanner
      .filter(availability => format(availability.range.end, 'HH:mm') === '00:00');
  }

  @Exclude() public getProfilePicture(): string {
    return `${environment.scheduler.url}${environment.scheduler.iri}/${this.pictureUrl}`;
  }

  public getMainLocation(): Fetched<Lazy, LocationModel> | undefined {
    return this.locations.find(loc => loc.isMain)?.location;
  }

  public get mainAddress(): Address | undefined {
    return this.addresses.find(address => address.type === AddressTypeEnum.HOME);
  }

  /**
   * Uses the CandidateDetails' skills to create a list of skills that are grouped
   * first by BusinessService and BusinessEntity, then by Role and lastly by each
   * of the skill's level. The size of the past and future items is calculated by
   * adding the different size properties together from the SkillsPerRole objects,
   * to make it easier to display an accurate count of past and future skills for
   * the current SkillsPerService object. The list can be easily displayed to the
   * user, since all items are sorted in their respective smaller groups.
   *
   * @example
   * ```
   *  [MyServiceName, MyEntityName]
   *    [RoleName] // Sorted by amount of subjects
   *      [Subject, Subject, Subject]   | [Level, Level]
   *      [Subject, Subject]            | [Level, Level, Level, Level]
   *      [Subject]                     | [Level]
   *    [RoleName]
   *      [...]
   *    [3 future items] // From the reduce x.size
   *      [RoleName]
   *        [Subject (date)]            | [Level]
   *        [Subject (date)]
   *        [Subject (date)]            | [Level, Level]
   *  [OtherServiceName, OtherEntityName]
   *    [...]
   * ```
   */
  private async calculateSkillsPerService(): Promise<void> {
    const skillPromises: Promise<CandidateSkill<EagerLoaded>>[] = this.skills.map(skill => skill.fetchAll());

    const skills: CandidateSkill<EagerLoaded>[] = await Promise.all(skillPromises);
    const serviceMap: Map<string, SkillsPerService> = new Map();

    for (const skill of skills) {
      const service: BusinessService | undefined = (skill.skill.role as Role<EagerLoaded>).businessService;
      if (!service) {
        continue;
      }
      if (!serviceMap.has(service.getIri() as string)) {
        serviceMap.set(service.getIri() as string, new SkillsPerService(
          service,
          [skill],
        ));
      } else {
        serviceMap.get(service.getIri() as string)?.addSkill(skill);
      }
    }

    this.skillPerSubjects$.next([...serviceMap.values()].sort((
      a,
      b,
    ) => a.businessService.name.localeCompare(b.businessService.name)));
  }


}
