import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { LoadingComponent } from './loading.component';
import { LoadingDirective } from './loading.directive';

@NgModule({
  imports: [
    MatIconModule,
  ],
  exports: [
    LoadingComponent,
    LoadingDirective,
  ],
  declarations: [
    LoadingComponent,
    LoadingDirective,
  ],
})
export class LoadingModule {}
