import { Location as LocationUrl } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ModalController, ViewDidEnter, ViewDidLeave } from '@ionic/angular';
import {
  AssignmentStateDisplayValues,
  AssignmentStateEnum,
  TransitionEnum,
  transitionMessages,
  TransitionToMessage,
  TransitionToStateEnum,
} from '@scheduler-frontend/enums';
import { AssignmentDetailWithAssignmentHasSlot, TransitionOption } from '@scheduler-frontend/models';
import { TtSimpleModalComponent } from '@techniek-team/components/modal';
import { EagerLoaded } from '@techniek-team/fetch';
import { SentryErrorHandler } from '@techniek-team/sentry-web';
import { ToastService } from '@techniek-team/services';
import { format } from 'date-fns';
import { firstValueFrom, Observable, ReplaySubject, share, Subject } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';
import { AssignmentApi } from '../../../api/assignment/assignment.api';
import { ApplyTransitionResponse } from '../../../api/assignment/assignment.response';
import { UnassignService } from '../../services/mark-as-absent/unassign.service';
import {
  AssignmentTransitionMessagesModalComponent,
} from '../assignment-transition-messages-modal/assignment-transition-messages-modal.component';
import { AssignmentModalRoutingService, AssignmentModalSegmentsUrlValue } from './assignment-modal-routing.service';
import { AssignmentModalService } from './assignment-modal.service';
import { containsInfoOrSuccess, containsWarningOrError } from './assignment-transition-message.fuction';

export enum AssignmentModalSegments {
  OVERVIEW = 'OVERVIEW',
  PAYOUT = 'PAYOUT',
  HISTORY = 'HISTORY',
}

@Component({
  selector: 'app-edit-assignment-modal',
  templateUrl: './assignment-modal.component.html',
  styleUrls: ['./assignment-modal.component.scss'],
  providers: [AssignmentModalService],
})
export class AssignmentModalComponent implements OnInit, OnDestroy, ViewDidEnter, ViewDidLeave {

  public availableTransition$!: Observable<TransitionOption[]>;

  @Input()
  public set assignmentId(id: string) {
    this.id = id;
    this.assignmentModalService.assignmentId = id;
  }

  @Input()
  public set segment(segment: AssignmentModalSegments) {
    if (!segment) {
      return;
    }
    let path: string = this.locationUrl.path();
    const match: RegExpMatchArray | null = path.match(/(.*\/assignment\/[^/]*)\/.*?/);
    if (match) {
      path = match[1];
    }

    const segmentUrlPart: string = Object.keys(AssignmentModalSegmentsUrlValue)[
      Object.values(AssignmentModalSegmentsUrlValue).indexOf(segment as unknown as AssignmentModalSegmentsUrlValue)
    ];
    this.locationUrl.replaceState(`${path}/${segmentUrlPart}`);
    this.currentSegment = segment;
  }

  //eslint-disable-next-line max-len
  protected assignmentStatusControl: FormControl<AssignmentStateEnum | TransitionEnum | null> = new FormControl(
    {
      value: null,
      disabled: true,
    });

  protected readonly TransitionToMessage: typeof TransitionToMessage = TransitionToMessage;

  protected readonly AssignmentModalSegments: typeof AssignmentModalSegments = AssignmentModalSegments;

  protected readonly AssignmentStateDisplayValues: typeof AssignmentStateDisplayValues = AssignmentStateDisplayValues;

  protected readonly AssignmentStateEnum: typeof AssignmentStateEnum = AssignmentStateEnum;

  protected currentSegment: string = AssignmentModalSegments.OVERVIEW;

  private id!: string;

  public assignment$: Observable<AssignmentDetailWithAssignmentHasSlot<EagerLoaded>> = this.assignmentModalService.
    assignment$.pipe(filter(assignment => assignment.getId() === this.id));

  private onDestroy$: Subject<void> = new Subject();

  constructor(
    private assignmentApi: AssignmentApi,
    private toastService: ToastService,
    private sentryErrorHandler: SentryErrorHandler,
    private assignmentModalService: AssignmentModalService,
    private modalController: ModalController,
    private locationUrl: LocationUrl,
    private assignmentModalRoutingService: AssignmentModalRoutingService,
    private unassignService: UnassignService,
  ) {
  }

  /**
   * @inheritDoc
   */
  public ngOnInit(): void {
    this.availableTransition$ = this.createAvailableTransitionsObservable();

    this.availableTransition$.subscribe(transition => {
      if (transition.length === 0) {
        this.assignmentStatusControl.disable({ emitEvent: false });
        return;
      }
      this.assignmentStatusControl.enable({ emitEvent: false });
    });
    this.assignment$.subscribe(assignment => {
      this.assignmentStatusControl.patchValue(assignment.state, { emitEvent: false });
    });
    this.createAssignmentStateChangeSubscription();
    this.checkCancelledWarning();
  }

  /**
   * @inheritDoc
   */
  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  /**
   * @inheritDoc
   */
  public ionViewDidEnter(): void {
    this.assignmentModalRoutingService.setRoute(this.assignmentModalService.assignmentId);
  }

  /**
   * @inheritDoc
   */
  public ionViewDidLeave(): void {
    this.assignmentModalRoutingService.removeRoute();
  }

  public segmentChanged(tab: AssignmentModalSegments): void {
    this.segment = tab;
  }

  //eslint-disable-next-line max-lines-per-function
  public async onAssignmentTransitionSubmit(value: TransitionEnum): Promise<boolean> {
    if (!value) {
      return false;
    }

    const assignment: AssignmentDetailWithAssignmentHasSlot = await firstValueFrom(
      this.assignmentModalService.assignment$,
    );

    if (value === TransitionEnum.UNASSIGN) {
      await this.unassignService.unassign(assignment, undefined, true);

      return this.modalController.dismiss(true, 'unassignSubmit');
    }

    //eslint-disable-next-line @typescript-eslint/typedef
    const transitionToState = TransitionToStateEnum[value] as unknown as keyof typeof AssignmentStateDisplayValues;
    let newState: string = AssignmentStateDisplayValues[transitionToState];
    const modal: HTMLIonModalElement = await this.modalController.create({
      component: TtSimpleModalComponent,
      cssClass: ['stack-modal'],
      componentProps: {
        title: 'Bevestig status wijziging',
        message: `${transitionMessages[value]} De nieuwe status wordt dan "${newState}".`,
      },
    });
    await modal.present();
    const { role } = await modal.onWillDismiss();
    if (role === 'cancel') {
      return false;
    }
    try {
      const response: ApplyTransitionResponse = await firstValueFrom(this.assignmentApi.applyTransition({
        assignment: this.assignmentModalService.assignmentId,
        transition: value,
      }));
      await this.displayMessageAboutAssignmentTransition(response);
    } catch (error) {
      if (error instanceof HttpErrorResponse && error.status === 400 && error.error.message) {
        await this.toastService.error(error.error.message);
      } else {
        await Promise.all([
          this.sentryErrorHandler.captureError(error),
          this.toastService.error(this.sentryErrorHandler.extractMessage(
            error,
            'Er is iets mis gegaan bij het wijzigen van de status',
          )),
        ]);
      }
    }
    return this.modalController.dismiss(false, 'transitionSubmit');
  }

  public close(): Promise<boolean> {
    return this.modalController.dismiss(this.assignmentModalService.assignmentHasChanged, 'close');
  }

  private createAssignmentStateChangeSubscription(): void {
    this.assignmentStatusControl.valueChanges.subscribe(async value => {
      const success: boolean = await this.onAssignmentTransitionSubmit(value as TransitionEnum);

      if (success) {
        return;
      }

      const assignment: AssignmentDetailWithAssignmentHasSlot = await firstValueFrom(
        this.assignmentModalService.assignment$,
      );
      this.assignmentStatusControl.patchValue(assignment.state, { emitEvent: false });
    });
  }

  private checkCancelledWarning(): void {
    this.assignment$.pipe(take(1)).subscribe(async (assignment) => {
      if (assignment.state === AssignmentStateEnum.CANCELLED && assignment.assignmentHasSlots.length) {
        const date: string = format(assignment.assignmentHasSlots[0].startDate, 'dd-MM-yyyy');
        const confirmModal: HTMLIonModalElement = await this.modalController.create({
          component: TtSimpleModalComponent,
          cssClass: ['stack-modal'],
          componentProps: {
            title: 'Let op',
            message: `Deze opdracht bevat een geannuleerde shift die niet uitgeroosterd kan worden.
            Probeer ${assignment.candidate?.fullName} nog in te plannen op een andere shift op ${date}!`,
            buttons: [
              {
                text: 'OK',
              },
            ],
          },
        });
        confirmModal.present();
      }
    });
  }

  private createAvailableTransitionsObservable(): Observable<TransitionOption[]> {
    return this.assignmentModalService.assignment$.pipe(
      switchMap(assignment => this.assignmentApi.getTransitions(assignment)),
      share({
        connector: () => new ReplaySubject(1),
        resetOnError: false,
        resetOnComplete: false,
        resetOnRefCountZero: false,
      }),
    );
  }

  /**
   * Display information about the assignment transition that has occurred.
   * This can be done in two ways:
   *
   * - Display a modal where messages are shown to the user. These messages
   *   can be about errors, warnings, information or successes. The modal
   *   will be shown when there is more than a single message OR there is a
   *   single warning or error message.
   *
   * - Display a toast using the snack bar. This will be a simple message.
   *   If there is a single info or success message this will be included to
   *   the message that is displayed.
   */
  private async displayMessageAboutAssignmentTransition(transition: ApplyTransitionResponse): Promise<void> {
    let oldDutchState: string = AssignmentStateDisplayValues[transition.oldState];
    let newDutchState: string = AssignmentStateDisplayValues[transition.newState];

    if (transition.messages.length > 1 || containsWarningOrError(transition)) {
      const modal: HTMLIonModalElement = await this.modalController.create({
        component: AssignmentTransitionMessagesModalComponent,
        componentProps: { transition: transition },
      });
      await modal.present();
      await modal.onDidDismiss();
    }

    let toastMessage: string = `De status van de opdracht is veranderd naar ${newDutchState}.`;
    if (transition.oldState) {
      toastMessage = `De status van de opdracht is veranderd van ${oldDutchState} naar ${newDutchState}.`;
    }

    let toastDuration: undefined | number = 5000;
    if (containsInfoOrSuccess(transition)) {
      toastMessage += ' ' + transition.messages[0].text;
      toastDuration = undefined;
    }
    return this.toastService.create(toastMessage, toastDuration);
  }
}
