import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@scheduler-frontend/environments';
import { BusinessService, BusinessServiceDetailed } from '@scheduler-frontend/models';
import { Collection, Resource } from '@techniek-team/api-platform';
import { denormalize, Hydra } from '@techniek-team/class-transformer';
import { CacheService } from '@techniek-team/services';
import { hoursToMilliseconds } from 'date-fns';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GetBusinessServiceDetailedResponse, GetBusinessServiceResponse } from './business-service.response';

export interface BusinessServiceApiInterface {
  getBusinessService(businessService: string | BusinessService): Observable<BusinessServiceDetailed>;
  getBusinessServices(): Observable<Hydra<BusinessService>>;
}

/**
 * Api to retrieve the {@see BusinessService} resource from the scheduler-api
 */
@Injectable({
  providedIn: 'root',
})
export class BusinessServiceApi implements BusinessServiceApiInterface {
  constructor(
    private httpClient: HttpClient,
    private cacheService: CacheService,
  ) {
  }

  /**
   * Gets a specific Business service
   */
  public getBusinessService(businessService: string | BusinessService): Observable<BusinessServiceDetailed> {
    businessService = (businessService instanceof BusinessService) ? businessService.getId() : businessService;
    //eslint-disable-next-line max-len
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/business_services/${businessService}`;

    //todo retrieve this from the cache as well.
    return this.httpClient.get<Resource<GetBusinessServiceDetailedResponse>>(url)
      .pipe(map(response => denormalize(BusinessServiceDetailed, response)));
  }

  /**
   * Gets a list for all business services.
   *
   * The response is cashed in the {@see CacheService} and is refreshed every day.
   * The list comes sorted ascending on name.
   */
  public getBusinessServices(): Observable<Hydra<BusinessService>> {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/business_services`;
    const call: Observable<Hydra<BusinessService>> = this.httpClient
      .get<Collection<GetBusinessServiceResponse>>(url)
      .pipe(
        map(response => denormalize(BusinessService, response)),
      );

    return this.cacheService.create<Hydra<BusinessService>>(
      'BusinessService',
      call,
      { refreshInterval: hoursToMilliseconds(24) },
    );
  }
}
