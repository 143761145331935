import { LazyJsonLd as JsonLd } from '@techniek-team/fetch';
import { Expose, Type } from 'class-transformer';
import { Coordinate } from './coordinate.model';

export class LocationMinimal extends JsonLd {

  /**
   * The Gps coordinates of this location.
   *
   * These coordinates are used to show the location within googleMaps on the
   * {@see AssignmentDetailPage}
   */
  @Type(() => Coordinate)
  @Expose() public coordinates?: Coordinate;

  /**
   * The name of the location.
   *
   * The name of the location is shown on the Assignment card.
   * {@see AssignmentCardComponent }
   */
  @Expose() public name?: string;
}
