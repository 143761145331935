import { LazyJsonLd as JsonLd } from '@techniek-team/fetch';
import { Expose, Type } from 'class-transformer';
import { ProductType } from '../product-type/product-type.model';

/**
 * standard version of the Role resource from Scheduler-api
 *
 * It's named SubjectModel because Subject matches too much with Rxjs Subject classes.
 */
export class SubjectModel extends JsonLd {

  /**
   * @inheritDoc
   */
  public override readonly className: string = 'SubjectModel';

  /**
   * The abbreviation or sort version of the {@see name}
   */
  @Expose() public abbreviation!: string;

  /**
   * Name of this Subject
   */
  @Expose() public name!: string;

  @Type(() => ProductType)
  @Expose() public productTypes: ProductType[] = [];

  /**
   * @inheritDoc
   */
  public override toString(): string {
    return this.name;
  }
}
