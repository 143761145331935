import { NgModule, Type } from '@angular/core';
import { NoPreloading, PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { environment } from '@scheduler-frontend/environments';
import { OAuthGuard } from './core/oauth.guards';
import { MenuLayoutPage } from './menu-layout/menu-layout.page';

/**
 * All routes are also specified in the {@see ViewPermission} so add the route also
 * there
 */
const appRoutes: Routes = [
  {
    path: '',
    canActivate: [OAuthGuard],
    component: MenuLayoutPage,
    children: [
      {
        path: 'home',
        pathMatch: 'full',
        canActivate: [OAuthGuard],
        loadChildren: (): Promise<Type<unknown>> => import('./home/home.module')
          .then(m => m.HomePageModule),
      },
      {
        path: 'agenda',
        canActivate: [OAuthGuard],
        loadChildren: (): Promise<Type<unknown>> => import('./location-overview/location-overview.module')
          .then(m => m.LocationOverviewModule),
      },
      {
        path: 'location-overview',
        redirectTo: 'agenda',
      },
      {
        path: 'daadwerkelijk-gewerkte-uren',
        canActivate: [OAuthGuard],
        loadChildren: (): Promise<Type<unknown>> => import('./hours-approval-overview/hours-approval-overview.module')
          .then(m => m.HoursApprovalOverviewModule),
      },
      {
        path: 'hours-approval-overview',
        redirectTo: 'daadwerkelijk-gewerkte-uren',
      },
      {
        path: 'uitbetaling',
        loadChildren: (): Promise<Type<unknown>> => import('./payout/payout.module').then(m => m.PayoutModule),
      },
      {
        path: 'location-payout-approval-overview',
        redirectTo: 'uitbetaling/locatie',
      },
      {
        path: 'payout-approval-overview',
        redirectTo: 'uitbetaling/hoofdkantoor',
      },
      {
        path: 'zoeken',
        loadChildren: (): Promise<Type<unknown>> => import('./search/search.module')
          .then(m => m.SearchPageModule),
        canActivate: [OAuthGuard],
      },
      {
        path: 'search',
        redirectTo: 'zoeken',
      },
      {
        path: 'moeilijke-gevallen',
        canActivate: [OAuthGuard],
        loadChildren: (): Promise<Type<unknown>> => import('./difficulty-details/difficulty-details.module')
          .then(m => m.DifficultyDetailsModule),
      },
      {
        path: 'difficulty',
        redirectTo: 'moeilijke-gevallen',
      },
      {
        path: 'wachtend-op-shift',
        canActivate: [OAuthGuard],
        loadChildren: (): Promise<Type<unknown>> => import('./awaiting-slots/awaiting-slots.module')
          .then(m => m.AwaitingSlotsModule),
      },
      {
        path: 'begeleiders',
        canActivate: [OAuthGuard],
        loadChildren: (): Promise<Type<unknown>> => import('./candidate-search/candidate-search.module')
          .then(m => m.CandidateSearchModule),
      },
      {
        path: 'candidate-search',
        redirectTo: 'begeleiders',
      },
      {
        path: 'dashboard',
        redirectTo: '/home',
      },
    ],
  },
  {
    path: 'login',
    loadChildren: (): Promise<Type<unknown>> => import('./login/login.module')
      .then(m => m.LoginModule),
  },
  {
    path: '**',
    redirectTo: '/home',
  },
];

@NgModule({
  imports: [
    /**
     * Preloading the modules slows down the async http calls after the initial
     * page load. While developing we do a lot of page reloads, so to improve
     * the initial loading speed we disable the preloading when the environment
     * is not production
     */
    RouterModule.forRoot(appRoutes, {
      preloadingStrategy: environment.production ? PreloadAllModules : NoPreloading,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}

