import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { SentryEnvironment } from '@techniek-team/sentry-web';
import { filter, switchMap } from 'rxjs/operators';
import { environment } from '@scheduler-frontend/environments';
import { AssignmentModalRoutingService } from './shared/modals/assignment-modal/assignment-modal-routing.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  constructor(
    private storage: Storage,
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private route: ActivatedRoute,
    //eslint-disable-next-line @typescript-eslint/naming-convention
    private assignmentModalRoutingService: AssignmentModalRoutingService,
  ) {
    this.storage.create();
    this.assignmentModalRoutingService.initRouting();
  }

  /**
   * @inheritDoc
   */
  public ngOnInit(): void {
    this.redirectToBeta();
  }

  public redirectToBeta(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      switchMap(() => this.storage.get('enableBeta').then(enableBeta => [enableBeta, this.route.firstChild])),
    ).subscribe(([enableBeta, rootEvent]) => {
      const route: ActivatedRoute = rootEvent.firstChild ?? rootEvent;
      const hasPleaseNoMoreBeta: boolean = route.snapshot.queryParamMap.has('pleaseNoMoreBeta');
      if (hasPleaseNoMoreBeta) {
        //noinspection JSIgnoredPromiseFromCall
        this.storage.remove('enableBeta');
      }
      if (hasPleaseNoMoreBeta === false && enableBeta !== null) {
        if (environment.environment === SentryEnvironment.PRODUCTION) {
          this.document.location.href = 'https://beta.roostertool.mijnskoleo.nl/';
        } else {
          //eslint-disable-next-line no-console
          console.log('In production you would be redirected to beta version');
        }
      }
    });
  }
}
