import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@scheduler-frontend/environments';
import {
  Assignment,
  AssignmentDetailWithAssignmentHasSlot,
  AssignmentHasSlotDetailed,
  CandidateMinimal,
  LogRecord,
  PayoutWaitingForApproval,
  Slot,
  TransitionLogRecord,
  TransitionOption,
} from '@scheduler-frontend/models';
import { Collection, Resource } from '@techniek-team/api-platform';
import { denormalize, Hydra, TsRange, TsRangeInterface } from '@techniek-team/class-transformer';
import { normalizeNull } from '@techniek-team/common';
import { formatISO } from 'date-fns';
import { Observable, of, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { AssignmentAssignmentHasSlotContract } from '../contract/assignment-has-slot.contract';
import { CandidateMinimalContract } from '../contract/candidate.contract';
import {
  ApplyTransitionRequest,
  AssignmentAssignRequest,
  EditDescriptionRequest,
  MarkAsUrgentRequest,
  RemoveSlotFromAssignmentRequest,
  UpdateAssignmentSelfAssignDataRequest,
  UpdateSlotActualWorkingTimesRequest,
} from './assignment.request';
import {
  ApplyTransitionResponse,
  CreateAssignmentResponse,
  FinalizeAssignmentsResponse,
  GetAssignmentV1Response,
  GetAssignmentV3Response,
  GetExecutedTransitionResponse,
  GetPreviewListResponse,
  GetTransitionResponse,
  RemoveSlotFromAssignmentResponse,
} from './assignment.response';

//eslint-disable-next-line max-lines-per-function
function convertGetAssignmentV1ResponseToV3(response: GetAssignmentV1Response): Resource<GetAssignmentV3Response> {
  return {
    '@id': `${environment.scheduler.iri}/v3/assignments/${response.id}`,
    '@type': 'Assignment',
    'bookingPeriodClosed': response.bookingPeriodClosed,
    'contractType': response.contractType,
    'state': response.state,
    'name': response.name,
    'containsCombined': false,
    'grade': response.grade,
    'gradeCount': response.gradeCount,
    'timePrecision': response.timePrecision,
    'schedule': {
      '@id': `${environment.scheduler.iri}/v3/schedules/${response.schedule.id}`,
      '@type': 'Schedule',
      'name': response.schedule.name,
    },
    'location': (response.location) ? `${environment.scheduler.iri}/v3/locations/${response.location.id}` : undefined,
    'businessService': `${environment.scheduler.iri}/v3/business_services/${response.businessService.id}`,
    'description': response.description,
    'definitiveConfirmationDate': response.definitiveConfirmationDate,
    //eslint-disable-next-line max-lines-per-function
    'assignmentHasSlots': response.assignmentHasSlots.map(hasSlot => ({
      '@id': `${environment.scheduler.iri}/v3/assignment-has-slots/${hasSlot.id}`,
      '@type': 'AssignmentHasSlot',
      'actualTimePeriod': hasSlot.actualTimePeriod,
      'breakTime': hasSlot.breakTime,
      'purpose': hasSlot.purpose,
      'slot': {
        '@id': `${environment.scheduler.iri}/v3/slots/${hasSlot.slot.id}`,
        '@type': 'Slot',
        'schedule': {
          '@id': `${environment.scheduler.iri}/v3/schedules/${response.id}`,
          '@type': 'Schedule',
          'name': response.schedule.name,
        },
        'displayAsCombined': hasSlot.slot.displayAsCombined,
        'isCombined': hasSlot.slot.isCombined,
        'performSkillCheck': hasSlot.slot.performSkillCheck,
        'lesson': (hasSlot.slot.lesson) ? {
          '@id': `${environment.scheduler.iri}/v3/lessons/${hasSlot.slot.lesson.id}`,
          '@type': 'Lesson',
          //eslint-disable-next-line max-len
          'location': (response.location) ? `${environment.scheduler.iri}/v3/locations/${response.location.id}` : undefined,
          'numberOfPupils': hasSlot.slot.lesson.numberOfPupils,
          //eslint-disable-next-line max-len
          'subject': (hasSlot?.slot?.lesson?.subject) ? `${environment.scheduler.iri}/v3/subjects/${hasSlot.slot.lesson.subject.id}` : undefined,
          'level': hasSlot.slot.lesson.level,
          'date': hasSlot.slot.lesson.date,
          'name': hasSlot.slot.lesson.name,
          'isOnline': hasSlot.slot.lesson.isOnline,
          'isBillable': hasSlot.slot.lesson.isBillable,
        } : undefined,
        'timePeriod': hasSlot.slot.timePeriod,
        'role': `${environment.scheduler.iri}/v3/roles/${hasSlot.slot.role.id}`,
        'actions': (hasSlot.slot.actions ?? []).map(action => ({
          '@id': `${environment.scheduler.iri}/v3/actions/${action.id}`,
          '@type': 'Action',
          'description': action.description,
        })),
      },
    } as Resource<AssignmentAssignmentHasSlotContract>)),
    'assignmentHasDocuments': response.assignmentHasDocuments.map(doc => ({
      '@id': `${environment.scheduler.iri}/v3/assignment_has_documents/${doc.id}`,
      '@type': 'AssignmentHasDocuments',
      'document': {
        '@id': `${environment.scheduler.iri}/v3/documents/${doc.document.id}`,
        '@type': 'Document',
        'publicationStart': doc.document.publicationStart,
        'title': doc.document.title,
        'type': doc.document.type,
        'url': doc.document.url,
        'signedUrl': doc.document.signedUrl,
      },
    })),
    'onlineLessonLinks': response.onlineLessonLinks.map(onlineLinks => ({
      links: onlineLinks.links.map(links => ({
        link: links.link,
        type: links.type,
        timePeriod: links.timePeriod,
      })),
      lessonId: onlineLinks.lessonId,
    })),
    'candidate': (response.candidateId) ? {
      '@id': `${environment.scheduler.iri}/v3/candidates/${response.candidateId}`,
      '@type': 'Assignment',
      'fullName': response.candidateName,
    } as Resource<CandidateMinimalContract> : undefined,
    'selfAssignable': response.selfAssignable,
    'allowSelfAssignWhenNew': response.allowSelfAssignWhenNew,
    'minimalGradeSelfAssign': response.minimalGradeSelfAssign,
    'maxTravelDistanceSelfAssign': response.maxTravelDistanceSelfAssign,
    'allowUpdatingSelfAssignFields': response.allowUpdatingSelfAssignFields,
    'canEnableCompensation': response.canEnableCompensation,
    'performCompensation': response.performCompensation,
    'allowManualTravelCompensation': response.allowManualTravelCompensation,
    'restrictAssignmentCompensationAccess': response.restrictAssignmentCompensationAccess,
    'isUrgent': response.isUrgent,
  };
}

export interface AssignmentApiInterface {
  assign(request: AssignmentAssignRequest): Observable<CreateAssignmentResponse[]>;

  removeSlot(
    request: RemoveSlotFromAssignmentRequest,
  ): Observable<RemoveSlotFromAssignmentResponse>;

  getAssignment(
    assignment: string | Assignment,
  ): Observable<AssignmentDetailWithAssignmentHasSlot>;

  editDescription(
    request: EditDescriptionRequest,
  ): Observable<void>;

  updateActualWorkingTimes(
    request: UpdateSlotActualWorkingTimesRequest,
  ): Observable<void>;

  updateSelfAssignSettings(request: UpdateAssignmentSelfAssignDataRequest): Observable<void>;

  getTransitions(assignmentId: string): Observable<TransitionOption[]>;

  applyTransition(request: ApplyTransitionRequest): Observable<ApplyTransitionResponse>;

  getExecutedTransitions(assignment: string | Assignment): (
    params: HttpParams,
    headers?: HttpHeaders,
  ) => Observable<Hydra<LogRecord>>;

  finalizeAssignments(assignmentIds: PayoutWaitingForApproval[]): Observable<FinalizeAssignmentsResponse>;

  getPreviewList(slots: Slot[]): Observable<GetPreviewListResponse[] | undefined>;

  markAsUrgent(request: MarkAsUrgentRequest): Observable<void>;
}

@Injectable({
  providedIn: 'root',
})
export class AssignmentApi implements AssignmentApiInterface {

  constructor(protected httpClient: HttpClient) {
  }

  /**
   * Assigns Slots to a Candidate.
   */
  public assign(request: AssignmentAssignRequest): Observable<CreateAssignmentResponse[]> {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/assignments`;

    if (request.slots[0] instanceof Slot) {
      request.slots = (request.slots as Slot[]).map(slot => slot.getId());
    }

    if (request.candidate instanceof CandidateMinimal) {
      request.candidate = request.candidate.getId();
    }

    return this.httpClient.post<CreateAssignmentResponse[]>(url, {
      candidate: request.candidate,
      slots: request.slots,
      resolveConflicts: request.resolveConflicts,
    });
  }

  /**
   * remove a Single slot from an Assignment that is not yet finalized.
   */
  public removeSlot(
    request: RemoveSlotFromAssignmentRequest,
  ): Observable<RemoveSlotFromAssignmentResponse> {
    if (request.slots[0] instanceof Slot) {
      request.slots = (request.slots as Slot[]).map(slot => slot.getId());
    }
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/assignments/unassign_slots`;

    return this.httpClient.post<RemoveSlotFromAssignmentResponse>(url, request);
  }

  /**
   * Retrieves a detailed version of the Assignment resource for the given parameter.
   */
  public getAssignment(
    assignment: string | Assignment,
  ): Observable<AssignmentDetailWithAssignmentHasSlot> {
    if (assignment instanceof Assignment) {
      assignment = assignment.getId();
    }
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v2/assignments/${assignment}`;
    return this.httpClient.get<GetAssignmentV1Response>(url).pipe(
      map(response => normalizeNull(response)),
      map(response => convertGetAssignmentV1ResponseToV3(response)),
      map(response => denormalize(AssignmentDetailWithAssignmentHasSlot, response)),
    ) as unknown as Observable<AssignmentDetailWithAssignmentHasSlot>;
  }

  /**
   * Edits the Assignments description.
   */
  public editDescription(
    request: EditDescriptionRequest,
  ): Observable<void> {
    if (request.assignment instanceof Assignment) {
      request.assignment = request.assignment.getId();
    }

    //eslint-disable-next-line max-len
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/assignments/${request.assignment}/description`;

    return this.httpClient.post<void>(url, { description: request.description });
  }

  /**
   * Updates the working times in an Assignment Slot.
   */
  public updateActualWorkingTimes(
    request: UpdateSlotActualWorkingTimesRequest,
  ): Observable<void> {
    if (request.assignmentId instanceof Assignment) {
      request.assignmentId = request.assignmentId.getId();
    }
    if (request.assignmentHasSlotId instanceof AssignmentHasSlotDetailed) {
      request.assignmentHasSlotId = request.assignmentHasSlotId.getId();
    }
    //eslint-disable-next-line max-len
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/assignments/${request.assignmentId}/slots/${request.assignmentHasSlotId}`;

    return this.httpClient.post<void>(url, {
      actualStartTime: formatISO(request.time.start),
      actualEndTime: formatISO(request.time.end),
      breakTime: request.breakTime,
    });
  }

  /**
   * Updates the Assignments selfAssign data.
   */
  public updateSelfAssignSettings(request: UpdateAssignmentSelfAssignDataRequest): Observable<void> {
    if (request.assignment instanceof Assignment) {
      request.assignment = request.assignment.getId();
    }
    //eslint-disable-next-line max-len
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/assignments/${request.assignment}/self-assign`;

    return this.httpClient.patch<void>(url, request.selfAssign);
  }

  /**
   * Applies a predefined transition on the provided Assignment.
   */
  public getTransitions(assignment: string | Assignment): Observable<TransitionOption[]> {
    if (assignment instanceof Assignment) {
      assignment = assignment.getId();
    }
    //eslint-disable-next-line max-len
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/assignments/${assignment}/transitions/available`;

    return this.httpClient.get<GetTransitionResponse>(url).pipe(
      map(response => denormalize(TransitionOption, response.transitions)),
    );
  }

  /**
   * return a log of all previously executed transitions.
   */
  public getExecutedTransitions(assignment: string | Assignment): (
    params: HttpParams,
    headers?: HttpHeaders,
  ) => Observable<Hydra<LogRecord>> {
    if (assignment instanceof Assignment) {
      assignment = assignment.getId();
    }
    return (params: HttpParams, headers?: HttpHeaders): Observable<Hydra<LogRecord>> => {
      const client: HttpClient = this.httpClient;
      //eslint-disable-next-line max-len
      const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/assignments/${assignment}/executed_transitions`;
      return client.get<Collection<GetExecutedTransitionResponse>>(url, { params: params, headers: headers })
        .pipe(
          map(response => denormalize(TransitionLogRecord, response) as unknown as Hydra<LogRecord>),
        );
    };
  }

  /**
   * Applies a predefined transition on the provided Assignment.
   */
  public applyTransition(request: ApplyTransitionRequest): Observable<ApplyTransitionResponse> {
    if (request.assignment instanceof Assignment) {
      request.assignment = request.assignment.getId();
    }
    //eslint-disable-next-line max-len
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/assignments/${request.assignment}/transitions`;

    return this.httpClient.post<ApplyTransitionResponse>(url, {
      transitionName: request.transition,
    });
  }

  /**
   * Finalizes the Assignment.
   */
  public finalizeAssignments(assignmentIds: PayoutWaitingForApproval[]): Observable<FinalizeAssignmentsResponse> {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/assignments/finalized`;
    return this.httpClient.post<FinalizeAssignmentsResponse>(url, assignmentIds.map(item => item.getId()));
  }

  /**
   * Retrieves the Assignment preview list.
   */
  public getPreviewList(slots: Slot[]): Observable<GetPreviewListResponse[] | undefined> {
    if (slots.length === 0) {
      return of(undefined);
    }

    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/assignments/preview-list`;
    let params: HttpParams = new HttpParams();

    slots.forEach((slot, index) => {
      params = params.append('slots[' + index + ']', slot.getId());
    });

    return this.httpClient.get<{ assignmentPreviewList: GetPreviewListResponse<TsRangeInterface>[] }>(
      url,
      { params: params },
    ).pipe(
      map(response => response.assignmentPreviewList),
      map(response => response.map(item => {
        return {
          ...item,
          slots: item.slots.map(slot => ({ ...slot, timePeriod: denormalize(TsRange, slot.timePeriod) })),
        };
      })),
    );
  }

  public markAsUrgent(request: MarkAsUrgentRequest): Observable<void> {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/mark-as-urgent`;
    return this.httpClient.post<void>(url, request);
  }
}
