import { SentryEnvironment } from '@techniek-team/sentry-web';
import { Environment } from './environment.interface';
import { TtFeatureFlagsEnvironment } from './tt-feature-flags-environment.enum';

export const environment: Environment = {
  production: true,
  debug: false,
  environment: SentryEnvironment.PRODUCTION,
  release: '<<RELEASE>>',
  scheduler: {
    iri: '/api',
    url: 'https://roostertool.mijnskoleo.nl',
  },
  perza: {
    iri: '/api',
    url: 'https://perza.lyceo.nl',
  },
  ssoConfig: {
    clientId: 'b0df8b65-bd1d-46d5-99d0-04fc534d41e3',
    profileBaseUrl: 'https://sso.lyceo.nl/api/v1/profile',
    ssoBaseUrl: 'https://sso.lyceo.nl',
    baseUrl: 'https://roostertool.mijnskoleo.nl/',
    redirectUri: '<<TT_REDIRECT_URL>>',
  },
  gitlabFeatureFlags: {
    url: 'https://roostertool.mijnskoleo.nl/api/features',
    environment: TtFeatureFlagsEnvironment.PRODUCTION,
  },
};
