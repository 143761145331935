import { Expose, Transform } from 'class-transformer';
import { parseISO } from 'date-fns';
import { parseISODuration } from '@scheduler-frontend/common';

export class Allotment {

  @Transform(({ value }) => parseISO(value), { toClassOnly: true })
  @Expose() public isoWeek!: Date;

  @Transform(({ value }) => parseISODuration(value), { toClassOnly: true })
  @Expose() public preferredWorkingTime!: Duration;

  @Transform(({ value }) => parseISODuration(value), { toClassOnly: true })
  @Expose() public actualWorkingTime!: Duration;
}
