import { DeclineReasonTypeEnum } from '@scheduler-frontend/enums';
import { Expose } from 'class-transformer';

export class DeclineReason {
  @Expose() public id!: string;

  @Expose() public description!: string;

  @Expose() public declineReasonType!: DeclineReasonTypeEnum;
}
