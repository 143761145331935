<ng-container *ngIf="assignmentModalService.assignment$ | async; let assignment">
  <ion-list class="candidate">
    <ion-list-header>
      <ion-label>Kandidaat</ion-label>
    </ion-list-header>
    <ion-item lines="none">
      <ion-label>{{ (assignmentModalService.candidate$ | async)?.fullName }}</ion-label>
    </ion-item>
    <ion-item lines="none">
      <ion-label>{{ (assignmentModalService.candidate$ | async)?.phoneNumber }}</ion-label>
    </ion-item>
    <ion-item lines="none">
      <ion-label>{{ (assignmentModalService.candidate$ | async)?.emailAddress }}</ion-label>
    </ion-item>
  </ion-list>
  <app-self-assign-form *ngIf="showSelfAssign$ | async"
                        [assignment]="assignment"></app-self-assign-form>
  <app-urgent-form [assignment]="assignment"></app-urgent-form>
  <app-assignment-has-slot-table [candidate]="assignmentModalService.candidate$ | async"
                                 [assignment]="assignment">
  </app-assignment-has-slot-table>
</ng-container>
