import { LevelEnum } from '@scheduler-frontend/enums';
import { TsRange } from '@techniek-team/class-transformer';
import { EagerLoaded } from '@techniek-team/fetch';
import { FetchObservable } from '@techniek-team/rxjs';
import { Expose, Type } from 'class-transformer';
import { Observable, of } from 'rxjs';
import { Role } from '../../role/role.model';
import { SlotDetailed } from '../../slot/slot-detailed.model';
import { SubjectModel } from '../../subject/subject.model';
import { AssignmentHasSlotDetailed } from './assignment-has-slot-detailed.model';

//eslint-disable-next-line max-len
export class AssignmentHasSlotDetailedWithSlot<Lazy = FetchObservable<unknown>> extends AssignmentHasSlotDetailed<Lazy> {

  /**
   * @inheritDoc
   */
  public override readonly className: string = 'AssignmentHasSlotDetailedWithSlot';

  /**
   * The slot or shift a candidate needs to preform.
   *
   * Note: this property is only available when this resource is retrieved
   * through the {@see AssignmentApi}. In other cases look at
   * the parent of this resource
   */
  @Type(() => SlotDetailed)
  @Expose() public slot!: SlotDetailed<Lazy>;

  /**
   * Returns either the actualTimePeriod or the slot timePeriod when the actual
   * isn't available.
   */
  public get realTimePeriod(): TsRange {
    if (this.actualTimePeriod) {
      return this.actualTimePeriod;
    }
    return this.slot.timePeriod;
  }

  /**
   * Returns the start date of this assignment.
   */
  public get startDate(): Date {
    return this.slot.timePeriod.start;
  }

  /**
   * Returns the end date of this assignment.
   */
  public get endDate(): Date {
    return this.slot.timePeriod.start;
  }

  /**
   * Returns the subject of this assignment.
   */
  public get subject(): Observable<SubjectModel>{
    if (this.slot?.lesson.subject instanceof FetchObservable) {
      return this.slot?.lesson.subject;
    }
    return of(this.slot?.lesson.subject as SubjectModel);
  }

  /**
   * Returns the level of this assignment.
   */
  public get level(): LevelEnum | undefined {
    return this.slot?.lesson?.level;
  }

  /**
   * Returns the role of this assignment.
   */
  public get role(): Observable<Role> | FetchObservable<Role<unknown>> {
    if (this.slot?.role instanceof FetchObservable) {
      return this.slot?.role;
    }
    return of(this.slot?.role) as unknown as Observable<Role>;
  }

  public async fetchAll(): Promise<AssignmentHasSlotDetailedWithSlot<EagerLoaded>> {
    //eslint-disable-next-line max-len
    const assignment: AssignmentHasSlotDetailedWithSlot<EagerLoaded> = this as AssignmentHasSlotDetailedWithSlot<EagerLoaded>;

    this.slot = await this.slot.fetchAll();
    return assignment;
  }
}
