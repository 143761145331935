<tt-modal (cancel)="close()">
  <tt-modal-title slot="start"
                  color="tertiary">
    <ng-container *ngIf="assignment$ | async as assignment; else loadingTitle">
      {{ assignment.businessService.name }}
    </ng-container>
    <ng-template #loadingTitle>
      <ion-skeleton-text animated="true" style="width: 60%;"></ion-skeleton-text>
    </ng-template>
  </tt-modal-title>

  <ion-list slot="start">
    <ion-item [button]="true"
              lines="none"
              [class.selected]="currentSegment === AssignmentModalSegments.OVERVIEW"
              (click)="segmentChanged(AssignmentModalSegments.OVERVIEW)">
      <fa-icon slot="start" [icon]="['far', 'check-to-slot']"></fa-icon>
      <ion-label>Algemeen</ion-label>
    </ion-item>
    <ion-item [button]="true"
              lines="none"
              [class.selected]="currentSegment === AssignmentModalSegments.PAYOUT"
              (click)="segmentChanged(AssignmentModalSegments.PAYOUT)">
      <fa-icon slot="start"
               [icon]="['fad', 'money-check-dollar']"></fa-icon>
      <ion-label>Uitbetaling</ion-label>
    </ion-item>
    <ion-item [button]="true"
              lines="none"
              [class.selected]="currentSegment === AssignmentModalSegments.HISTORY"
              (click)="segmentChanged(AssignmentModalSegments.HISTORY)">
      <fa-icon slot="start"
               [icon]="['fad', 'cabinet-filing']"></fa-icon>
      <ion-label>Historie</ion-label>
    </ion-item>
  </ion-list>

  <div slot="start">
    <ng-container *ngIf="(assignment$ | async) as assignment">
      <ion-item *ngIf="assignment.state === AssignmentStateEnum.PROVISIONALLY_CONFIRMED"
                class="status-explanation"
                lines="none">
        <ion-label class="ion-text-wrap">Deze opdracht zal op {{assignment.definitiveConfirmationDate | date:'dd-MM-yyyy'}} definitief bevestigd worden.</ion-label>
      </ion-item>
    </ng-container>
  </div>

  <ion-select interface="popover"
              label="Status"
              placeholder="Status"
              slot="start"
              [formControl]="assignmentStatusControl">
    <ion-select-option *ngIf="(assignment$ | async) as assignment"
                       [value]="assignment.state"
                       disabled="true">
      {{ AssignmentStateDisplayValues[assignment.state] | capitalize }}
    </ion-select-option>
    <ng-container *ngIf="availableTransition$ | async as availableTransitions">
      <ion-select-option *ngFor="let transition of availableTransitions"
                         [value]="transition.name">
        {{ TransitionToMessage[transition.name] | capitalize }}
      </ion-select-option>
    </ng-container>
  </ion-select>

  <app-edit-assignment-description slot="start"></app-edit-assignment-description>

  <ng-container *ngIf="assignment$ | async">
    <ng-container [ngSwitch]="currentSegment">
      <ng-container *ngSwitchDefault>
        <tt-modal-title>Algemeen</tt-modal-title>
        <app-assignment-general></app-assignment-general>
      </ng-container>
      <ng-container *ngSwitchCase="AssignmentModalSegments.PAYOUT">
        <tt-modal-title>Uitbetaling</tt-modal-title>
        <app-payout-details></app-payout-details>
      </ng-container>
      <ng-container *ngSwitchCase="AssignmentModalSegments.HISTORY">
        <tt-modal-title>Historie</tt-modal-title>
        <app-assignment-history></app-assignment-history>
      </ng-container>
    </ng-container>
  </ng-container>

  <ion-button (click)="close()"
              slot="action"
              color="lyceo-green"
              fill="clear">Sluiten
  </ion-button>
</tt-modal>
