import { AssignmentHasSlotPurposeEnum } from '@scheduler-frontend/enums';
import { JsonLd, TsRange } from '@techniek-team/class-transformer';
import { FetchObservable } from '@techniek-team/rxjs';
import { Expose, Type } from 'class-transformer';

/**
 * The Detailed version of the AssignmentHasSlot resource from Scheduler-api.
 */
export class AssignmentHasSlotDetailed<Lazy = FetchObservable<unknown>> extends JsonLd {

  /**
   * @inheritDoc
   */
  public override readonly className: string = 'AssignmentHasSlotDetailed';

  /**
   * Time in minutes of how long the break was.
   */
  @Expose() public breakTime?: number;

  /**
   * The actual times that the candidate has worked on the slots, which
   * can be different from the actually planned hours.
   */
  @Type(() => TsRange)
  @Expose() public actualTimePeriod?: TsRange;

  @Type(() => Date)
  @Expose() public actualTimePeriodUpdatedAt?: Date | null;

  /**
   * Enum containing the purpose of the assignment has slot
   */
  @Expose() public purpose!: AssignmentHasSlotPurposeEnum;

  /**
   * @inheritDoc
   */
  public override toString(): string {
    return `${this.className}: ${this.getId()}`;
  }
}
