import { JsonLd, TsRange } from '@techniek-team/class-transformer';
import { Expose, Type } from 'class-transformer';
import { LocationMinimal } from '../../location/location-minimal.model';
import { RoleMinimal } from '../../role/role-minimal.model';

export class PayoutWaitingForApprovalSlot extends JsonLd {

  @Type(() => LocationMinimal)
  @Expose() public location!: LocationMinimal;

  @Type(() => TsRange)
  @Expose() public timePeriod!: TsRange;

  @Type(() => RoleMinimal)
  @Expose() public role!: RoleMinimal;

}
