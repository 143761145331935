import { Expose } from 'class-transformer';

export class Remarks {
  @Expose() public internal?: string;

  @Expose() public external?: string;

  @Expose() public examTraining?: string;

  @Expose() public tutoring?: string;

  @Expose() public secondment?: string;
}
