import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@scheduler-frontend/environments';
import { DeclineReason } from '@scheduler-frontend/models';
import { denormalize } from '@techniek-team/class-transformer';
import { CacheService } from '@techniek-team/services';
import { hoursToMilliseconds } from 'date-fns';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GetDeclineReasonResponse } from './decline-reason.response';

export interface DeclineReasonApiInterface {
  getDeclineReasons(): Observable<DeclineReason[]>;
}

@Injectable({
  providedIn: 'root',
})
export class DeclineReasonApi implements DeclineReasonApiInterface {

  constructor(
    private httpClient: HttpClient,
    private cacheService: CacheService,
  ) {
  }

  /**
   * Get the decline reasons from the backend.
   */
  public getDeclineReasons(): Observable<DeclineReason[]> {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/decline-reasons`;

    const call: Observable<GetDeclineReasonResponse[]> = this.httpClient.get<GetDeclineReasonResponse[]>(url).pipe(
      map(response => denormalize(DeclineReason, response)),
    );

    return this.cacheService.create(
      'DeclineReason',
      call,
      { refreshInterval: hoursToMilliseconds(24) },
    );
  }
}
