import { LazyJsonLd as JsonLd } from '@techniek-team/fetch';
import { Expose, Type } from 'class-transformer';
import { ProductType } from '../product-type/product-type.model';

/**
 * The Bare minimal version of the Schedule Resource.
 */
export class Product extends JsonLd {

  /**
   * @inheritDoc
   */
  public override readonly className: string = 'Product';

  /**
   * Name of this Schedule
   */
  @Expose() public name!: string;

  /**
   * Product type of the product.
   */
  @Type(() => ProductType)
  @Expose() public productType!: ProductType;

  /**
   * @inheritDoc
   */
  public override toString(): string {
    return this.name;
  }
}
