import { EagerLoaded, Fetch, Fetched } from '@techniek-team/fetch';
import { FetchObservable } from '@techniek-team/rxjs';
import { Expose } from 'class-transformer';
import { firstValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { BusinessService } from '../business/business-service.model';
import { RoleMinimal } from './role-minimal.model';

/**
 * standard version of the Role resource from Scheduler-api
 */
export class Role<Lazy = FetchObservable<unknown>> extends RoleMinimal {

  /**
   * @inheritDoc
   */
  public override readonly className: string = 'Role';

  /**
   * If where sorting roles we should take this sorting order into account.
   * Sorting is mostly done within a specific businessService
   */
  @Expose() public sortingPriority?: number;

  /**
   * If true a break time is allowed in the shift.
   *
   * Only available for BUSINESS_USER_ROLE, therefore it has a default value
   */
  @Expose() public allowBreakTime: boolean = false;

  /**
   * The service where the role belongs to.
   */
  @Fetch(() => BusinessService)
  @Expose() public businessService?: Fetched<Lazy, BusinessService<Lazy>>;

  //eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    super();
  }

  public override async fetchAll<T extends typeof Role>(): Promise<Role<EagerLoaded>>{
    //eslint-disable-next-line max-len
    let businessService: FetchObservable<BusinessService<Lazy>> | BusinessService<Lazy> | undefined = this.businessService;

    if (businessService instanceof FetchObservable) {
      businessService = await firstValueFrom(businessService.pipe(take(1))).then(item => item.fetchAll());
    }

    const role: Role<EagerLoaded> = this as Role<EagerLoaded>;
    role.businessService = businessService as BusinessService<EagerLoaded>;
    return role as Role<EagerLoaded>;
  }

  /**
   * @inheritDoc
   */
  public override toString(): string {
    return this.name;
  }
}
