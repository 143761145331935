import { Component, Input } from '@angular/core';
import { AssignmentStateDisplayValues } from '@scheduler-frontend/enums';
import { TransitionLogRecord } from '@scheduler-frontend/models';

@Component({
  selector: 'app-transition-log-record',
  templateUrl: './transition-log-record.component.html',
  styleUrls: ['./transition-log-record.component.scss'],
})
export class TransitionLogRecordComponent {

  public readonly AssignmentStateDisplayValues: typeof AssignmentStateDisplayValues = AssignmentStateDisplayValues;

  @Input() public logRecord!: TransitionLogRecord;
}
