/* eslint-disable @typescript-eslint/no-explicit-any */
import { from, OperatorFunction, pipe } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { EagerLoaded } from '../decorators/fetch.decorator';
import { JsonLd, Hydra } from '@techniek-team/class-transformer';
import { LazyJsonLd } from '../lazy-json-ld.model';

/**
 * Filter items emitted by the source Observable by only emitting those that
 * are defined. Meaning that all value that aren't null or undefined are emitted.
 *
 * It's also possible to uses this method in conjunction with {@see CombineLatest }
 * by giving an array of the index numbers to check
 *
 * @example
 * ```typescript
 *  const a: MyModel<EagerLoaded> = of(
 *    serialize(MyModel, { address: '/api/v3/ade143d3-46d1-418b-a331-c5f90716e070'})
 *  ).pipe(
 *    eagerLoad<MyModel<EagerLoaded>(),
 *  );
 *
 *  const a = combineLatest([
 *   of(undefined),
 *   of(serialize(MyModel, { address: '/api/v3/ade143d3-46d1-418b-a331-c5f90716e070'})),
 *  ]).pipe(
 *    isDefined([1]),
 *  );
 * ```
 */
export function eagerLoad<A>(
  paramsToFetch?: number[],
): OperatorFunction<any | any[] | Map<string, any> | Hydra<any>, A> {
  return pipe(
    switchMap((value: any | Hydra<any> | Map<string, any> | any[]) => {
      if (Array.isArray(value) && paramsToFetch) {
        const promises: Promise<A | Hydra<any> | Map<string, A> | A[]>[] = [];
        for (let [index, item] of value.entries()) {
          if (paramsToFetch.includes(index)) {
            promises.push(fetchAll<any, A>(item));
            continue;
          }
          promises.push(Promise.resolve(item));
        }

        return from(Promise.all(promises) as unknown as Promise<A>);
      }

      return from(fetchAll(value) as Promise<A>);
    }),
  );
}

/**
 * Internal used method of {@see eagerLoad} it create a combined promise of all
 * the {@see JsonLd.fetchAll} methods base on the format your'e resource or collection
 * is stored in.
 */
function fetchAll<T extends LazyJsonLd, A extends T & LazyJsonLd<EagerLoaded>>(
  value: T | Hydra<T> | Map<string, T> | T[],
): Promise<A | Hydra<A> | Map<string, A> | A[]> {
  if (value instanceof Hydra) {
    const promises: Promise<T>[] = value.collection.map(item => item.fetchAll() as Promise<T>);
    return Promise.all(promises).then(eagerLoaded => {
      value.collection = eagerLoaded;
      return value;
    }) as Promise<Hydra<A>>;
  }

  if (Array.isArray(value)) {
    const promises: Promise<T>[] = value.map(item => item.fetchAll() as Promise<T>);
    return Promise.all(promises) as unknown as Promise<A[]>;
  }

  if (value instanceof Map) {
    const promises: Promise<[unknown, T]>[] = [...value.entries()]
      .map(([key, item]) => (item.fetchAll() as Promise<T>).then(eagerLoaded => [key, eagerLoaded]));
    return Promise.all(promises).then(tuple => new Map(tuple)) as Promise<Map<string, A>>;
  }
  if (value instanceof JsonLd) {
    return value.fetchAll() as unknown as Promise<A>;
  }
  return Promise.resolve(value) as unknown as Promise<A>;
}
