import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@scheduler-frontend/environments';
import {
  Candidate,
  CandidateMinimal,
  CandidateSearchResult,
  LocationModel,
  Slot,
  SlotStatistics,
} from '@scheduler-frontend/models';
import { Collection, Resource } from '@techniek-team/api-platform';
import { denormalize } from '@techniek-team/class-transformer';
import { differenceInMinutes } from 'date-fns';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SlotContract } from '../contract/slot.contract';
import { CandidateGetMatchingSlotsRequest, CandidateRejectionRequest } from './candidate.request';
import {
  CandidateFilter,
  GetByLocationResponseV1,
  GetByLocationResponseV3,
  GetCandidateDetailsResponse,
  GetCandidatesResponseV1,
  GetCandidatesResponseV3,
  GetHistoryResponseV1,
  GetHistoryResponseV3,
  GetMatchingSlotStatisticsResponseV1,
  GetMatchingSlotStatisticsResponseV3,
  GetPersonalRemarksResponse,
  SearchCandidatesBySlotsResponseV1,
  SearchCandidatesBySlotsResponseV3,
} from './candidate.response';

function convertGetMatchingSlotStatisticsResponseV1toV3(
  response: GetMatchingSlotStatisticsResponseV1,
): Resource<GetMatchingSlotStatisticsResponseV3> {
  return {
    '@id': `${environment.scheduler.iri}/v3/slot-statistics/${response.id}`,
    '@type': 'SlotStatistics',
    'criteriaMatch': response.criteriaMatch,
    'availability': {
      available: response.availability.available,
      unavailable: response.availability.unavailable,
      conflict: response.availability.conflict,
      total: response.availability.total,
    },
    'distance': response.distance,
    'conflictingSlots': response.conflictingSlots.map(slot => ({
      '@id': `${environment.scheduler.iri}/v3/slots/${slot.id}`,
      '@type': 'Slot',
      'schedule': {
        '@id': `${environment.scheduler.iri}/v3/schedules/${slot.scheduleId}`,
        '@type': 'Schedule',
        'name': slot.schedule.name,
      },
      'lesson': {
        '@id': `${environment.scheduler.iri}/v3/lessons/${slot.lesson.id}`,
        '@type': 'Lesson',
        'location': (slot.location) ? `${environment.scheduler.iri}/v3/locations/${slot.location.id}` : undefined,
      },
      'timePeriod': slot.timePeriod,
      'role': `${environment.scheduler.iri}/v3/roles/${slot.role}`,
    } as Resource<SlotContract>)),
    'conflictsResolvable': response.conflictsResolvable,
  };
}

function convertGetCandidatesResponseV1ToV3(response: GetCandidatesResponseV1[]): GetCandidatesResponseV3[];
function convertGetCandidatesResponseV1ToV3(response: GetByLocationResponseV1[]): GetByLocationResponseV3[];
function convertGetCandidatesResponseV1ToV3(
  response: GetCandidatesResponseV1[] | GetByLocationResponseV1[],
): GetCandidatesResponseV3[] | GetByLocationResponseV3[] {
  return response.map(item => ({
    '@id': `${environment.scheduler.iri}/v3/candidates/${item.id}`,
    'gender': item.gender,
    'phoneNumber': item.phoneNumber,
    'emailAddress': item.emailAddress,
    'grade': item.grade,
    'fullName': item.fullName,
    'pictureUrl': (item as GetCandidatesResponseV1)?.pictureUrl,
    'isMain': (item as GetByLocationResponseV1)?.isMain,
  } as GetCandidatesResponseV3 | GetByLocationResponseV3));
}

export function convertSearchCandidatesBySlotsResponseV1toV3(
  response: SearchCandidatesBySlotsResponseV1,
  requestedMinutes: number,
): SearchCandidatesBySlotsResponseV3 {
  return {
    candidates: response.candidates.map( item => ({
      '@id': `${environment.scheduler.iri}/v3/candidates/${item.candidate.id}`,
      'gender': item.gender,
      'phoneNumber': item.phoneNumber,
      'emailAddress': item.emailAddress,
      'grade': item.grade,
      'fullName': item.candidate.fullName,
      'pictureUrl': undefined,
      'distance': item.distance,
      'availableMinutes': item.availableMinutes,
      'unavailableMinutes': item.unavailableMinutes,
      'conflictingMinutes': item.conflictingMinutes,
      'isMain': item.isMain,
      'avgTravelBurden': item.avgTravelBurden,
      'avgAvailability': item.avgAvailability,
      'avgQuality': item.avgQuality,
      'avgSkillEfficiency': item.avgSkillEfficiency,
      'avgWaitingTimeSinceHired': item.avgWaitingTimeSinceHired,
      'avgWaitingTimeSinceLastAssignment': item.avgWaitingTimeSinceLastAssignment,
      'avgTotal': item.avgTotal,
      'travelTime': item.travelTime,
      'travelMode': item.travelMode,
      'allotments': item.allotments,
      'requestedMinutes': requestedMinutes,
    })),
    isLimited: response.isLimited,
  };
}

//eslint-disable-next-line max-lines-per-function
export function convertGetHistoryResponseV1ToV3(
  response: GetHistoryResponseV1[],
): GetHistoryResponseV3[] {
  //eslint-disable-next-line max-lines-per-function
  return response.map(item => ({
    '@id': `${environment.scheduler.iri}/v2/assignments/${item.id}`,
    'grade': item?.grade,
    'gradeCount': item?.gradeCount,
    // nah no need to return the name of a assignment is not as if we used it everywhere!. sigh
    'name': item.assignmentHasSlots[0].slot.schedule.name + item.assignmentHasSlots[0].slot.location?.name,
    'description': item?.description,
    'containsCombined': false,
    'contractType': item.contractType,
    'state': item.state,
    'bookingPeriodClosed': item.bookingPeriodClosed,
    'candidate': {
      '@id': `${environment.scheduler.iri}/v1/candidates/${item.candidateId}`,
      '@type': 'Candidate',
      'fullName': item.candidateName,
    },
    'timePrecision': undefined,
    'definitiveConfirmationDate': undefined,
    'schedule': {
      '@id': `${environment.scheduler.iri}/v1/schedules/${item.slots[0].scheduleId}`,
      '@type': 'Schedule',
      'name': item.slots[0].schedule.name,
    },
    'location': (item.assignmentHasSlots[0].slot.location) ? item.assignmentHasSlots[0].slot.location.id : undefined,
    'businessService': item.assignmentHasSlots[0].slot.role.businessService.id,
    'assignmentHasSlots': item.assignmentHasSlots.map(hasSlot => ({
      '@id': `${environment.scheduler.iri}/v1/assignment-has-slots/${hasSlot.id}`,
      '@type': 'AssignmentHasSlot',
      'purpose': hasSlot.purpose,
      'actualTimePeriod': hasSlot.slot.timePeriod,
      'slot': {
        '@id': `${environment.scheduler.iri}/v1/slots/${hasSlot.slot.id}`,
        '@type': 'Slot',
        'actions': undefined,
        'lesson': (hasSlot.slot.lesson) ? {
          '@id': `${environment.scheduler.iri}/v3/lessons/${hasSlot.slot.schedule.id}`,
          '@type': 'Lesson',
          //eslint-disable-next-line max-len
          'location': (hasSlot.slot.location) ? `${environment.scheduler.iri}/v3/locations/${hasSlot.slot.location.id}` : '' as string,
          'numberOfPupils': hasSlot.slot.lesson.numberOfPupils,
          'subject': undefined,
          'level': undefined,
          'date': hasSlot.slot.lesson.date,
          'name': hasSlot.slot.lesson.name,
          'isBillable': hasSlot.slot.lesson.isBillable,
          'isOnline': hasSlot.slot.lesson.isOnline,
        } : undefined,
        'timePeriod': hasSlot.slot.timePeriod,
        'role': `${environment.scheduler.iri}/v3/roles/${hasSlot.slot.role.id}`,
        'displayAsCombined': hasSlot.slot.displayAsCombined,
        'isCombined': hasSlot.slot.isCombined,
        'performSkillCheck': false,
        'schedule': {
          '@id': `${environment.scheduler.iri}/v3/schedules/${hasSlot.slot.schedule.id}`,
          '@type': 'Schedule',
          'name': hasSlot.slot.schedule.name,
        },
      },
    })),
    'assignmentHasDocuments': [],
    'onlineLessonLinks': [],

  }));
}

export interface CandidateApiInterface {
  getCandidates(query?: string, limit?: number): Observable<Candidate[]>;

  getCandidate(candidate: string | CandidateMinimal): Observable<Resource<GetCandidateDetailsResponse>>;

  getHistory(candidateId: string): Observable<GetHistoryResponseV3[]>;
  getCandidatePersonalRemarks(candidate: string|CandidateMinimal): Observable<Collection<GetPersonalRemarksResponse>>;
  postCandidatePersonalRemarks(candidate: string|CandidateMinimal, remark: string): Observable<void>;
  searchCandidatesBySlots(
    slots: Slot[],
    filterFields?: CandidateFilter,
    searchInput?: string,
    sortBy?: string,
    limit?: number,
  ): Observable<CandidateSearchResult>;

  getByLocation(
    location: string | LocationModel,
    filterFields?: CandidateFilter,
  ): Observable<Candidate[]>;

  getMatchingSlotsStatistics(
    request: CandidateGetMatchingSlotsRequest,
  ): Observable<SlotStatistics[]>;

  postCandidateRejection(
    request: CandidateRejectionRequest,
  ): Observable<void>;
}

@Injectable({
  providedIn: 'root',
})
export class CandidateApi implements CandidateApiInterface {

  private readonly sortByValues: Record<string, string> = {
    SORT_BY_NAME: 'name',
    SORT_BY_DISTANCE: 'distance',
    SORT_BY_GRADE: 'grade',
    SORT_BY_RANKING: 'ranking',
    SORT_BY_AVAILABILITY: 'availability',
  };

  constructor(
    private httpClient: HttpClient,
  ) {
  }

  /**
   * Get the candidates that match the given search query string.
   */
  public getCandidates(query?: string, limit: number = 200): Observable<Candidate[]> {
    let url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/candidates`;
    let params: HttpParams = new HttpParams();

    if (!!(query)) {
      params = params.set('query', query);
    }

    params = params.set('limit', limit.toString());

    return this.httpClient.get<GetCandidatesResponseV1[]>(url, { params: params }).pipe(
      map(response => convertGetCandidatesResponseV1ToV3(response)),
      map(response => denormalize(Candidate, response)),
    );
  }

  /**
   * Get a candidate with a large set of detailed information, using the known
   * candidate id.
   */
  public getCandidate(candidate: string | CandidateMinimal): Observable<Resource<GetCandidateDetailsResponse>> {
    const id: string = (typeof candidate === 'string') ? candidate : candidate.getId();
    //eslint-disable-next-line max-len
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/candidates/${id}`;

    return this.httpClient.get<Resource<GetCandidateDetailsResponse>>(url);
  }

  /**
   * Get the history of a given candidate.
   */
  public getHistory(candidateId: string): Observable<GetHistoryResponseV3[]> {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/candidates/${candidateId}/history`;

    return this.httpClient.get<GetHistoryResponseV1[]>(url).pipe(
      map(response => convertGetHistoryResponseV1ToV3(response)),
    );
  }

  public getCandidatePersonalRemarks(
    candidate: string|CandidateMinimal,
  ): Observable<Collection<GetPersonalRemarksResponse>> {
    const id: string = (typeof candidate === 'string') ? candidate : candidate.getId();
    const url: string = `${environment.perza.url}${environment.perza.iri}/people/${id}/remarks`;

    return this.httpClient.get<Collection<GetPersonalRemarksResponse>>(url);
  }

  /**
   * Post a remark about a person to Perza.
   *
   * The Candidates used in the scheduler also exists as person resource in Perza
   * But the Iri would differ because it being a different api. Because of that
   * we use they id her and prefix it so create a valid iri for Perza
   */
  public postCandidatePersonalRemarks(candidate: string|CandidateMinimal, remark: string): Observable<void> {
    const id: string = (typeof candidate === 'string') ? candidate : candidate.getId();
    const url: string = `${environment.perza.url}${environment.perza.iri}/personal_remarks`;

    return this.httpClient.post<void>(url, {
      remark: remark,
      person: '/api/people/' + id,
    });
  }

  /**
   * Search for candidates that match the provided slots and other constraining
   * options.
   */
  public searchCandidatesBySlots(
    slots: Slot[],
    filterFields?: CandidateFilter,
    searchInput?: string,
    sortBy?: string,
    limit: number = 200,
  ): Observable<CandidateSearchResult> {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/criteria/matches-slots`;
    let params: HttpParams = this.createParamsFromFilterFields(filterFields);
    let requestedMinutes: number | null = null;

    slots.forEach((slot, index) => {
      params = params.append('slots[' + index + ']', slot.getId());

      requestedMinutes = (requestedMinutes ?? 0)
        + differenceInMinutes(slot.timePeriod.end as Date, slot.timePeriod.start as Date);
    });

    if (searchInput && searchInput.length >= 2) {
      params = params.append('searchQuery', searchInput);
    }
    if (sortBy) {
      params = params.append('orderBy', this.sortByValues[sortBy]);
    }

    params = params.append('limit', limit.toString());

    return this.httpClient.get<SearchCandidatesBySlotsResponseV1>(url, { params: params }).pipe(
      map(response => convertSearchCandidatesBySlotsResponseV1toV3(response, (requestedMinutes ?? 0))),
      map(response => denormalize(CandidateSearchResult, response)),
    );
  }

  /**
   * Get candidates based on a location and other filter criteria.
   */
  public getByLocation(
    location: string | LocationModel,
    filterFields?: CandidateFilter,
  ): Observable<Candidate[]> {
    if (location instanceof LocationModel) {
      location = location.getId();
    }
    //eslint-disable-next-line max-len
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/candidates/by-location/${location}`;
    const params: HttpParams = this.createParamsFromFilterFields(filterFields);

    return this.httpClient.get<GetByLocationResponseV1[]>(url, { params: params }).pipe(
      map(response => convertGetCandidatesResponseV1ToV3(response)),
      map(response => denormalize(Candidate, response)),
    );
  }

  // todo is this the correct place for this endpoint?
  /**
   * Find statistics that match the given candidate id and list of slots.
   */
  public getMatchingSlotsStatistics(
    request: CandidateGetMatchingSlotsRequest,
  ): Observable<SlotStatistics[]> {
    //eslint-disable-next-line max-len
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/criteria/matches-candidate-with-slots`;

    if (request.slots[0] instanceof Slot) {
      request.slots = (request.slots as Slot[]).map(slot => slot.getId());
    }

    return this.httpClient.post<GetMatchingSlotStatisticsResponseV1[]>(url, {
      candidateId: request.candidateId,
      slots: request.slots,
    }).pipe(
      map(response => response.map(item => convertGetMatchingSlotStatisticsResponseV1toV3(item))),
      map(response => denormalize(SlotStatistics, response)),
    );
  }

  /**
   * Post the slots and rejection reason for the candidate.
   */
  public postCandidateRejection(
    request: CandidateRejectionRequest,
  ): Observable<void> {
    //eslint-disable-next-line max-len
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/candidates/${request.candidate.getId()}/rejection`;

    if (request.slots[0] instanceof Slot) {
      request.slots = (request.slots as Slot[]).map(slot => slot.getId());
    }

    return this.httpClient.post<void>(url, {
      declineReason: request.declineReason,
      slots: request.slots,
    });
  }

  /**
   * Parse the given filterFields object and append or set HttpParams based on
   * the content of the filterFields object.
   */
  private createParamsFromFilterFields(filterFields?: CandidateFilter, params?: HttpParams): HttpParams {
    params = params ?? new HttpParams();
    if (!filterFields) {
      return params;
    }

    const parseArray: (filter: keyof CandidateFilter) => void = (filter: keyof CandidateFilter) => {
      for (let [key, item] of (filterFields[filter] as { toString: () => string }[]).entries()) {
        params = (params as HttpParams).append(filter + '[' + key + ']', item.toString());
      }
    };

    for (const filter of Object.keys(filterFields)) {
      if (!filterFields[filter as keyof CandidateFilter]) {
        continue;
      }

      if (Array.isArray(filterFields[filter as keyof CandidateFilter])) {
        parseArray(filter as keyof CandidateFilter);
      } else {
        params = params.set(filter, filterFields[filter as keyof CandidateFilter] as string | number | boolean);
      }
    }

    return params;
  }
}
