import { EagerLoaded } from '@techniek-team/fetch';
import { FetchObservable } from '@techniek-team/rxjs';
import { Exclude, Expose, Type } from 'class-transformer';
import { firstValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { Action } from '../action/action.model';
import { LessonDetailed } from '../lesson/lesson-detailed.model';
import { Role } from '../role/role.model';
import { SlotStatistics } from './slot-statistics/slot-statistics.model';
import { Slot } from './slot.model';

/**
 * Detailed version of the Slot Resource.
 */
export class SlotDetailed<Lazy = FetchObservable<unknown>> extends Slot<Lazy> {

  /**
   * @inheritDoc
   */
  public override readonly className: string = 'SlotDetailed';

  @Type(() => Action)
  @Expose() public actions?: Action[];

  /**
   * The lesson where this slot belongs to
   */
  @Type(() => LessonDetailed)
  @Expose() public override lesson!: LessonDetailed<Lazy>;

  /**
   * Returns true if slot.isCombined is true, or if slot is in assignment with
   * one or more other slots that have isCombined = true.
   *
   * Only visible for BUSINESS_USER_ROLE
   */
  @Expose() public displayAsCombined: boolean = false;

  /**
   * If true, this slot is combined with one or more other (conflicting) slots into one assignment.
   * Only possible if productType.allowCombinedSlots is true (e.g. Summerschools).
   * Only visible for BUSINESS_USER_ROLE
   */
  @Expose() public isCombined: boolean = false;

  /**
   * If true the skills of the candidate should be taken into account.
   * Only visible for BUSINESS_USER_ROLE
   */
  @Expose() public performSkillCheck!: boolean;

  /**
   * Property containing statistics about the slot.
   */
  @Exclude() public statistics?: SlotStatistics;

  /**
   * Returns true if there are statistics available.
   */
  public hasStatistics(): boolean {
    return !!(this.statistics);
  }

  @Exclude() public isParentSlot(): boolean {
    return (this.displayAsCombined === true && this.isCombined === false);
  }

  public override async fetchAll(): Promise<SlotDetailed<EagerLoaded>> {
    let role: FetchObservable<Role<Lazy>> | Role<Lazy> = this.role;

    if (role instanceof FetchObservable) {
      role = await firstValueFrom(role.pipe(take(1))).then(item => item.fetchAll());
    }

    const slot: SlotDetailed<EagerLoaded> = this as SlotDetailed<EagerLoaded>;
    slot.role = role as Role<EagerLoaded>;
    slot.lesson = await this.lesson.fetchAll() as LessonDetailed<EagerLoaded>;
    return slot;
  }
}
