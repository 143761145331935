import { AfterContentInit, Component, Injector, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { AssignmentTransition, LogRecord, RecordType } from '@scheduler-frontend/models';
import { TtHydraPaginationDataSource } from '@techniek-team/datasource';
import { AssignmentApi } from '../../../../api/assignment/assignment.api';
import { AssignmentModalService } from '../assignment-modal.service';

@Component({
  selector: 'app-assignment-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
})
export class HistoryComponent implements AfterContentInit {

  @ViewChild(MatSort) public sort!: MatSort;

  protected readonly AssignmentTransition: typeof AssignmentTransition = AssignmentTransition;

  protected readonly RecordType: typeof RecordType = RecordType;

  protected readonly displayedColumns: string[] = ['icon', 'initiatedAt', 'description', 'initiatedBy'];

  protected logDataSource!: TtHydraPaginationDataSource<LogRecord>;

  constructor(
    private assignmentApi: AssignmentApi,
    private injector: Injector,
    public assignmentModalService: AssignmentModalService,
  ) {
  }

  /**
   * @inheritDoc
   */
  public ngAfterContentInit(): void {
    this.logDataSource = new TtHydraPaginationDataSource(
      this.injector,
      LogRecord,
      this.assignmentApi.getExecutedTransitions(this.assignmentModalService.assignmentId),
      this.sort,
    );
    this.logDataSource.init();
  }
}

