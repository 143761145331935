import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AssignmentStateEnum } from '@scheduler-frontend/enums';
import { AssignmentDetail } from '@scheduler-frontend/models';
import { EagerLoaded } from '@techniek-team/fetch';
import { PermissionService } from '@techniek-team/permissions';
import { firstEmitFrom } from '@techniek-team/rxjs';
import { SentryErrorHandler } from '@techniek-team/sentry-web';
import { ToastService } from '@techniek-team/services';
import { BehaviorSubject, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AssignmentApi } from '../../../../../api/assignment/assignment.api';
import { AssignmentPermission } from '../../../../../core/permission/assignment.permission';

interface UrgentFormValue {
  isUrgent: boolean;
}

interface UrgentForm {
  isUrgent: FormControl<boolean>;
}

@Component({
  selector: 'app-urgent-form',
  templateUrl: './urgent-form.component.html',
  styleUrls: ['./urgent-form.component.scss'],
})
export class UrgentFormComponent implements OnInit {

  /**
   * The provided assignment.
   */
  @Input() public assignment!: AssignmentDetail<EagerLoaded>;

  /**
   * The form.
   */
  protected urgentForm!: FormGroup<UrgentForm>;

  /**
   * Subject when true the loading indicator of the isUrgent field is shown.
   */
  protected loader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private assignmentApi: AssignmentApi,
    private errorHandler: SentryErrorHandler,
    private permissionService: PermissionService,
    private toastService: ToastService,
  ) {
  }

  /**
   * @inheritDoc
   */
  public ngOnInit(): void {
    this.urgentForm = this.createForm();
    this.createIsUrgentSubscriber();
  }

  private createForm(): FormGroup<UrgentForm> {
    let disabled: boolean = true;

    if (this.assignment.state === (AssignmentStateEnum.UNASSIGNED || AssignmentStateEnum.WAITING_FOR_CONFIRMATION)) {
      disabled = false;
    }

    const urgentFormGroup: FormGroup<UrgentForm> = new FormGroup<UrgentForm>({
      isUrgent: new FormControl<boolean>(
        { value: this.assignment.isUrgent, disabled: disabled },
        { nonNullable: true },
      ),
    });

    this.permissionService.isDenied(AssignmentPermission, 'MARK_AS_URGENT').then(isDenied => {
      if (isDenied) {
        urgentFormGroup.disable({ onlySelf: false, emitEvent: false });
      }
    });

    return urgentFormGroup;
  }

  private createIsUrgentSubscriber(): void {
    (this.urgentForm.get('isUrgent') as FormControl).valueChanges.pipe(
      switchMap(() => from(this.editIsUrgent())),
    ).subscribe();
  }

  private async editIsUrgent(): Promise<void> {
    const formValue: UrgentFormValue = this.urgentForm.getRawValue();

    try {
      this.loader$.next(true);

      await firstEmitFrom(this.assignmentApi.markAsUrgent({
        assignmentId: this.assignment.getId(),
        isUrgent: formValue.isUrgent,
      }));

      await this.toastService.create('Urgentiestatus is opgeslagen.');
    } catch (error) {
      let message: string = 'Er is iets misgegaan bij het bijwerken van de urgentiestatus.';

      if ((error instanceof HttpErrorResponse && error.status !== 400) || !(error instanceof HttpErrorResponse)) {
        await this.errorHandler.captureError(error);
      }

      await this.toastService.error(this.errorHandler.extractMessage(error, message));
    } finally {
      this.loader$.next(false);
    }
  }

}
