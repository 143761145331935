import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@scheduler-frontend/environments';
import {
  Assignment,
  AssignmentHasSlotDetailed,
  LocationModel,
  PayoutWaitingForApproval,
} from '@scheduler-frontend/models';
import { Resource } from '@techniek-team/api-platform';
import { denormalize, TsRangeInterface } from '@techniek-team/class-transformer';
import { formatISO } from 'date-fns';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CompensationCell } from '../../payout/admin-approval-overview/models/compensation-cell.model';
import { CompensationTable } from '../../payout/admin-approval-overview/models/compensation-table.model';
import { LocationMinimalContract } from '../contract/location.contract';
import { RoleMinimalContract } from '../contract/role.contract';
import { PostCompensationLineRequest } from './payout.request';
import {
  GetBulkPayoutResponse,
  GetLocationPayoutWaitingForApprovalsResponseV1,
  GetLocationPayoutWaitingForApprovalsResponseV3,
  GetPayoutInformationResponse,
  PostMustPerformCompensationResponse,
} from './payout.response';

//eslint-disable-next-line max-len,max-lines-per-function
function convertGetLocationPayoutWaitingForApprovalsResponseV1ToV3(response: Resource<GetLocationPayoutWaitingForApprovalsResponseV1>): Resource<GetLocationPayoutWaitingForApprovalsResponseV3> {
  return {
    '@id': `/${response['@id']}`,
    '@type': 'PayoutWaitingForApproval',
    //todo check if the array actual optional
    'name': (response.name) ? response.name : undefined,
    'description': (response.description) ? response.description : undefined,
    'contractType': (response.contractType) ? response.contractType : undefined,
    'candidate': {
      '@id': `${environment.scheduler.iri}/v3/candidates/${response.candidateId}`,
      '@type': 'CandidateMinimal',
      'fullName': response.candidateName,
    },
    'state': response.state,
    'schedule': {
      '@id': `${environment.scheduler.iri}/v3/schedules/${response.schedule.id}`,
      '@type': 'Schedule',
      'name': response.schedule.name,
      'product': {
        '@id': `${environment.scheduler.iri}/v3/products/${response.schedule.product.id}`,
        '@type': 'Product',
        'name': response.schedule.product.name,
        'productType': {
          '@id': `${environment.scheduler.iri}/v3/product-types/${response.schedule.product.productType.id}`,
          '@type': 'ProductType',
          'name': response.schedule.product.productType.name,
          'abbreviation': response.schedule.product.productType.abbreviation,
          'allowCreateShift': false,
          'hasBalance': false,
        },
      },
    },
    'compensation': ((response.compensation) ? {
      ...response.compensation,
      assignmentCompensationLines: response.compensation?.assignmentCompensationLines?.map(item => ({
        ...item,
        '@id': `${environment.scheduler.iri}/v3/assignment-compensation-lines/${item.id}`,
        //eslint-disable-next-line max-len
        'assignmentHasSlot': `${environment.scheduler.iri}/v3/payout-waiting-for-approval-assignment-has-slots/${item.assignmentHasSlotId}`,
        //eslint-disable-next-line max-len
        'assignmentCompensation': `${environment.scheduler.iri}/v3/assignment-compensations/${item.assignmentCompensationId}`,
      })) ?? [],
    } : undefined) as {
      state: string;
      total: string;
      projectCode: string;
      division: string;
      invoiceNumber: string|null;
      assignmentCompensationLines: Resource<{
        assignmentCompensation: string; //iri
        assignmentHasSlot: string; // iri
        description: string;
        quantity: string;
        amount: string;
        subtotal: string;
        articleCode: string;
        isExtra: boolean;
        date: string;
      }>[];
    },
    'assignmentHasSlots': response.assignmentHasSlots.map(item => ({
      '@id': `${environment.scheduler.iri}/v3/payout-waiting-for-approval-assignment-has-slots/${item.id}`,
      'slot': {
        '@id': `${environment.scheduler.iri}/v3/payout-waiting-for-approval-slots/${item.id}`,
        'scheduleId': item.slot?.scheduleId,
        'location': (item.slot.location) ? {
          '@id': `${environment.scheduler.iri}/v3/locations/${item.slot.location.id}`,
          ...item.slot.location,
        } : undefined,
        'timePeriod': item.slot.timePeriod,
        'role': (item.slot.role) ? {
          '@id': `${environment.scheduler.iri}/v3/locations/${item.slot.role.id}`,
          ...item.slot.role,
        } : undefined,
      },
      'actualTimePeriod': item.actualTimePeriod,
    })) as Resource<{
      slot: Resource<{
        scheduleId: string;
        location: Resource<LocationMinimalContract>;
        timePeriod: TsRangeInterface;
        role: Resource<RoleMinimalContract>;
      }>;
      actualTimePeriod: TsRangeInterface;
    }>[],
  };
}

@Injectable({
  providedIn: 'root',
})
export class PayoutApi {

  constructor(protected httpClient: HttpClient) {
  }

  public getPayoutInformation(
    assignment: string | Assignment,
  ): Observable<GetPayoutInformationResponse> {
    const id: string = (typeof assignment === 'string') ? assignment : assignment.getId();
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/assignments/${id}/payout`;

    return this.httpClient.get<GetPayoutInformationResponse>(url);
  }

  /**
   * Create a new assignment compensation line by doing a POST request
   * to the API.
   */
  public postCompensationLine(
    request: PostCompensationLineRequest,
  ): Observable<void> {
    //eslint-disable-next-line max-len
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/assignments/${request.assignmentCompensationLine}/compensations/lines`;

    const assignmentHasSlot: string | AssignmentHasSlotDetailed = request.assignmentHasSlot;
    return this.httpClient.post<void>(url, {
      //eslint-disable-next-line @typescript-eslint/naming-convention
      article_code: request.articleCode,
      description: request.description,
      quantity: request.quantity,
      amount: request.amount,
      assignmentHasSlot: (typeof assignmentHasSlot === 'string') ? assignmentHasSlot : assignmentHasSlot.getId(),
      subtotal: request.subtotal,
      date: formatISO(request.date),
    });
  }

  public deleteAssignmentCompensationLine(
    assignmentCompensationLineId: string,
  ): Observable<void> {
    //eslint-disable-next-line max-len
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/assignments/${assignmentCompensationLineId}/compensation/line`;

    return this.httpClient.delete<void>(url);
  }

  public postMustPerformCompensation(
    assignment: string | Assignment,
    mustPerformCompensation: boolean,
    automaticTravelCompensation: boolean,
  ): Observable<PostMustPerformCompensationResponse> {
    const id: string = (typeof assignment === 'string') ? assignment : assignment.getId();
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/assignments/${id}/compensation`;

    return this.httpClient.post<PostMustPerformCompensationResponse>(url, {
      mustPerformCompensation: mustPerformCompensation,
      automaticTravelCompensation: automaticTravelCompensation,
    });
  }

  public getBulkPayouts(): Observable<GetBulkPayoutResponse[]> {
    //eslint-disable-next-line max-len
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/payouts/aggregated/unfinalized-assignments`;

    return this.httpClient.get<GetBulkPayoutResponse[]>(url);
  }

  public postBulkPayouts(
    product: CompensationTable,
    week: number,
    year: number,
  ): Observable<void> {
    //eslint-disable-next-line max-len
    return this.httpClient.post<void>(`${environment.scheduler.url}${environment.scheduler.iri}/v1/payouts/aggregated/unfinalized-assignments`,
      {
        productTypeId: product.id,
        week: week,
        year: year,
        numberOfAssignments: product.totalAssignments,
        totalAmount: (product.footer.getCell('total') as CompensationCell).amount,
      },
    );
  }

  public getLocationPayoutWaitingForApprovals(
    location: string | LocationModel,
  ): Observable<PayoutWaitingForApproval[]> {
    if (location instanceof LocationModel) {
      location = location.getId();
    }
    //eslint-disable-next-line max-len
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/assignments/${location}/unfinalized`;

    return this.httpClient.get<Resource<GetLocationPayoutWaitingForApprovalsResponseV1>[]>(url).pipe(
      map(response => response.map(item => convertGetLocationPayoutWaitingForApprovalsResponseV1ToV3(item))),
      map(response => denormalize(PayoutWaitingForApproval, response)),
    );
  }

}
