<tt-modal (cancel)="modalController.dismiss()">
  <tt-modal-title color="tertiary">What's new</tt-modal-title>

  <ion-card *ngFor="let release of releases" class="ion-text-start ion-margin-bottom">
    <ion-card-header>
      <ion-card-title>{{release.name}}</ion-card-title>
      <ion-card-subtitle>{{release.releasedAt | date}}</ion-card-subtitle>
    </ion-card-header>

    <ion-card-content class="whats-new"
                      [innerHTML]="release.descriptionHtml">
    </ion-card-content>

    <ion-card-content *ngIf="release.images.length">
      <img *ngFor="let image of release.images"
           [ngSrc]="image.url"
           height="292"
           width="600"
           [alt]="image.name">
    </ion-card-content>
  </ion-card>
</tt-modal>
