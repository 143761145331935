import { Injectable } from '@angular/core';
import {
  AssignmentDetailWithAssignmentHasSlot,
  Slot,
  SlotDetailed,
  SlotDetailedWithAssignmentHasSlot,
} from '@scheduler-frontend/models';
import { isAfter, isSameMonth, max, setDate, startOfDay, subMonths } from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class BookingPeriodService {

  /**
   * The method check if the given Slot or Date lies within a closed booking period.
   */
  public isBookingPeriodClosed(target: Slot | SlotDetailed | SlotDetailedWithAssignmentHasSlot | Date): boolean {
    if (target instanceof SlotDetailedWithAssignmentHasSlot && target?.assignmentHasSlot?.assignment) {
      return target.assignmentHasSlot.assignment.bookingPeriodClosed;
    }
    const lastBookingPeriod: Date = this.getLastBookingPeriod();

    if (target instanceof Date) {
      // The booking period for a certain month closes on 00:00:00 of the six day
      // of the next month. The booking period is about a month. Meaning that the
      // first 5 day of a month are part of the booking period of that month and
      // not the previous.
      if (isSameMonth(lastBookingPeriod, target)) {
        return false;
      }

      return isAfter(lastBookingPeriod, target);
    }

    if (isSameMonth(lastBookingPeriod, target.timePeriod.end)) {
      return false;
    }

    return isAfter(this.getLastBookingPeriod(), target.timePeriod.end);
  }

  public wasOutsideOfBookingPeriod(
    period: Date,
    target: SlotDetailed | AssignmentDetailWithAssignmentHasSlot | Date,
  ): boolean {
    if (target instanceof SlotDetailed) {
      target = target.timePeriod.end;
    }

    if (target instanceof AssignmentDetailWithAssignmentHasSlot) {
      target = max(target.assignmentHasSlots.map(hasSlot => hasSlot.slot.timePeriod.end));
    }

    const targetsBookingPeriod: Date = this.getBookingPeriodForDate(period);

    // The booking period for a certain month closes on 00:00:00 of the six day
    // of the next month. The booking period is about a month. Meaning that the
    // first 5 day of a month are part of the booking period of that month and
    // not the previous.
    if (isSameMonth(targetsBookingPeriod, target)) {
      return false;
    }

    return isAfter(targetsBookingPeriod, target);

  }

  /**
   * Returns the Last closed booking period.
   */
  public getLastBookingPeriod(): Date {
    return this.getBookingPeriodForDate(new Date());
  }

  /**
   * Returns the booking's period closing date for the given date.
   */
  public getBookingPeriodForDate(date: Date): Date {
    if (isAfter(date, setDate(startOfDay(date), 6))) {
      return setDate(startOfDay(date), 6);
    }
    return setDate(subMonths(startOfDay(date), 1), 6);
  }
}
