import { Injectable } from '@angular/core';
import { PreFetchMethodsObject, PreFetchServiceInterface } from '@techniek-team/fetch';
import { UserService } from '@techniek-team/oauth';
import { firstEmitFrom } from '@techniek-team/rxjs';
import { BusinessServiceApi } from '../../../api/business-service/business-service.api';
import { LocationApi } from '../../../api/location/location.api';
import { ProductTypeApi } from '../../../api/product-type/product-type.api';
import { RegionApi } from '../../../api/region/region.api';
import { RoleApi } from '../../../api/role/role.api';
import { SubjectApi } from '../../../api/subject/subject.api';

@Injectable({
  providedIn: 'root',
})
export class PreFetchService implements PreFetchServiceInterface {

  constructor(
    private businessServiceApi: BusinessServiceApi,
    private subjectApi: SubjectApi,
    private roleApi: RoleApi,
    private locationApi: LocationApi,
    private productTypeApi: ProductTypeApi,
    private userService: UserService,
    private regionApi: RegionApi,
  ) {
  }

  public async preFetch(): Promise<PreFetchMethodsObject> {
    await firstEmitFrom(this.userService.currentUser());
    return {
      businessService: this.businessServiceApi.getBusinessServices(),
      subjectModel: this.subjectApi.getSubjects(),
      role: this.roleApi.getRoles(),
      locationModel: this.locationApi.getLocations({ cache: true }),
      productType: this.productTypeApi.getProductTypes(),
      regions: this.regionApi.getRegions(),
    };
  }
}
