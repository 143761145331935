import { LevelEnum } from '@scheduler-frontend/enums';
import { EagerLoaded } from '@techniek-team/fetch';
import { max, min } from 'date-fns';
import { CandidateSkill } from '../skill/candidate-skilll.model';

/**
 * The model contains a list of candidate skill which all shared the same
 * {@see Role}
 */
export class SkillsPerLevelSet {

  public subjects: Set<string> = new Set<string>();

  public levels: Set<LevelEnum> = new Set<LevelEnum>();

  public skills: CandidateSkill<EagerLoaded>[] = [];

  constructor(skills: CandidateSkill<EagerLoaded>[]) {
    this.addSkill(skills);
  }

  public addSkill(skills: CandidateSkill<EagerLoaded>|CandidateSkill<EagerLoaded>[]): void {
    if (!Array.isArray(skills)) {
      skills = [skills];
    }

    for (let skill of skills) {
      this.skills.push(skill);
      this.levels.add(skill.skill.level);

      if (skill.skill.subject) {
        this.subjects.add(skill.skill.subject.name);
      }
    }
  }

  public levelSetEquals(levels: Set<LevelEnum>): boolean {
    if (levels.size !== this.levels.size) {
      return false;
    }

    for (let level of levels) {
      if (!this.levels.has(level)) {
        return false;
      }
    }
    return true;
  }

  public getEarliestStartDate(): Date {
    return min(this.skills.map(skill => skill.validityRange.start));
  }

  public getLatestEndDate(): Date {
    return max(this.skills.map(skill => skill.validityRange.end));
  }
}
