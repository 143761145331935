import { EagerLoaded, Fetch, Fetched } from '@techniek-team/fetch';
import { FetchObservable } from '@techniek-team/rxjs';
import { Expose } from 'class-transformer';
import { firstValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { BusinessServiceDetailed } from '../business/business-service-detailed.model';
import { Role } from './role.model';

/**
 * standard version of the Role resource from Scheduler-api
 */
export class RoleDetailed<Lazy = FetchObservable<unknown>> extends Role<Lazy> {
  /**
   * @inheritDoc
   */
  public override readonly className: string = 'RoleDetailed';

  /**
   * The service where the role belongs to.
   */
  @Fetch(() => BusinessServiceDetailed)
  @Expose() public override businessService?: Fetched<Lazy, BusinessServiceDetailed<Lazy>>;

  //eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    super();
  }

  public override async fetchAll(): Promise<RoleDetailed<EagerLoaded>> {
    //eslint-disable-next-line max-len
    let businessService: FetchObservable<BusinessServiceDetailed<Lazy>> | BusinessServiceDetailed<Lazy> | undefined = this.businessService;

    if (businessService instanceof FetchObservable) {
      businessService = await firstValueFrom(businessService.pipe(take(1)));
    }

    const role: RoleDetailed<EagerLoaded> = this as RoleDetailed<EagerLoaded>;
    role.businessService = businessService as BusinessServiceDetailed<Lazy>;
    return role;
  }
}
