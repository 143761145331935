import { EagerLoaded, Fetch, Fetched, LazyJsonLd as JsonLd } from '@techniek-team/fetch';
import { FetchObservable } from '@techniek-team/rxjs';
import { Expose } from 'class-transformer';
import { firstValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { LocationModel } from '../../location/location.model';

export class CandidateLocation<Lazy = FetchObservable<unknown>> extends JsonLd {

  /**
   * @inheritDoc
   */
  public override readonly className: string = 'CandidateLocation';

  @Expose() public isMain!: boolean;

  @Fetch(() => LocationModel)
  @Expose() public location?: Fetched<Lazy, LocationModel>;


  public override async fetchAll(): Promise<CandidateLocation<EagerLoaded>> {
    let location: FetchObservable<LocationModel> | LocationModel | undefined = this.location;

    if (location instanceof FetchObservable) {
      location = await firstValueFrom(location.pipe(take(1)));
    }

    const candidateLocation: CandidateLocation<EagerLoaded> = this as CandidateLocation<EagerLoaded>;
    candidateLocation.location = location as LocationModel;
    return candidateLocation;
  }

  /**
   * @inheritDoc
   */
  public override toString(): string {
    return `${this.className}: ${this.getId()}`;
  }
}

