export enum PayoutArticleCodeEnum {
  REIMBURSEMENT = 'reimbursement',
  REMUNERATION = 'remuneration',
  ROLE_PREMIUM = 'role_premium',
  GEOGRAPHIC_PREMIUM = 'geographic_premium',
  EXPERIENCE_PREMIUM = 'experience_premium',
  MIGRATION_PREMIUM = 'migration_premium',
  FIXED = 'fixed',
  SLOT_PREMIUM = 'slot_premium',
}

export enum ArticleCodeEnumDisplayValues {
  //eslint-disable-next-line @typescript-eslint/naming-convention
  reimbursement = 'Reiskosten',
  //eslint-disable-next-line @typescript-eslint/naming-convention
  remuneration = 'Uren',
  //eslint-disable-next-line @typescript-eslint/naming-convention
  role_premium = 'Vergoeding',
  //eslint-disable-next-line @typescript-eslint/naming-convention
  geographic_premium = 'Vergoeding',
  //eslint-disable-next-line @typescript-eslint/naming-convention
  experience_premium = 'Vergoeding',
  //eslint-disable-next-line @typescript-eslint/naming-convention
  migration_premium = 'Vergoeding',
  //eslint-disable-next-line @typescript-eslint/naming-convention
  fixed = 'Vergoeding',
  //eslint-disable-next-line @typescript-eslint/naming-convention
  slot_premium = 'Vergoeding',
}
