// https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
import { HttpErrorResponse } from '@angular/common/http';

interface ErrorCandidate {
  name?: unknown;
  message?: unknown;
  stack?: unknown;
}

interface ServerResponseErrorCandidate {
  code: number;
  message: string;
}

export class ServerResponseError extends Error {
  constructor(message: string, public readonly code: number) {
    super(message);
    // see: typescriptlang.org/docs/handbook/release-notes/typescript-2-2.html
    Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
    this.name = ServerResponseError.name; // stack traces display correctly now
  }
}


function tryToUnwrapZonejsError(error: unknown): unknown | Error {
  // TODO: once Angular14 is the minimum requirement ERROR_ORIGINAL_ERROR and
  //  getOriginalError from error.ts can be used directly.
  if (error && (error as { ngOriginalError: Error }).ngOriginalError) {
    return (error as { ngOriginalError: Error }).ngOriginalError;
  }

  return error;
}

function extractHttpModuleError(error: HttpErrorResponse): string | Error {
  // The `error` property of http exception can be either an `Error` object, which we can use directly...
  if (isErrorOrErrorLikeObject(error.error)) {
    return error.error;
  }

  // ... or an`ErrorEvent`, which can provide us with the message but no stack...
  if (error.error instanceof ErrorEvent && error.error.message) {
    return error.error.message;
  }

  if (isErrorResponseBody(error.error)) {
    return new ServerResponseError(error.error.message, error.error.code);
  }

  // ...or the request body itself, which we can use as a message instead.
  if (typeof error.error === 'string') {
    return `Server returned code ${error.status} with body "${error.error}"`;
  }

  // If we don't have any detailed information, fallback to the request message itself.
  return error.message;
}

function isErrorOrErrorLikeObject(value: unknown): value is Error {
  if (value instanceof Error) {
    return true;
  }

  if (value === null || typeof value !== 'object') {
    return false;
  }

  const candidate: ErrorCandidate = value as ErrorCandidate;

  return (
    (typeof candidate.name === 'string')
    && (typeof candidate.message === 'string')
    && (undefined === candidate.stack || (typeof candidate.stack === 'string'))
  );
}

function isErrorResponseBody(value: unknown): value is ServerResponseErrorCandidate {
  const candidate: ServerResponseErrorCandidate = value as ServerResponseErrorCandidate;

  return (
    (typeof candidate.code === 'number') && (typeof candidate.message === 'string')
  );
}


export function extractor(errorCandidate: unknown): unknown {
  let error: unknown = tryToUnwrapZonejsError(errorCandidate);


  // We can handle messages and Error objects directly.
  if (typeof error === 'string' || error instanceof Error) {
    return error;
  }

  if (error instanceof HttpErrorResponse) {
    return extractHttpModuleError(error);
  }

  // We can handle messages and Error objects directly.
  if (typeof error === 'string' || isErrorOrErrorLikeObject(error)) {
    return error;
  }

  // Nothing was extracted, fallback to default error message.
  return null;
}
