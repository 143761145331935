import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

export class JsonLdInterceptor implements HttpInterceptor {
  /**
   * @inheritDoc
   *
   * This interceptor adds the json-ld accept header to all specified endpoints.
   */
  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let request: HttpRequest<unknown> = req.clone({
      headers: req.headers.set('accept', 'application/ld+json, application/json'),
    });

    return next.handle(request);
  }
}
