import { Expose, Type } from 'class-transformer';
import { sum } from 'lodash-es';
import { Candidate } from '../../candidate/candidate.model';
import { Allotment } from './allotment.model';

export class CandidateSearchResultItem extends Candidate {
  @Expose() public distance!: number;

  @Expose() public avgTravelBurden!: number;

  @Expose() public avgQuality!: number;

  @Expose() public avgAvailability!: number;

  @Expose() public avgSkillEfficiency!: number;

  @Expose() public avgWaitingTimeSinceHired!: number;

  @Expose() public avgWaitingTimeSinceLastAssignment!: number;

  @Expose() public avgTotal!: number;

  @Expose() public requestedMinutes!: number;

  @Expose() public availableMinutes!: number;

  @Expose() public unavailableMinutes!: number;

  @Expose() public conflictingMinutes!: number;

  @Type(() => Allotment)
  @Expose() public allotments: Allotment[] = [];

  public get avgAllotments(): { preferredWorkingTime: number; actualWorkingTime: number } | undefined {
    if (!this.allotments || this.allotments.length === 0) {
      return undefined;
    }

    return {
      preferredWorkingTime: (this.allotments.length === 0) ? 0 : sum(
        this.allotments.map(item => item.preferredWorkingTime.hours),
      ) / this.allotments.length,
      actualWorkingTime: (this.allotments.length === 0) ? 0 : sum(
        this.allotments.map(item => item.actualWorkingTime.hours),
      ) / this.allotments.length,
    };
  }

}
