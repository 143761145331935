import { sortDefined } from './sort-defined.function';
import { SortDirection } from './sort-direction.type';

export function sortNumber(a: number, b: number, direction: SortDirection = 'asc'): number {
  const sortDefinedResult: number = sortDefined(a, b, direction);
  if (sortDefinedResult !== 0) {
    return sortDefinedResult;
  }

  return ((a ?? 0) - (b ?? 0)) * (direction === 'asc' ? 1 : -1);
}
