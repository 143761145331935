import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IonicModule } from '@ionic/angular';
import { TtModalModule } from '@techniek-team/components/modal';
import { AsTypeModule } from '../../as-type/as-type.module';
import { AssignmentTransitionMessagesModalComponent } from './assignment-transition-messages-modal.component';

@NgModule({
  declarations: [
    AssignmentTransitionMessagesModalComponent,
  ],
  imports: [
    CommonModule,
    TtModalModule,
    FontAwesomeModule,
    MatTooltipModule,
    IonicModule,
    AsTypeModule,
  ],
  exports: [
    AssignmentTransitionMessagesModalComponent,
  ],
})
export class AssignmentTransitionMessagesModalModule { }
