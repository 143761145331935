import { NgModule } from '@angular/core';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import {
  faAddressCard,
  faAlarmClock,
  faArrowDownShortWide,
  faArrowRightFromBracket,
  faArrowUpShortWide,
  faCabinetFiling, faCalendarClock,
  faCalendarDays as faCalendarDuo,
  faCalendarExclamation,
  faChalkboardUser,
  faChartUser,
  faCheck,
  faClipboardMedical,
  faClockEight,
  faClockEleven,
  faClockFive,
  faClockFour,
  faClockNine,
  faClockOne,
  faClockSeven,
  faClockSix,
  faClockTen,
  faClockThree,
  faClockTwelve,
  faClockTwo,
  faEllipsisVertical,
  faFileInvoiceDollar,
  faFilters,
  faFlask,
  faLinkSimple,
  faMagnifyingGlass,
  faMoneyCheckDollar,
  faPhone,
  faRoute,
  faSnooze,
  faTimer,
  faTriangleExclamation as faTriangleExclamationDuo,
  faUserGroup,
  faVirus,
} from '@fortawesome/pro-duotone-svg-icons';
import {
  faBriefcaseBlank,
  faCalendar,
  faChartSimple,
  faCircleInfo,
  faCopy,
  faFileLines,
  faHome,
  faHouseNight,
  faPowerOff,
  faTrash,
} from '@fortawesome/pro-light-svg-icons';
import {
  faArrowFromLeft,
  faCheckToSlot,
  faChevronDown,
  faCircleCheck,
  faEuroSign,
  faExclamationCircle,
  faEyeSlash,
  faInfoCircle,
  faLightbulb,
  faPen,
  faStickyNote,
  faTrashCan,
  faTriangleExclamation,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faAt,
  faBuildingUser,
  faCalendarCheck,
  faCalendarExclamation as faCalendarExclamationSolid,
  faCalendarXmark as faCalendarXmarkSolid,
  faCircleCheck as faCircleCheckSolid,
  faHourglassStart,
  faLocationDot,
  faPartyHorn,
  faUser,
  faWifi,
} from '@fortawesome/pro-solid-svg-icons';

@NgModule({
  imports: [FontAwesomeModule],
  exports: [FontAwesomeModule],
})
export class IconsModule {
  //eslint-disable-next-line max-lines-per-function
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faAddressCard,
      faAlarmClock,
      faArrowDownShortWide,
      faArrowFromLeft,
      faArrowRightFromBracket,
      faArrowUpShortWide,
      faAt,
      faBriefcaseBlank,
      faBuildingUser,
      faCabinetFiling,
      faCalendar,
      faCalendarCheck,
      faCalendarClock,
      faCalendarDuo,
      faCalendarExclamation,
      faCalendarExclamationSolid,
      faCalendarXmarkSolid,
      faChalkboardUser,
      faChartSimple,
      faChartUser,
      faCheck,
      faCheckToSlot,
      faChevronDown,
      faCircleCheck,
      faCircleCheckSolid,
      faCircleInfo,
      faClipboardMedical,
      faClockEight,
      faClockEleven,
      faClockFive,
      faClockFour,
      faClockNine,
      faClockOne,
      faClockSeven,
      faClockSix,
      faClockTen,
      faClockThree,
      faClockTwelve,
      faClockTwo,
      faCopy,
      faEllipsisVertical,
      faEuroSign,
      faExclamationCircle,
      faEyeSlash,
      faFileInvoiceDollar,
      faFileLines,
      faFilters,
      faFlask,
      faHome,
      faHourglassStart,
      faHouseNight,
      faInfoCircle,
      faLightbulb,
      faLinkSimple,
      faLocationDot,
      faMagnifyingGlass,
      faMoneyCheckDollar,
      faPartyHorn,
      faPen,
      faPhone,
      faPhone,
      faPowerOff,
      faRoute,
      faSnooze,
      faStickyNote,
      faTimer,
      faTrash,
      faTrashCan,
      faTriangleExclamation,
      faTriangleExclamationDuo,
      faUser,
      faUserGroup,
      faVirus,
      faWhatsapp,
      faWifi,
    );
  }
}
