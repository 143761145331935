import { Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IonColor, IonColorType } from '@techniek-team/lyceo-style';

export interface TtSimpleModalButton {
  text: string;
  color: IonColor;
  fill?: 'clear' | 'default' | 'outline' | 'solid';
  role?: 'cancel' | string;
  cssClass?: string | string[];
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  handler?: () => any;
}

@Component({
  selector: 'tt-simple-modal',
  templateUrl: './tt-simple-modal.component.html',
  styleUrls: ['./tt-simple-modal.component.scss'],
})
export class TtSimpleModalComponent implements OnInit {

  @Input() public title: string | undefined;

  @Input() public message: string | undefined;

  @Input() public buttons: TtSimpleModalButton[] = [
    {
      text: 'Sluiten',
      color: IonColorType.PRIMARY,
      fill: 'outline',
      role: 'cancel',
    },
    {
      text: 'Bevestigen',
      color: IonColorType.PRIMARY,
      role: 'confirm',
    },
  ];

  constructor(
    private elementRef: ElementRef,
    private modalController: ModalController,
    private renderer: Renderer2,
  ) {
  }

  public ngOnInit(): void {
    const ionModal: HTMLIonModalElement | null = this.elementRef.nativeElement.closest('ion-modal');
    if (ionModal) {
      this.renderer.addClass(ionModal, 'tt-simple-modal');
    }
  }

  protected cancel(): Promise<boolean> {
    return this.modalController.dismiss(null, 'cancel');
  }

  protected buttonClick(button: TtSimpleModalButton): Promise<boolean> {
    let data: unknown = null;

    if (button.handler) {
      data = button.handler();
    }

    return this.modalController.dismiss(data, button?.role);
  }
}
