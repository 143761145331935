import { Expose, Type } from 'class-transformer';
import { OnlineLessonLink } from '../../online-lesson-link.model';

/**
 * The standard version of the AssignmentOnlineLesson response which is part of
 * the Assignment from Scheduler-api.
 *
 * The model doesn't extend JsonLd because it isn't a real Resource.
 */
export class AssignmentOnlineLesson {

  /**
   * Identifier of the lesson to which this assignment belongs.
   */
  @Expose() public lessonId!: string;

  /**
   * Online links of a lesson.
   */
  @Type(() => OnlineLessonLink)
  @Expose() public links!: OnlineLessonLink[];
}

