import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Release } from '@scheduler-frontend/models';

@Component({
  selector: 'app-whats-new-modal',
  templateUrl: './whats-new-modal.component.html',
  styleUrls: ['./whats-new-modal.component.scss'],
})
export class WhatsNewModalComponent {
  @Input() public releases: Release[] = [];

  constructor(protected modalController: ModalController) {
  }
}
