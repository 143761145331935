import { EagerLoaded, Fetch, Fetched } from '@techniek-team/fetch';
import { FetchObservable } from '@techniek-team/rxjs';
import { Expose, Type } from 'class-transformer';
import { firstValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { ProductTypeDetailed } from '../product-type/product-type-detailed.model';
import { BusinessService } from './business-service.model';
import { DeliveryType } from './delivery-type.model';

/**
 * Detailed version of the BusinessEntity resource from Scheduler-api.
 */
export class BusinessServiceDetailed<Lazy = FetchObservable<unknown>> extends BusinessService<Lazy> {

  /**
   * @inheritDoc
   */
  public override readonly className: string = 'BusinessServiceDetailed';

  /**
   * Array of Delivery types which are ways to suply the customer with this service.
   * {@see AssignmentDeliveryTypeEnum}
   */
  @Type(() => DeliveryType)
  @Expose() public deliveryTypes: DeliveryType[] = [];

  /**
   * The product type this businessService belongs to.
   *
   * Business service can be seen as the combination of {@see BusinessEntity}
   * and {@see ProductType}.
   */
  @Fetch(() => ProductTypeDetailed)
  @Expose() public override productType?: Fetched<Lazy, ProductTypeDetailed>;

  public get validDeliveryTypes(): DeliveryType[] {
    return (this.deliveryTypes ?? []).filter(deliveryType => !deliveryType.isPast() && !deliveryType.isFuture());
  }

  public override async fetchAll(): Promise<BusinessServiceDetailed<EagerLoaded>> {
    let productType: FetchObservable<ProductTypeDetailed> | ProductTypeDetailed | undefined = this.productType;

    if (productType instanceof FetchObservable) {
      productType = await firstValueFrom(productType.pipe(take(1)));
    }

    const businessService: BusinessServiceDetailed<EagerLoaded> = this as BusinessServiceDetailed<EagerLoaded>;
    businessService.productType = productType as ProductTypeDetailed;
    return businessService;
  }
}
