import { TsRange } from '@techniek-team/class-transformer';
import { Expose, Type } from 'class-transformer';

export class CandidateAvailabilities {

  @Expose() public id!: string;

  @Expose() public available!: boolean;

  @Expose() public dateUpdated!: Date;

  @Type(() => TsRange)
  @Expose() public range!: TsRange;

  @Expose() public remarks!: string;
}
