export enum AddressTypeEnum {
  HOME = 'HOME',
  AUXILIARY = 'AUXILIARY',
}

/* eslint-disable @typescript-eslint/naming-convention */
export enum AddressTypeDisplayValues {
  HOME = 'thuis',
  AUXILIARY = 'overnachtingsadres',
}
/* eslint-enable @typescript-eslint/naming-convention */
