import { Component, Input } from '@angular/core';
import { IsActiveMatchOptions } from '@angular/router';

@Component({
  selector: 'tt-sub-menu-item',
  templateUrl: './tt-sub-menu-item.component.html',
  styleUrls: ['./tt-sub-menu-item.component.scss'],
})
export class TtSubMenuItemComponent {

  /**
   * Commands to pass to {@link Router#createUrlTree Router#createUrlTree}.
   *   - **array**: commands to pass to {@link Router#createUrlTree Router#createUrlTree}.
   *   - **string**: shorthand for array of commands with just the string, i.e. `['/route']`
   *   - **null|undefined**: effectively disables the `routerLink`
   * @see {@link Router#createUrlTree Router#createUrlTree}
   * @see RouterLink.routerLink
   */
  @Input() public routerLink: unknown[] | string | null | undefined;

  /**
   * Options to configure how to determine if the router link is active.
   *
   * These options are passed to the `Router.isActive()` function.
   *
   * @see Router.isActive
   * @see RouterLinkActive.routerLinkActiveOptions
   */
  @Input() public routerLinkActiveOptions: { exact: boolean } | IsActiveMatchOptions = { exact: false };

}
