import { Injector } from '@angular/core';
import { AssignmentStateEnum, isBeforeState, isState } from '@scheduler-frontend/enums';
import { AssignmentDetail, AssignmentDetailWithAssignmentHasSlot, Slot } from '@scheduler-frontend/models';
import { EagerLoaded } from '@techniek-team/fetch';
import { BasePermission, InsufficientPermissionsError } from '@techniek-team/permissions';
import { GetPayoutInformationResponse } from '../../api/payout/payout.response';
import { BookingPeriodService } from '../../shared/services/booking-period/booking-period.service';

enum AssignmentPermissionSubjects {
  UPDATE = 'UPDATE',
  ASSIGN_CANDIDATE = 'ASSIGN_CANDIDATE',
  REMOVE_SLOT_FROM_ASSIGNMENT = 'REMOVE_SLOT_FROM_ASSIGNMENT',
  CREATE_ASSIGNMENT_COMPENSATION_LINE = 'CREATE_ASSIGNMENT_COMPENSATION_LINE',
  DELETE_ASSIGNMENT_COMPENSATION_LINE = 'DELETE_ASSIGNMENT_COMPENSATION_LINE',
  CHANGE_IS_BILLABLE = 'CHANGE_IS_BILLABLE',
  EDIT_ACTUAL_WORKING_TIMES_OUTSIDE_BOOKING = 'EDIT_ACTUAL_WORKING_TIMES_OUTSIDE_BOOKING',
  READ_SELF_ASSIGNABLE = 'READ_SELF_ASSIGNABLE',
  EDIT_SELF_ASSIGNABLE = 'EDIT_SELF_ASSIGNABLE',
  EDIT_MANUAL_TRAVELING_COMPENSATION = 'EDIT_MANUAL_TRAVELING_COMPENSATION',
  EDIT_PAYOUT_CHECKBOX = 'EDIT_PAYOUT_CHECKBOX',
  ADD_PREMIUM_ALLOWED = 'ADD_PREMIUM_ALLOWED',
  MARK_AS_URGENT = 'MARK_AS_URGENT',
}

/**
 * This Permission can be used with the {@see TtPermissionModule} and is used to
 * check permission about assignments.
 */
export class AssignmentPermission extends BasePermission {

  /**
   * @inheritDoc
   */
  public override readonly subjects: typeof AssignmentPermissionSubjects = AssignmentPermissionSubjects;

  /**
   * Injected BookingPeriodService.
   */
  private bookingPeriodService: BookingPeriodService;

  constructor(injector: Injector) {
    super(injector);
    this.bookingPeriodService = injector.get(BookingPeriodService);
  }

  /**
   * @inheritDoc
   */
  //eslint-disable-next-line complexity
  public validate<K extends string>(
    subject: K,
    data: AssignmentDetailWithAssignmentHasSlot | AssignmentDetail | Slot | GetPayoutInformationResponse,
  ): boolean {
    switch (subject) {
      case AssignmentPermissionSubjects.UPDATE:
        return this.update(data as AssignmentDetail);
      case AssignmentPermissionSubjects.ASSIGN_CANDIDATE:
        return this.assignCandidate(data as Slot);
      case AssignmentPermissionSubjects.REMOVE_SLOT_FROM_ASSIGNMENT:
        return this.removeSlotFromAssignment(data as AssignmentDetailWithAssignmentHasSlot);
      case AssignmentPermissionSubjects.ADD_PREMIUM_ALLOWED:
        return this.addPremiumAllowed(data as GetPayoutInformationResponse);
      case AssignmentPermissionSubjects.CREATE_ASSIGNMENT_COMPENSATION_LINE:
        return this.editAssignmentCompensationLine(data as AssignmentDetail);
      case AssignmentPermissionSubjects.DELETE_ASSIGNMENT_COMPENSATION_LINE:
        return this.editAssignmentCompensationLine(data as AssignmentDetail);
      case AssignmentPermissionSubjects.CHANGE_IS_BILLABLE:
        return this.changeIsBillable(data as AssignmentDetail);
      case AssignmentPermissionSubjects.EDIT_MANUAL_TRAVELING_COMPENSATION:
        return this.editManualTravelingCompensation(data as AssignmentDetail);
      case AssignmentPermissionSubjects.EDIT_PAYOUT_CHECKBOX:
        return this.editPayoutCheckbox(data as AssignmentDetail);
      case AssignmentPermissionSubjects.EDIT_SELF_ASSIGNABLE:
        return this.is('admin') && (data as AssignmentDetail).allowUpdatingSelfAssignFields;
      case AssignmentPermissionSubjects.EDIT_ACTUAL_WORKING_TIMES_OUTSIDE_BOOKING:
      case AssignmentPermissionSubjects.READ_SELF_ASSIGNABLE:
      case AssignmentPermissionSubjects.MARK_AS_URGENT:
        return this.is('admin');
      default:
        throw new InsufficientPermissionsError();
    }
  }

  /**
   * checks if the user may update the assignment.
   */
  private update(assignment: AssignmentDetail): boolean {
    if (!assignment) {
      return false;
    }

    if (isState(assignment.state, AssignmentStateEnum.APPROVED)) {
      return false;
    }

    if (isBeforeState(assignment.state, AssignmentStateEnum.WAITING_FOR_CONFIRMATION)) {
      return false;
    }

    if (assignment.bookingPeriodClosed) {
      // Only admins are allowed
      return this.is('admin');
    }

    return true;
  }

  /**
   * Checks if the user may assign a candidate from an assignment.
   */
  private assignCandidate(slot: Slot): boolean {
    if (!slot) {
      return false;
    }

    // assign
    if (this.bookingPeriodService.isBookingPeriodClosed(slot)) {
      // Only admins are allowed
      return this.is('admin');
    }
    return true;
  }

  /**
   * Checks if the user may remove a slot to assignment.
   */
  private removeSlotFromAssignment(assignment: AssignmentDetailWithAssignmentHasSlot): boolean {
    if (!assignment) {
      return false;
    }

    // removing the last slot of an assignment would be the same as unassigned
    // the candidate from an assignment. so do that instead.
    if (assignment.assignmentHasSlots.length <= 1) {
      return false;
    }

    if (isState(assignment.state, AssignmentStateEnum.APPROVED)) {
      return false;
    }

    if (assignment.bookingPeriodClosed) {
      // Only admins are allowed
      return this.is('admin');
    }
    return true;
  }

  /**
   * Checks if the user is allowed to add a premium to a compensation line
   */
  private addPremiumAllowed(payout: GetPayoutInformationResponse): boolean {
    if (!payout) {
      return false;
    }

    if (!this.is('admin')) {
      return false;
    }

    return payout.general.addPremiumAllowed;
  }

  /**
   * Checks if the user may create addition compensation lines and
   * checks if the user may delete addition compensation lines.
   */
  private editAssignmentCompensationLine(assignment: AssignmentDetail): boolean {
    if (!assignment) {
      return false;
    }

    if (isState(assignment.state, AssignmentStateEnum.APPROVED)) {
      return false;
    }

    if (assignment.bookingPeriodClosed) {
      // Only admins are allowed
      return this.is('admin');
    }

    return true;
  }

  //noinspection JSMethodCanBeStatic
  /**
   * Checks if the user may change the isBillable property of an assignment.
   */
  private changeIsBillable(assignment: AssignmentDetail<EagerLoaded>): boolean {
    if (!assignment) {
      return false;
    }

    if (!assignment.businessService.productType?.hasBalance) {
      return false;
    }

    if (assignment.bookingPeriodClosed) {
      // Only admins are allowed
      return this.is('admin');
    }

    return true;
  }

  /**
   * Checks if the user may change the manual Traveling compensation checkbox
   * {@see PayoutDetailsComponent}
   */
  private editManualTravelingCompensation(assignment: AssignmentDetail): boolean {
    if (!assignment || !assignment.allowManualTravelCompensation) {
      return false;
    }

    if (!assignment.canEnableCompensation) {
      return false;
    }

    if (isState(assignment.state, AssignmentStateEnum.APPROVED)) {
      return false;
    }

    if (assignment.bookingPeriodClosed) {
      // Only admins are allowed
      return this.is('admin');
    }

    return true;
  }

  /**
   * Checks if the user may change the payout checkbox
   * {@see PayoutDetailsComponent}
   */
  private editPayoutCheckbox(assignment: AssignmentDetail): boolean {
    if (!assignment) {
      return false;
    }

    if (!assignment.canEnableCompensation) {
      return false;
    }

    if (isState(assignment.state, AssignmentStateEnum.APPROVED)) {
      return false;
    }

    if (assignment.bookingPeriodClosed) {
      // Only admins are allowed
      return this.is('admin');
    }

    return true;
  }
}
