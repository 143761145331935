import { ComponentRef, Directive, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { LoadingComponent } from './loading.component';

@Directive({
  selector: '[appLoadingOld]',
})
export class LoadingDirective implements OnInit, OnDestroy {
  public cmpRef!: ComponentRef<LoadingComponent>;

  constructor(
    private viewContainerRef: ViewContainerRef,
  ) {}

  public ngOnInit(): void {
    this.cmpRef = this.viewContainerRef.createComponent(LoadingComponent);
  }

  public show(token?: string): void {
    this.cmpRef.instance.show(token);
  }

  public hide(token?: string): void {
    if (this.cmpRef) {
      this.cmpRef.instance.hide(token);
    }
  }

  /**
   * When a api call has not completed while switching pages the .hide()
   * method will never be called. This caused the loader then to be
   * stuck on the next page. Call this method in the onDestroy() to
   * prevent this.
   */
  public destroy(): void {
    if (this.cmpRef) {
      this.cmpRef.destroy();
    }
  }

  public ngOnDestroy(): void {
    this.destroy();
  }
}
