import { JsonLd, TsRange } from '@techniek-team/class-transformer';
import { EagerLoaded, Fetch, Fetched } from '@techniek-team/fetch';
import { FetchObservable } from '@techniek-team/rxjs';
import { Exclude, Expose, Type } from 'class-transformer';
import { getISODay } from 'date-fns';
import { firstValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { Lesson } from '../lesson/lesson.model';
import { Role } from '../role/role.model';
import { ScheduleMinimal } from '../schedule/schedule-minimal.model';

/**
 * Standard version of the Slot Resource.
 */
export class Slot<Lazy = FetchObservable<unknown>> extends JsonLd {

  /**
   * @inheritDoc
   */
  public override readonly className: string = 'Slot';

  /**
   * The schedule where this Slot is scheduled on.
   */
  @Type(() => ScheduleMinimal)
  @Expose() public schedule!: ScheduleMinimal;

  /**
   * Lesson to which this slot belongs.
   */
  @Type(() => Lesson)
  @Expose() public lesson!: Lesson<Lazy>;

  /**
   * Time period when this shift takes place
   */
  @Type(() => TsRange)
  @Expose() public timePeriod!: TsRange;

  /**
   * Role the candidate takes when doing this slot.
   */
  @Fetch(() => Role)
  @Expose() public role!: Fetched<Lazy, Role<Lazy>>;

  @Exclude() public get dayNumber(): number {
    return getISODay(this.timePeriod.start);
  }

  public async fetchAll(): Promise<Slot<EagerLoaded>> {
    let role: FetchObservable<Role<Lazy>> | Role<Lazy> = this.role;

    if (role instanceof FetchObservable) {
      role = await firstValueFrom(role.pipe(take(1))).then(item => item.fetchAll());
    }

    const slot: Slot<EagerLoaded> = this as Slot<EagerLoaded>;
    slot.role = role as Role<EagerLoaded>;
    slot.lesson = await this.lesson.fetchAll() as Lesson<EagerLoaded>;
    return slot;
  }
}
