import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ModalController, ToggleCustomEvent } from '@ionic/angular';
import { AssignmentHasSlotPurposeEnum, AssignmentStateEnum, ContractTypeEnum } from '@scheduler-frontend/enums';
import { AssignmentDetailWithAssignmentHasSlot, CandidateDetails, LessonDetailed } from '@scheduler-frontend/models';
import { TsRange } from '@techniek-team/class-transformer';
import { TtSimpleModalComponent } from '@techniek-team/components/modal';
import { EagerLoaded } from '@techniek-team/fetch';
import { PermissionService } from '@techniek-team/permissions';
import { firstEmitFrom } from '@techniek-team/rxjs';
import { SentryErrorHandler } from '@techniek-team/sentry-web';
import { ToastService } from '@techniek-team/services';
import { differenceInMinutes, format, isAfter } from 'date-fns';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, switchMap, takeUntil } from 'rxjs/operators';
import { AssignmentApi } from '../../../../../api/assignment/assignment.api';
import { LessonApi } from '../../../../../api/lesson/lesson.api';
import { MarkAsPresentApi, MaskAsPresentRequest } from '../../../../../api/messenger/mark-as-present.api';
import { AssignmentPermission } from '../../../../../core/permission/assignment.permission';
import { UnassignService } from '../../../../services/mark-as-absent/unassign.service';
import { AssignmentModalService } from '../../assignment-modal.service';
import { AssignmentHasSlotTableRow } from './assignment-has-slot-table-row.model';

export type AssignmentHasSlotRowFormValue = FormGroup<AssignmentHasSlotRowForm>['value'];
export interface AssignmentHasSlotRowForm {
  breakTime: FormControl<number>;
  actualTimePeriod: FormGroup<{
    start: FormControl<Date>;
    end: FormControl<Date>;
  }>;
}

@Component({
  selector: 'app-assignment-has-slot-table',
  templateUrl: './assignment-has-slot-table.component.html',
  styleUrls: ['./assignment-has-slot-table.component.scss'],
})
export class AssignmentHasSlotTableComponent implements OnInit {

  /**
   * The current assignment
   */
  @Input() public assignment!: AssignmentDetailWithAssignmentHasSlot<unknown>;

  /**
   * Candidate associated with this Candidate.
   */
  @Input() public candidate?: CandidateDetails | null;

  protected rows: BehaviorSubject<AssignmentHasSlotTableRow[]> = new BehaviorSubject<AssignmentHasSlotTableRow[]>([]);

  protected changeIsBillablePermission!: Promise<boolean>;

  protected readonly AssignmentStateEnum: typeof AssignmentStateEnum = AssignmentStateEnum;

  protected readonly TableRow: typeof AssignmentHasSlotTableRow = AssignmentHasSlotTableRow;

  protected readonly AssignmentHasSlotPurposeEnum: typeof AssignmentHasSlotPurposeEnum = AssignmentHasSlotPurposeEnum;

  protected readonly ContractTypeEnum: typeof ContractTypeEnum = ContractTypeEnum;

  protected readonly displayedColumns: string[] = [
    'marking',
    'location',
    'date',
    'time',
    'breakTime',
    'actualTime',
    'role',
    'saldo',
    'actions',
  ];

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private permissionService: PermissionService,
    private assignmentApi: AssignmentApi,
    private lessonApi: LessonApi,
    private modalController: ModalController,
    private unassignService: UnassignService,
    private markAsPresentApi: MarkAsPresentApi,
    private assignmentModalService: AssignmentModalService,
    private toastService: ToastService,
    private sentryErrorHandler: SentryErrorHandler,
  ) {
  }

  /**
   * @inheritDoc
   */
  public ngOnInit(): void {
    this.createTableRowsSubscription();
    this.changeIsBillablePermission = this.assignment.fetchAll().then(assignment => this.permissionService.isDenied(
      AssignmentPermission,
      'CHANGE_IS_BILLABLE',
      assignment,
    ));
  }

  /**
   * Unassign the current candidate from the given slot in the assignment.
   */
  protected async unassignSlot(row: AssignmentHasSlotTableRow): Promise<void> {
    row.isBillableLoader.next(true);
    const success: boolean = await this.unassignService.unassign(this.assignment, [row.assignmentHasSlot]);
    if (success) {
      this.assignmentModalService.reloadAssignment();
    }

    row.isBillableLoader.next(false);
  }

  /**
   * Remove Slot from unassigned Assignment.
   */
  protected async removeSlot(row: AssignmentHasSlotTableRow): Promise<void> {
    if (row.assignment.state !== AssignmentStateEnum.UNASSIGNED) {
      return;
    }
    try {
      const confirmModal: HTMLIonModalElement = await this.modalController.create({
        component: TtSimpleModalComponent,
        componentProps: {
          title: 'Shift openstellen',
          message: 'Deze shift zal worden ontkoppeld van deze opdracht.',
        },
      });
      await confirmModal.present();
      const { role } = await confirmModal.onWillDismiss();

      if (role === 'confirm') {
        await firstEmitFrom(this.assignmentApi.removeSlot({ slots: [row.assignmentHasSlot.slot.getId()] }));
        const index: number = this.assignment.assignmentHasSlots
          .findIndex(item => item.getId === row.assignmentHasSlot.getId);
        this.assignment.assignmentHasSlots.splice(index, 1);
        await this.toastService.create('Shift is ontkoppeld van de opdracht.');
        this.assignmentModalService.reloadAssignment();
      }
    } catch (error) {
      await Promise.all([
        this.sentryErrorHandler.captureError(error),
        this.toastService.error(this.sentryErrorHandler.extractMessage(
          error,
          'Er is iets misgegaan bij het openstellen van deze shift.',
        )),
      ]);
    }
  }

  /**
   * Mark the current candidate assigned to the slot as absent.
   */
  protected async markAsPresent(row: AssignmentHasSlotTableRow): Promise<void> {
    const confirmModal: HTMLIonModalElement = await this.modalController.create({
      component: TtSimpleModalComponent,
      componentProps: {
        title: 'Ziekmelding ongedaan maken',
        message: 'Weet je zeker dat je de ziekmelding '
          + `voor ${this.candidate?.fullName} op deze shift wilt terugdraaien?`,
      },
    });
    await confirmModal.present();
    const { role } = await confirmModal.onWillDismiss();

    if (role === 'confirm') {
      try {
        await firstEmitFrom(this.markAsPresentApi.execute(new MaskAsPresentRequest(
          row.assignmentHasSlot,
        )));
        await this.toastService
          .create(`De ziekmelding voor ${this.candidate?.fullName} op deze shift is teruggedraaid.`);

        this.assignmentModalService.reloadAssignment();
      } catch (error) {
        await Promise.all([
          this.sentryErrorHandler.captureError(error),
          this.toastService.error(this.sentryErrorHandler.extractMessage(
            error,
            `Let op! Het terugdraaien van de ziekmelding van ${this.candidate?.fullName} is mislukt!`
            + ' Misschien was de ziekmelding al teruggedraaid of is er inmiddels een '
            + 'andere begeleider ingepland op deze shift.',
          )),
        ]);
      }
    }
  }


  /**
   * Disable billing the pupil for this lesson
   */
  protected async onIsBillableClick(
    row: AssignmentHasSlotTableRow,
    ev: Event,
  ): Promise<void> {
    const event: ToggleCustomEvent = ev as ToggleCustomEvent;
    const lesson: LessonDetailed = await row.assignmentHasSlot.slot.lesson;
    try {
      await firstEmitFrom(this.lessonApi.setIsBillable({
        isBillable: !lesson.isBillable,
        lessonId: lesson.getId(),
      }));
      lesson.isBillable = !lesson.isBillable;
      await this.toastService.create(
        `'Saldo afschrijven voor deze les is ${(lesson.isBillable ? 'aangezet' : 'uitgezet')}'`,
      );
    } catch (error) {
      event.target.checked = lesson.isBillable;
      await Promise.all([
        this.sentryErrorHandler.captureError(error),
        this.toastService.error(this.sentryErrorHandler.extractMessage(
          error,
          'Let op! Bijwerken van saldo afschrijven niet gelukt!',
        )),
      ]);
    }
  }

  protected async confirmTime(row: AssignmentHasSlotTableRow): Promise<void> {
    await this.editTimeDetails(row);
    this.assignmentModalService.reloadAssignment();
    const formValue: ReturnType<FormGroup<AssignmentHasSlotRowForm>['getRawValue']> = row.form.getRawValue();
    if (row.assignmentHasSlot.actualTimePeriod && formValue.actualTimePeriod) {
      row.assignmentHasSlot.actualTimePeriod.start = formValue.actualTimePeriod.start as Date;
      row.assignmentHasSlot.actualTimePeriod.end = formValue?.actualTimePeriod.end as Date;
    } else {
      row.assignmentHasSlot.actualTimePeriod = new TsRange(
        formValue?.actualTimePeriod?.start,
        formValue?.actualTimePeriod?.end,
      );
    }
    row.assignmentHasSlot.breakTime = +formValue?.breakTime;
  }

  /**
   * Creates Form for each row.
   */
  private createTableRowsSubscription(): Subscription {
    return this.assignmentModalService.assignment$.subscribe(assignment => {
      const rows: AssignmentHasSlotTableRow[] = this.rows.getValue();
      if (this.rows.getValue().length === 0) {
        this.rows.next(this.createTableRows(assignment));
        return;
      }
      for (let slot of assignment.assignmentHasSlots) {
        const row: AssignmentHasSlotTableRow | undefined = rows
          .find(item => item.assignmentHasSlot.getId() === slot.getId());
        if (row) {
          row.assignmentHasSlot = slot;
          row.form.patchValue({
            breakTime: slot.breakTime ?? 0,
            actualTimePeriod: {
              start: slot?.actualTimePeriod?.start ?? slot.slot.timePeriod.start,
              end: slot?.actualTimePeriod?.end ?? slot.slot.timePeriod.end,
            },
          }, { emitEvent: false, onlySelf: true });
        }
      }
      this.rows.next(rows);
    });
  }

  /**
   * create a Table row for each assignmentHasSlot
   */
  private createTableRows(assignment: AssignmentDetailWithAssignmentHasSlot<EagerLoaded>): AssignmentHasSlotTableRow[] {
    const rows: AssignmentHasSlotTableRow[] = [];
    for (let hasSlot of assignment.assignmentHasSlots) {
      //eslint-disable-next-line @typescript-eslint/typedef
      const form = new FormGroup({
        breakTime: new FormControl<number>(
          { value: hasSlot?.breakTime ?? 0, disabled: !hasSlot.slot.role.allowBreakTime },
          { nonNullable: true, validators: [Validators.required, Validators.min(0)] },
        ),
        actualTimePeriod: new FormGroup({
          start: new FormControl<Date>(
            hasSlot?.actualTimePeriod?.start ?? hasSlot.slot.timePeriod.start,
            { nonNullable: true, validators: [Validators.required] },
          ),
          end: new FormControl<Date>(
            hasSlot?.actualTimePeriod?.end ?? hasSlot.slot.timePeriod.end,
            { nonNullable: true, validators: [Validators.required] },
          ),
        }, [this.timeDateRangeValidator]),
      }, { validators: this.validateBreakTime });
      const row: AssignmentHasSlotTableRow = new AssignmentHasSlotTableRow(
        hasSlot,
        form,
        assignment,
        this.permissionService,
      );

      this.createOnRowChangeSubscription(row);
      rows.push(row);
    }

    return rows;
  }

  /**
   * Creates a subscription submitting the details on change.
   */
  private createOnRowChangeSubscription(row: AssignmentHasSlotTableRow): Subscription {
    return row.form.valueChanges.pipe(
      takeUntil(this.onDestroy$),
      distinctUntilChanged(),
      filter(() => row.form.valid),
      switchMap(() => this.editTimeDetails(row)),
    ).subscribe((_) => {
      this.assignmentModalService.reloadAssignment();
      const formValue: ReturnType<FormGroup<AssignmentHasSlotRowForm>['getRawValue']> = row.form.getRawValue();
      row.assignmentHasSlot.actualTimePeriod = new TsRange(
        formValue.actualTimePeriod.start,
        formValue.actualTimePeriod.end,
      );
      row.assignmentHasSlot.breakTime = +formValue?.breakTime;
    });
  }

  /**
   * Save the updated assignmentHasSlot values to the backend
   */
  //eslint-disable-next-line max-lines-per-function
  private async editTimeDetails(row: AssignmentHasSlotTableRow): Promise<void> {
    if (row.form.invalid || row.form.disabled) {
      return;
    }

    try {
      row.timeLoader.next(true);
      const formValue: ReturnType<FormGroup<AssignmentHasSlotRowForm>['getRawValue']> = row.form.getRawValue();
      await firstEmitFrom(this.assignmentApi.updateActualWorkingTimes({
        assignmentId: this.assignment.getId(),
        assignmentHasSlotId: row.assignmentHasSlot.getId(),
        time: new TsRange(formValue.actualTimePeriod.start, formValue.actualTimePeriod.end),
        breakTime: (row.assignmentHasSlot.slot.role.allowBreakTime) ? +formValue.breakTime : undefined,
      }));
      await this.toastService.create(
        `Werkelijke werktijd opgeslagen voor ${format(row.assignmentHasSlot.slot.timePeriod.start, 'dd-MM-yyyy')}`,
      );
    } catch (error) {
      let date: string = format(row.assignmentHasSlot.slot.timePeriod.start, 'dd-MM-yyyy');
      if (error instanceof HttpErrorResponse && (error.status === 400 || error.status === 409)) {
        return this.toastService.error(this.sentryErrorHandler.extractMessage(
          error,
          `Er is iets misgegaan bij het bijwerken van de shift op datum ${date} .`,
        ));
      }

      await Promise.all([
        this.sentryErrorHandler.captureError(error),
        this.toastService.error(this.sentryErrorHandler.extractMessage(
          error,
          `Er is iets misgegaan bij het bijwerken van de shift op datum ${date} .`,
        )),
      ]);
    } finally {
      row.timeLoader.next(false);
    }
  }

  //noinspection JSMethodCanBeStatic
  /**
   * Form validator which checks if the time is correct.
   */
  private timeDateRangeValidator(group: AbstractControl): ValidationErrors | null {
    if (!group || !(group instanceof FormGroup)) {
      return null;
    }

    const value: NonNullable<AssignmentHasSlotRowFormValue['actualTimePeriod']> = group.getRawValue();
    if (!value.start || !value.end) {
      return { tsRangeTimeNotSet: { value: group } };
    }
    return isAfter(group.value.end, group.value.start) ? null : { tsRangeNotInRange: { value: group } };
  }

  /**
   * Make sure the length of the break is not longer than the length of the shift
   */
  private validateBreakTime(group: AbstractControl): ValidationErrors | null {
    if (!group || !(group instanceof FormGroup)) {
      return null;
    }

    const value: ReturnType<FormGroup<AssignmentHasSlotRowForm>['getRawValue']> = group.getRawValue();
    const shiftLength: number = differenceInMinutes(value.actualTimePeriod.end, value.actualTimePeriod.start);
    const breakLength: number = +value.breakTime;

    if (shiftLength > breakLength) {
      return null;
    }

    return { breakIsLongerThenWorkingTimes: { value: breakLength } };
  }
}
