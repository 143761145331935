import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@scheduler-frontend/environments';
import { SubjectModel } from '@scheduler-frontend/models';
import { Collection, Resource } from '@techniek-team/api-platform';
import { denormalize, Hydra } from '@techniek-team/class-transformer';
import { CacheService } from '@techniek-team/services';
import { hoursToMilliseconds } from 'date-fns';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GetSubjectResponse } from './subject.response';

export interface SubjectApiInterface {
  getSubject(subject: string): Observable<SubjectModel>;
  getSubjects(): Observable<Hydra<SubjectModel>>;
}

/**
 * Api to retrieve the {@see SubjectModel} resource from the scheduler-api
 */
@Injectable({
  providedIn: 'root',
})
export class SubjectApi implements SubjectApiInterface {

  constructor(
    private httpClient: HttpClient,
    private cacheService: CacheService,
  ) {
  }

  public getSubject(subject: string): Observable<SubjectModel> {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/subjects/${subject}`;

    //todo retrieve this from the cache as well.
    return this.httpClient.get<Resource<GetSubjectResponse>>(url).pipe(
      map(response => denormalize(SubjectModel, response)),
    );
  }

  public getSubjects(): Observable<Hydra<SubjectModel>> {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/subjects`;
    const call: Observable<Hydra<SubjectModel>> = this.httpClient.get<Collection<GetSubjectResponse>>(url).pipe(
      map(response => denormalize(SubjectModel, response)),
    );

    return this.cacheService.create<Hydra<SubjectModel>>(
      'SubjectModel',
      call,
      { refreshInterval: hoursToMilliseconds(24) },
    );
  }
}
