import { OnlineLessonLinkEnum } from '@scheduler-frontend/enums';
import { TsRange, TsRangeInterface } from '@techniek-team/class-transformer';
import { Expose, Type } from 'class-transformer';

/**
 * The standard version of the OnlineLessonLink resource from Scheduler-api
 */
export class OnlineLessonLink {

  /**
   * Url to the wizIq room
   */
  @Expose() public link!: string;

  /**
   * Type of Online Lesson link
   */
  @Expose() public type!: OnlineLessonLinkEnum;

  /**
   * Time the online lesson takes place
   */
  @Type(() => TsRange)
  @Expose() public timePeriod!: TsRangeInterface;
}
