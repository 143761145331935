import { NgModule } from '@angular/core';
import { TtMenuModule } from '@techniek-team/components/menu-layout';
import { TtPermissionModule } from '@techniek-team/permissions';
import { CoreModule } from '../core/core.module';
import { WhatsNewModalModule } from '../shared/modals/whats-new-modal/whats-new-modal.module';
import { MenuLayoutPage } from './menu-layout.page';

@NgModule({
  imports: [
    CoreModule,
    TtMenuModule,
    TtPermissionModule,
    WhatsNewModalModule,
  ],
  declarations: [MenuLayoutPage],
})
export class MenuLayoutPageModule {
}
