/** @format **/
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@scheduler-frontend/environments';
import { LessonDetailed, Schedule } from '@scheduler-frontend/models';
import { Collection } from '@techniek-team/api-platform';
import { denormalize } from '@techniek-team/class-transformer';
import { formatISO, subDays } from 'date-fns';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IsBillableRequest } from './lesson.request';
import { GetLessonResponse } from './lesson.response';

export interface LessonApiInterface {
  getLessons(schedule: string | Schedule): Observable<LessonDetailed[]>;
  setIsBillable(isBillableRequest: IsBillableRequest): Observable<void>;
}

@Injectable({
  providedIn: 'root',
})
export class LessonApi implements LessonApiInterface {
  constructor(protected httpClient: HttpClient) {}

  public getLessons(schedule: string | Schedule): Observable<LessonDetailed[]> {
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/lessons`;

    return this.httpClient
      .get<GetLessonResponse[]>(url, {
      params: {
        schedule: typeof schedule === 'string' ? schedule : (schedule.getIri() as string),
      },
    })
      .pipe(map((response) => denormalize(LessonDetailed, response)));
  }

  public getLessonsFromSchedule(
    schedule: string | Schedule,
    afterDate?: Date,
  ): Observable<LessonDetailed[]> {
    if (!afterDate) {
      afterDate = subDays(new Date(), 28);
    }
    if (schedule instanceof Schedule) {
      schedule = schedule.getId();
    }

    let params = new HttpParams();
    params = params.set('date[after]', formatISO(afterDate, { representation: 'date' }));

    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v3/schedules/${schedule}/lessons`;

    return this.httpClient.get<Collection<GetLessonResponse>>(url, { params: params }).pipe(
      map((response) => denormalize(LessonDetailed, response)),
      map((response) => response.toArray()),
    );
  }

  public setIsBillable(isBillableRequest: IsBillableRequest): Observable<void> {
    //eslint-disable-next-line max-len
    const url: string = `${environment.scheduler.url}${environment.scheduler.iri}/v1/lessons/${isBillableRequest.lessonId}/isBillable`;

    return this.httpClient.put<void>(url, {
      isBillable: isBillableRequest.isBillable,
    });
  }
}
