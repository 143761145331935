import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { AssignmentStateEnum } from '@scheduler-frontend/enums';
import { EagerLoaded } from '@techniek-team/fetch';
import { FetchObservable } from '@techniek-team/rxjs';
import { Exclude, Expose, Type } from 'class-transformer';
import { isEqual, roundToNearestMinutes } from 'date-fns';
import { firstValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  AssignmentHasSlotDetailedWithAssignment as AssignmentHasSlot,
} from '../assignment/assignment-has-slot/assignment-has-slot-with-assignment.model';
import { Assignment } from '../assignment/assignment.model';
import { LessonDetailed } from '../lesson/lesson-detailed.model';
import { Role } from '../role/role.model';
import { SlotDetailed } from './slot-detailed.model';

/**
 * Detailed version of the Slot Resource.
 */
export class SlotDetailedWithAssignmentHasSlot<Lazy = FetchObservable<unknown>> extends SlotDetailed<Lazy> {

  /**
   * @inheritDoc
   */
  public override readonly className: string = 'SlotDetailedWithAssignmentHasSlot';

  /**
   * Assignment where the slot belongs to
   *
   * Note: this property is only available when this resource is retrieved
   * through the {@see SlotApi} or {@see SearchApi}. In other cases look at
   * the parent of this resource
   */
  @Type(() => AssignmentHasSlot)
  @Expose() public assignmentHasSlot?: AssignmentHasSlot;

  /**
   * Assignment where the slot belongs to
   */
  public get assignment(): Assignment | undefined {
    return this?.assignmentHasSlot?.assignment;
  }

  @Exclude() public get isTimePeriodEqual(): boolean {
    if (!this.assignmentHasSlot?.actualTimePeriodUpdatedAt || !this.assignmentHasSlot.actualTimePeriod) {
      // no actual timeperiod set
      return false;
    }
    const isStartEqual: boolean = isEqual(
      roundToNearestMinutes(this.timePeriod.start),
      roundToNearestMinutes(this.assignmentHasSlot.actualTimePeriod.start),
    );
    const isEndEqual: boolean = isEqual(
      roundToNearestMinutes(this.timePeriod.end),
      roundToNearestMinutes(this.assignmentHasSlot.actualTimePeriod.end),
    );
    return isStartEqual && isEndEqual;
  }

  public isAssignable(): boolean {
    return this.assignment === undefined || this.assignment?.isUnassigned();
  }

  public get stateIcon(): IconProp | undefined {
    switch (this.assignment?.state || AssignmentStateEnum.UNASSIGNED) {
      case AssignmentStateEnum.WAITING_FOR_CONFIRMATION:
      case AssignmentStateEnum.WAITING_FOR_DOCUMENTS:
      case AssignmentStateEnum.PROVISIONALLY_CONFIRMED:
        return ['fas', 'hourglass-start'];
      case AssignmentStateEnum.APPROVED:
        return ['fas', 'circle-check'];
      default:
        return undefined;
    }
  }


  public override async fetchAll(): Promise<SlotDetailedWithAssignmentHasSlot<EagerLoaded>> {
    let role: FetchObservable<Role<Lazy>> | Role<Lazy> = this.role;

    if (role instanceof FetchObservable) {
      role = await firstValueFrom(role.pipe(take(1))).then(item => item.fetchAll());
    }

    //eslint-disable-next-line max-len
    const slot: SlotDetailedWithAssignmentHasSlot<EagerLoaded> = this as SlotDetailedWithAssignmentHasSlot<EagerLoaded>;

    slot.role = role as Role<EagerLoaded>;
    slot.lesson = await this.lesson.fetchAll() as LessonDetailed<EagerLoaded>;
    return slot;
  }
}
