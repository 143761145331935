/** @format **/
import {
  AfterViewInit,
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  Injector,
  Input,
  OnInit,
  Renderer2,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { maskitoTransform } from '@maskito/core';
import { MaskitoOptions } from '@maskito/core/lib/types/mask-options';
import { maskitoNumberOptionsGenerator, maskitoParseNumber } from '@maskito/kit';
import {
  fixFormControlMarkAs,
  getElementFont,
  getElementLetterSpacing,
  getTextDimensions,
} from '@techniek-team/common';
import { IonColor } from '@techniek-team/lyceo-style';
import { BehaviorSubject, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

type OnChangeCallback = (output: number | null) => void;
type OnTouchCallback = () => void;
type OnValidCallback = () => void;

@Component({
  selector: 'tt-number-input',
  templateUrl: './tt-number-input-control.component.html',
  styleUrls: ['./tt-number-input-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TtNumberInputControlComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TtNumberInputControlComponent),
      multi: true,
    },
  ],
})
export class TtNumberInputControlComponent implements OnInit, ControlValueAccessor, AfterViewInit, Validator {

  /**
   * If `true`, the value will be cleared after focus upon edit. Defaults to `true` when `type` is `"password"`,
   * `false` for all other types.
   */
  @Input() public clearOnEdit?: boolean;

  /**
   * The color to use from your application's color palette. Default options are: `"primary"`, `"secondary"`,
   * `"tertiary"`, `"success"`, `"warning"`, `"danger"`, `"light"`, `"medium"`, and `"dark"`. For more information on
   * colors, see [theming](/docs/theming/basics).
   */
  @Input() public color?: IonColor;


  /**
   * Text that is placed under the input and displayed when an error is detected.
   */
  @Input() public errorText?: string;

  /**
   * The fill for the item. If `"solid"` the item will have a background. If `"outline"` the item will be transparent
   * with a border. Only available in `md` mode.
   */

  @Input() public fill?: 'outline' | 'solid';
  /**
   * Text that is placed under the input and displayed when no error is detected.
   */

  @Input() public helperText?: string;

  /**
   * Input for the label tekst
   */
  @Input() public label?: string;

  /**
   * Where to place the label relative to the input. `"start"`: The label will appear to the left of the input in LTR
   * and to the right in RTL. `"end"`: The label will appear to the right of the input in LTR and to the left in RTL.
   * `"floating"`: The label will appear smaller and above the input when the input is focused or it has a value.
   * Otherwise it will appear on top of the input. `"stacked"`: The label will appear smaller and above the input
   * regardless even when the input is blurred or has no value. `"fixed"`: The label has the same behavior as `"start"`
   * except it also has a fixed width. Long text will be truncated with ellipses ("...").
   */
  @Input() public labelPlacement: 'start' | 'end' | 'floating' | 'stacked' | 'fixed' = 'start';

  /**
   * set the aria label which ionic requires
   */
  @Input() public ariaLabel?: string;

  /**
   * Input for the minimum number they can be used as input.
   */
  @Input() public min?: number;

  /**
   * Input for the maximum number they can be used as input.
   */
  @Input() public max?: number;

  /**
   * Whether to allow decimals.
   */
  @Input() public allowDecimals: boolean = false;

  /**
   * A placeholder number
   */
  @Input() public placeholder?: number;

  /**
   * Press interval (in number of seconds) to register the long click press.
   */
  @Input() public longPressInterval: number = 0.1;

  /**
   * Number added or subtracted on button clicked.
   */
  @Input() public stepInterval: number = 0.1;

  /**
   * Number added or subtracted on button clicked with a long press.
   */
  @Input() public stepLongPressStepInterval: number = 0.1;

  /**
   * "block" for a full-width input or undefined for minimum needed
   */
  @HostBinding('class.block') @Input() public expand?: 'block';

  /**
   * FormControl that holds the current input.
   */
  public numberControl!: FormControl<number | null>;

  public maskItoConfig!: MaskitoOptions;

  protected maskControl!: FormControl<string | null>;

  protected contentWidth$ = new BehaviorSubject<{ min: string; max: string }>({ min: '0', max: 'auto' });

  @HostBinding('class.has-focus') protected hasFocus: boolean = false;

  @HostBinding('class.fill-outline') protected fillOutline: boolean = false;

  @HostBinding('class.fill-solid') protected fillSolid: boolean = false;

  /**
   * Subject which completes all hot observers on emit.
   */
  private onDestroy$: Subject<void> = new Subject<void>();

  @HostBinding('class.disabled') protected disabled: boolean = false;

  constructor(
    private elementRef: ElementRef,
    private injector: Injector,
    private renderer: Renderer2,
  ) {
  }

  protected static maskValidator(actualControl: AbstractControl): ValidatorFn {
    return (_control: AbstractControl): ValidationErrors | null => {
      const validators: ValidatorFn | null = actualControl.validator;
      if (validators) {
        return validators(actualControl);
      }
      return null;
    };
  }

  /**
   * @inheritDoc
   */
  public ngOnInit(): void {
    this.maskItoConfig = maskitoNumberOptionsGenerator({
      decimalSeparator: ',',
      decimalPseudoSeparators: [',', '.'],
      thousandSeparator: ' ',
      precision: (this.allowDecimals) ? 2 : 0,
      min: this.min,
      max: this.max,
    });

    if (!this.allowDecimals) {
      this.stepInterval = Math.max(1, this.stepInterval);
      this.stepLongPressStepInterval = Math.max(1, this.stepLongPressStepInterval);
    }
    this.numberControl = this.createForm();
    this.maskControl = new FormControl<string | null>(
      null,
      [TtNumberInputControlComponent.maskValidator(this.numberControl)],
    );
    this.createOnChangeSubscriber();
    this.createFormControlSyncSubscribers();
    this.fillOutline = (this.fill === 'outline');
    this.fillSolid = (this.fill === 'solid');
  }

  /**
   * @inheritDoc
   */
  public ngAfterViewInit(): void {
    fixFormControlMarkAs(this.injector, this.numberControl, this.elementRef);
    this.contentWidth$.next(this.calculateContentWidth());
  }

  /**
   * @inheritDoc
   */
  public registerOnChange(fn: OnChangeCallback): void {
    this.onChange = fn;
  }

  /**
   * @inheritDoc
   */
  public registerOnValidatorChange(fn: () => void): void {
    this.onValidatorChange = fn;
  }

  /**
   * @inheritDoc
   */
  public registerOnTouched(fn: OnTouchCallback): void {
    this.onTouch = fn;
  }

  /**
   * @inheritDoc
   */
  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.numberControl.disable();
      this.maskControl.disable();
      this.disabled = true;
    } else {
      this.numberControl.enable();
      this.maskControl.enable();
      this.disabled = false;
    }
  }

  /**
   * @inheritDoc
   */
  public writeValue(value: number): void {
    if (!value) {
      // set default value;
      this.numberControl.reset();
      return;
    }

    const rounded: number = Math.round((value + Number.EPSILON) * 10) / 10;
    this.numberControl.setValue(rounded, { emitEvent: false, onlySelf: true });
    this.maskControl.setValue(maskitoTransform(
      rounded.toString() ?? '',
      this.maskItoConfig,
    ), { emitEvent: false });
  }

  /**
   * @inheritDoc
   */
  public validate(control: AbstractControl): ValidationErrors | null {
    if (this.numberControl.invalid) {
      return this.numberControl.errors;
    }
    if (control.validator !== null) {
      return control.validator(this.numberControl);
    }

    return null;
  }

  /**
   * Cancels any currently running long press event.
   */
  public stopPressInterval(): void {
    clearInterval(this.longPressInterval);
    if (!this.allowDecimals) {
      this.numberControl.setValue((this.numberControl.value) ? Math.round(this.numberControl.value) : null);
    }
    const ionItem: HTMLElement | null = this.elementRef.nativeElement.querySelector('ion-item');
    if (ionItem) {
      this.renderer.removeClass(ionItem, 'item-has-focus');
    }
  }

  /**
   * Method triggered when user clicks on the add button.
   * It adds stepInterval or 10 times the stepInterval depending on if the alt key is pressed.
   */
  public add(event: MouseEvent): void {
    this.stopPressInterval();
    this.internalAdd((event.altKey) ? (this.stepInterval * 10) : this.stepInterval);
  }

  /**
   * Method triggered when the user long presses the add button.
   * It adds this.stepInterval every 10th of a second.
   */
  public longPressAdd(): void {
    this.longPressInterval = window.setInterval(() => this.internalAdd(this.stepLongPressStepInterval), 100);
    const ionItem: HTMLElement | null = this.elementRef.nativeElement.querySelector('ion-item');
    if (ionItem) {
      this.renderer.addClass(ionItem, 'item-has-focus');
    }
  }

  /**
   * Method triggered when user clicks on the subtract button.
   * It subtracts  stepInterval or 10 times the stepInterval depending on if the alt key is pressed.
   */
  public sub(event: MouseEvent): void {
    this.stopPressInterval();
    this.internalSub((event.altKey) ? (this.stepInterval * 10) : this.stepInterval);
  }

  /**
   * Method triggered when the user long presses the subtract button.
   * It subtracts this.stepInterval every 10th of a second.
   */
  public longPressSub(): void {
    this.longPressInterval = window.setInterval(() => this.internalSub(this.stepLongPressStepInterval), 100);
  }

  protected maskPredicate(el: Element): Promise<HTMLInputElement> {
    return (el as HTMLIonInputElement).getInputElement();
  }

  /**
   * Round the input and check if value is within range of min max on blur.
   */
  protected checkAndRoundInput(): void {
    this.stopPressInterval();
    this.onTouch();
    let value: number = Math.round((this.numberControl.value as number + Number.EPSILON) * 10) / 10;
    if (typeof this.min === 'number' && value <= this.min) {
      value = this.min;
    }
    if (typeof this.max === 'number' && value >= this.max) {
      value = this.max;
    }

    this.numberControl.setValue(value, { emitEvent: false, onlySelf: true });
  }

  /**
   * the onChange callback
   */
  private onChange: OnChangeCallback = (_output: number | null) => { /* callback */
  };

  /**
   * the onTouch callback
   */
  private onTouch: OnTouchCallback = () => { /* callback */
  };

  /**
   * the onValidator callback.
   */
  private onValidatorChange: OnValidCallback = () => { /* callback */
  };

  //eslint-disable-next-line complexity
  private calculateContentWidth(): { min: string; max: string } {
    let contentWidth: { min: string; max: string } = { min: '0', max: 'auto' };
    let element: HTMLElement = this.elementRef.nativeElement.querySelector('ion-input');

    const labelWidth = this.getTextWidth(this.label, element);
    let maskSpacing: number = 0;

    if (labelWidth > 0 && (this.labelPlacement === 'stacked' || this.labelPlacement === 'floating')) {
      contentWidth.min = labelWidth.toString();
    }

    if (this.max !== undefined) {
      const maximum: number = Math.max(Math.abs(this.min ?? 0), Math.abs(this.max ?? 0));
      //eslint-disable-next-line max-len
      contentWidth.max = `${Math.ceil(Math.max(this.getTextWidth(maximum.toString(), element), labelWidth)).toString()}px`;
      maskSpacing = this.getTextWidth(' ', element) * Math.floor(maximum.toString().length / 4);
    }

    if (this.min !== undefined || labelWidth > 0) {
      const minimum: number = Math.min(Math.abs(this.min ?? 0), Math.abs(this.max ?? 0));
      //eslint-disable-next-line max-len
      contentWidth.min = `${Math.ceil(Math.max(this.getTextWidth(minimum.toString(), element), labelWidth)).toString()}px`;
      maskSpacing = Math.max(maskSpacing, this.getTextWidth(' ', element) * Math.floor(minimum.toString().length / 4));
    }

    //eslint-disable-next-line max-len
    contentWidth.min = `var(--tt-number-input-width, calc(${contentWidth.min} + var(--padding-start) + var(--padding-end) + 3px + ${maskSpacing}px))`;
    contentWidth.max = `var(--tt-number-input-width, calc(${contentWidth.max} + var(--padding-start) + var(--padding-end) + 3px + ${maskSpacing}px))`;
    return contentWidth;
  }

  private getTextWidth(text: string | undefined, element: HTMLElement): number {
    if (!text) {
      return 0;
    }
    return Math.ceil(getTextDimensions(text, getElementFont(element), getElementLetterSpacing(element)).width);
  }

  private createFormControlSyncSubscribers(): void {
    this.numberControl.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(value => {
        this.maskControl.setValue(maskitoTransform(
          value?.toString() ?? '',
          this.maskItoConfig,
        ), { emitEvent: false });
      });
    this.maskControl.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(value => {
        this.onTouch();
        this.numberControl.setValue(
          (value) ? maskitoParseNumber(value?.toString() ?? '', ',') : null,
        );

      });
  }

  /**
   * Add 1 or this.stepInterval to the number depend on if the alt key was pressed.
   */
  private internalAdd(toAdd: number): void {
    let current: number = this.numberControl.value ?? 0;

    if (typeof this.max === 'number' && current >= this.max) {
      return;
    }

    let newCurrent: number = current + toAdd;
    if (this.allowDecimals) {
      newCurrent = Math.round((newCurrent + Number.EPSILON) * 10) / 10;
    }
    this.onTouch();

    if (typeof this.min === 'number' && this.numberControl.value === null) {
      newCurrent = Math.max(newCurrent, this.min);
    }

    this.numberControl.setValue(newCurrent);
  }

  /**
   * Subtracts 1 or this.stepInterval to the number depend on if the alt key was pressed.
   */
  private internalSub(toSub: number): void {
    let current: number = this.numberControl.value ?? 0;

    if (typeof this.min === 'number' && current <= this.min) {
      if (this.numberControl.value === null && current === 0) {
        this.numberControl.setValue(0);
      }
      return;
    }

    let newCurrent: number = current - toSub;
    if (this.allowDecimals) {
      newCurrent = Math.round((newCurrent + Number.EPSILON) * 10) / 10;
    }

    this.onTouch();
    this.numberControl.setValue(newCurrent);
  }

  /**
   * Create the input form.
   */
  private createForm(): FormControl<number | null> {
    const validators: ValidatorFn[] = [];

    if (typeof this.min === 'number') {
      validators.push(Validators.min(this.min));
    }
    if (typeof this.max === 'number') {
      validators.push(Validators.max(this.max));
    }

    if (!this.allowDecimals) {
      validators.push(Validators.pattern(/^-?(-1|\d+)?$/));
    }

    return new FormControl<number | null>(null, validators);
  }

  /**
   * Create a subscriber which emits the changed values to the onChange.
   */
  private createOnChangeSubscriber(): void {
    this.numberControl.valueChanges.pipe(
      takeUntil(this.onDestroy$),
    ).subscribe(change => {
      this.onValidatorChange();
      this.onChange(change);
    });
  }

}
