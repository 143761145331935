import { LazyJsonLd as JsonLd } from '@techniek-team/fetch';
import { Expose } from 'class-transformer';

/**
 * Standard version of the BusinessEntity resource from Scheduler-api.
 */
export class BusinessEntity extends JsonLd {

  /**
   * @inheritDoc
   */
  public override readonly className: string = 'BusinessEntity';

  /**
   * Name of the company.
   */
  @Expose() public name!: string;

  /**
   * @inheritDoc
   */
  public override toString(): string {
    return this.name;
  }
}
