<ion-textarea [formControl]="descriptionController"
              color="tertiary"
              mode="md"
              fill="solid"
              data-test="descriptionController"
              autoGrow="true"
              rows="7"
              placeholder="Voeg hier een opmerking toe aan deze opdracht">
</ion-textarea>
<ion-spinner *ngIf="descriptionLoader$ | async"></ion-spinner>
