import { Expose } from 'class-transformer';
import { ProductType } from './product-type.model';

/**
 * Detailed version of the Product type resource from Scheduler-api
 */
export class ProductTypeDetailed extends ProductType {

  /**
   * @inheritDoc
   */
  public override readonly className: string = 'ProductTypeDetailed';

  /**
   * States that this product type contains only one on one lessons
   */
  @Expose() public isOneOnOne!: boolean;

  /**
   * State the you need to select a existing lesson on slot creation.
   */
  @Expose() public requiresPredefinedLesson!: boolean;

  /**
   * State that slots that are created for this product type requires a subject.
   */
  @Expose() public requiresSubject!: boolean;

  /**
   * State that slots that are created for this product type requires a level.
   */
  @Expose() public requiresLevel!: boolean;

  /**
   * State that slots that are created for this product type need to be scheduled
   * on a headquarter location
   */
  @Expose() public scheduledOnHeadquarters!: boolean;

  /**
   * State that slots that are created for this product type can take place
   * on a alternative location.
   */
  @Expose() public allowAlternateLocation!: boolean;

  /**
   * State that slots that are created for this product type can set
   * a number of required pupils.
   */
  @Expose() public allowSetNumberOfPupils!: boolean;
}
