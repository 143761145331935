<ion-label class="label-start"
           *ngIf="labelPlacement === 'start' && label">
  {{ label }}
</ion-label>
<div class="button-container"
     [class.disabled]="disabled"
     slot="start">
  <ion-button data-test="tt-number-input-sub-button"
              size="small"
              fill="clear"
              (click)="sub($event)"
              (press)="longPressSub()"
              (pressup)="stopPressInterval()"
              [disabled]="numberControl.disabled"
              [color]="color">
    <ion-icon icon="remove-outline"
              slot="icon-only"></ion-icon>
  </ion-button>
</div>
<ion-input data-test="tt-number-input-input"
           inputmode="decimal"
           [class.disabled]="disabled"
           *ngIf="(contentWidth$ | async) as contentWidth"
           [style.min-width]="contentWidth.min"
           [style.max-width]="(!expand) ? contentWidth.max : undefined"
           [label]="(labelPlacement === 'floating' || labelPlacement === 'stacked') ? label : undefined"
           [labelPlacement]="labelPlacement"
           [attr.aria-label]="label ?? ariaLabel"
           [clearOnEdit]="clearOnEdit"
           [color]="color"
           [fill]="fill"
           [maskito]="maskItoConfig"
           [maskitoElement]="maskPredicate"
           [step]="stepInterval"
           [placeholder]="placeholder"
           mode="md"
           [formControl]="maskControl"
           (ionFocus)="hasFocus = true"
           (ionBlur)="hasFocus = false"
           (blur)="checkAndRoundInput()"
           [min]="min"
           [max]="max"></ion-input>
<div class="button-container"
     [class.disabled]="disabled"
     slot="end">
  <ion-button data-test="tt-number-input-add-button"
              size="small"
              fill="clear"
              (click)="add($event)"
              (press)="longPressAdd()"
              (pressup)="stopPressInterval()"
              [disabled]="numberControl.disabled"
              [color]="color">
    <ion-icon icon="add-outline"
              slot="icon-only"></ion-icon>
  </ion-button>
</div>
<ion-label class="label-end"
           *ngIf="labelPlacement === 'end' && label">
  {{ label }}
</ion-label>
<ion-note class="error-text" *ngIf="numberControl.dirty">
  {{ errorText }}
</ion-note>
<ion-note class="helper-text" *ngIf="numberControl.pristine">
  {{ helperText }}
</ion-note>
